import React, { useEffect, useRef, useState } from 'react'
import { Layout } from '../Layouts/Layout'
import { ModalInformation } from './ModalInformation';
import { OrganigramaGeneral } from './OrganigramaGeneral';
import Request from '../../utils/http'
const request = new Request()
export const Organigrama = () => {
  const [dataToShow,setDataToShow] = useState(null)
  const [openModal,closeModal] = useState(false)
  const getData = async() => {
    const response = await request.get("information/departments", []);

     if (response && response.statusCode==200) {
      //     let org=[
    //         {
    //             expanded: true,
    //             type: 'person',
    //             data: {
    //                 image: 'https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png',
    //                 name: 'RAMÓN ROLANDO HEREDIA RUIZ',
    //                 title: 'Notario Titular'
    //             },  
    //             children:[] 
    //         }
    //     ]
    //     response.result.datos.Department.forEach(element => {
    //         let boss=JSON.parse(element.areaboss)
    //         let item={
    //             expanded: true,
    //             type: 'person',
    //             data: {
    //                 image: 'https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png',
    //                 name: boss.label,
    //                 title: element.description,
                    
    //             },
    //             children: [ ]
    //         }
    //         element.groups.forEach(group => {
    //           let puesto={
    //             label:`${group.description}\n Sueldo base: ${group.sueldo_base}`,
                
    //             children:[ ]
    //           }
    //           puesto.children.push({
                 
    //             expanded: true,
    //             type: 'person',
    //             data: {
    //                 image: 'https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png',
    //                 name: group.personal.length>0?`${group.personal[0].name} ${group.personal[0].lastName}`:'',
    //                 title:  group.personal.length>0?`sueldo: ${group.personal[0].sueldo}`:'',
    //                 children:[
    //                   {data:group.personal}
    //                 ]
                    
    //             },
                    
               
    //           })



    //             item.children.push(puesto)
               
    //         })

    //         org[0].children.push(item)
    //     });
    //     setData(org);
      // }
    }
  }
  return (
    <Layout>

       <OrganigramaGeneral {...{openModal,closeModal,dataToShow,setDataToShow}} />
        <ModalInformation {...{openModal,closeModal,dataToShow,setDataToShow}} />
    </Layout>
  )
}

