import React, { useEffect, useState } from 'react'
import { Layout } from '../Layouts/Layout'
import Request from '../../utils/http'
import userImage from '../../static/img/user.png'
import { AddColaborator } from './components/PersonalData/AddColaborator'
import { ButtonAddColaborator } from './components/PersonalData/ButtonAddColaborator'
import { TableColaborators } from './components/PersonalData/TableColaborators'
import { SearchComponent } from '../../components/SearchComponent'

const request = new Request()

export const Colaborators = () => {
    const [newColaborator,setNewColaborator]=useState(false)
    const [colaborators,setColaborator] =useState([])
    const [department, setDepartment] = useState([])
    const [tags, setTags] = useState([])
    const [tagsBoss, setTagsBoss] = useState([])
    const [type, setType] = useState(1)
    const [user,setUser] = useState(null)
    const [edit,setEdit] = useState(false)
    const [loading,setLoading]=useState(false)
    const [resultsSearch,setResultsSearch]=useState([])
    const [statusColaborator,setStatusColaborator]=useState('1')

    const getDataAditional =async (tipo)=>{
      
        const response = await request.get(`personalHana/allData/${tipo}`)
     
        if (response && response.statusCode === 200) {
            
            setDepartment(response.result.departamento)
            setTags(response.result.tags)
            let tags=[]
            response.result.p.forEach(element => {
              tags.push({
                label: `${element.name} ${element.lastName}`,
                value: element.id
              })
            });
          
            setTagsBoss(tags)
        }
      
    }
    const getData = async(statusCode) =>{
      setLoading(true)
      const response= await request.get(`getColaborators/${statusCode}`)
      if (response && response.statusCode === 200) {
        setColaborator(response.result.data.data)
      }
      setLoading(false)
      setStatusColaborator(statusCode)
    }
  
    useEffect(() => {
      getData(1);
      getDataAditional(type)
    }, []);
    
    const handleEdit = (user) => {
      let copy = JSON.parse(JSON.stringify(user))
         setUser(copy);
         setNewColaborator(true)
         setEdit(true)
      }
    const handleClose=()=>{
      setNewColaborator(false)
         getData();
         setUser(null)
         setEdit(false)
         setResultsSearch([])
    }
    const reload=()=>{
      getData();
    }

   
    const handleSearch =  (word) => {
      const copy = JSON.parse(JSON.stringify(colaborators));
      const lowerCaseWord = word.toLowerCase();
    
      const results = copy.filter((object) => {
        return (
          (object.name ?? '').toLowerCase().includes(lowerCaseWord) ||
          (object.wArea ?? '').toLowerCase().includes(lowerCaseWord) ||
          (object.lastName ?? '').toLowerCase().includes(lowerCaseWord) ||
          (object.puesto ?? '').toLowerCase().includes(lowerCaseWord)
        );
      });
    
      setResultsSearch(results);
     
      return  results.length>0?false:true
    };
    

  return (
    <Layout title='Gestión de Personal'>

      { newColaborator ? 
     
        <AddColaborator 
            {...
                {
                    user,
                    department,
                    tags,
                    tagsBoss,
                    getDataAditional,
                    handleClose,
                    newColaborator,
                    setUser,
                    edit,
                    loading

                }

            }
           
        />
          
        : (
          <>
          <div className='d-flex justify-content-end'>
           
              <button 
                className={`btn ${statusColaborator==1?'bg bg-gradient-dark':''}`} 
                onClick={e=>getData(1)}>Activos</button>
              <button 
                className={`btn ${statusColaborator==0?'bg bg-gradient-dark':''}`} 
                onClick={e=>getData(0)}>Inactivos</button>
              <button 
                className={`btn ${statusColaborator==2?'bg bg-gradient-dark':''}`} 
                onClick={e=>getData(2)}>Todos los registros</button>
           
          </div>
          <div className="d-flex justify-content-between" style={{overflowY:'auto'}}>
            <SearchComponent handleSearch={handleSearch} clearFunction={setResultsSearch}/>
            <div>
            <ButtonAddColaborator label={'nuevo  '} icon={' fa fa-user-plus '} classNameButtonParam={"mt-2 btn  bg bg-gradient-dark"} align={'start'} handleClick={e=>setNewColaborator(true)}/>
       
            </div>
           </div>

            <TableColaborators statusColaborator={statusColaborator} userImage={userImage} colaborators={resultsSearch.length>0?resultsSearch:colaborators} handleUpdate={handleEdit} reload={getData} loading={loading}/>
          </>
      )}
    </Layout>
  )
}
