import React, { useEffect,useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LayoutMovile } from "../Layouts/LayoutMovile";
import { TableInfinityFixed } from "../../components/TableInfinityFixed";
import { Ribbon } from "../../components/Ribbon";
import { useAppContext } from "../../Context/AppContext";
import VACATIONSDAYSPERYEARS from '../../utils/vacationsDays'
import Request from '../../utils/http'
import { SimpleModal } from "../../components/ReactModal/SimpleModal";
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment-timezone";
import Toast from "../../components/Toast/Toast";
const request = new Request();
const header=[
  {label:''},
  {label:'Periodo'},
  {label:'Regreso'},
  {label:'Dias'},
  {label:'Actual '},
  {label:'Status'},
]
export const VacationsMovilView = () => {
  const { urlToBack } = useParams();
  const {user,getInfo} = useAppContext()
  const [userdata,setUserdata]=useState([])
  const [totalDays,setTotalDays]=useState(0)
  const [days,setDays]=useState({programados:0,disponibles:0})
  const [festivosArr,setFestivos]=useState([])

  useEffect(() => {
      getInformationUser()
  },[])

  useEffect(() => {
    userdata.length>0 && calculateDays(userdata)
  },[userdata])

const calculateDays = (vac) => {
  let disponibles=0
  let programados=0

  
  vac.forEach(element => {
    const resultado = diasHabilesEntreFechas(element.start, element.end,festivosArr);
    programados=programados+resultado.diasHabiles
  });
  setDays({programados:programados,disponibles:totalDays-programados});
}


  const getInformationUser=async() => {
    let iduser= await getInfo()
    let ingreso= iduser.personalData.ingreso
    let dias= timediference('year',new Date(ingreso),new Date())
     
      setTotalDays(dias.dias)
      getData(iduser,dias.dias)
  }
  
  const getData = async(user,dias) => {
  
     const response = await request.get(`personal/getInformationbyidVacation/${user.id_personal}?year=${moment().format('YYYY')}`) 
    
   
    let fest=[]
    if (response && response.statusCode === 200) {
      response.result.festivos.forEach(element =>  fest.push(element.start));
      setFestivos(fest)
     
      let copy = response.result.data
      handleClculate(copy,dias)
    }
}

const handleClculate=(copy,dias) => {

  let programados=0
 
 copy.forEach(element => {
 
  element.periodo = `${moment(element.start).format('DD/MM/YYYY')} al ${moment(element.end).format('DD/MM/YYYY')}`
  switch (element.status) {
    case 0:
      element.autorizacion='en espera'
      break;
    case 1:
      element.autorizacion='en Proceso '
      break;
    case 2:
      element.autorizacion='Autorizado '
      break;
  
    default:
      element.autorizacion='Rechazado'
      break;
  }

  switch (element.taken) {
    case 0:
      element.actualState='Sin tomar'
      break;
    case 1:
      element.actualState='En curso'
      break;
    case 2:
      element.actualState='Terminado'
      break;
  
      
      default:
      element.actualState='Terminado'
      break;
  }
  element.open=false
  let d=diasHabilesEntreFechas(element.start, element.end,festivosArr);
 
 programados=programados+(d.diasHabiles)
 element.dias=d.diasHabiles
 
  
});

  // setDays({programados:programados, disponibles:dias-programados})

 
  setUserdata(copy)
  
 
}

const timediference=(type='year',inicio,fin)=>{
  let fechaInicio = inicio;
  let fechaFin = fin
  
  // Calcular la diferencia en milisegundos
  const diferenciaEnMilisegundos = fechaFin - fechaInicio;

  
  // Calcular la diferencia en años
  let milisegundosEnUnAno=0
  if (type=='year') {
    
     milisegundosEnUnAno = 1000 * 60 * 60 * 24 * 365.25; // Aproximadamente 365.25 días en un año para tener en cuenta años bisiestos
  }else{

     milisegundosEnUnAno = diferenciaEnMilisegundos /1000 * 60 * 60 * 24;
     return  milisegundosEnUnAno          // Aproximadamente 365.25 días en un año para tener en cuenta años bisiestos
  }
  const diferenciaEnAnos = diferenciaEnMilisegundos / milisegundosEnUnAno;

  // Redondear la diferencia si es necesario
  const annios = diferenciaEnAnos < 1 ? 1:Math.round(diferenciaEnAnos);
  // console.log("🚀 ~ file: index.jsx:70 ~ timediference ~ annios:", annios)

  
  const objetoConLabel3 = VACATIONSDAYSPERYEARS.find(item => item.label === annios);

  return objetoConLabel3
}

const handleOpen=(position)=>{
  let copy = JSON.parse(JSON.stringify(userdata));
  
  copy[position]['open'] = !copy[position]['open']
  setUserdata(copy)
}


function esFinDeSemana(fecha) {
  const dia = fecha.getDay();
  return dia === 5 || dia === 6; // 0 es Domingo, 6 es Sábado
}

function esFestivo(fecha, festivos) {
  const fechaStr = fecha.toISOString().split('T')[0];
 
 
  return festivos.includes(fechaStr);
}

function diasHabilesEntreFechas(fecha1, fecha2, festivos) {


  // Convertir las fechas de formato 'YYYY-MM-DD' a objetos Date
  const date1 = new Date(fecha1);
  const date2 = new Date(fecha2);

  // Asegurarse de que date1 es la fecha menor
  const [startDate, endDate] = date1 < date2 ? [date1, date2] : [date2, date1];

  let diasHabiles = 0;
  let currentDate = startDate;

  while (currentDate <= endDate) {
     
      if (!esFinDeSemana(currentDate) ) {
        if(!esFestivo(currentDate, festivos))
          {
            diasHabiles++;
          }
      }
          
      currentDate.setDate(currentDate.getDate() + 1);
  }

  // Calcular la siguiente fecha habil más próxima
  let siguienteFechaHabil = new Date(endDate);
  siguienteFechaHabil.setDate(siguienteFechaHabil.getDate() + 1); // Empezar el conteo desde el día siguiente a endDate

  while (esFinDeSemana(siguienteFechaHabil) || esFestivo(siguienteFechaHabil, festivos)) {
      siguienteFechaHabil.setDate(siguienteFechaHabil.getDate() + 1);
  }

  // Formatear la siguiente fecha habil en 'YYYY-MM-DD'
  const siguienteFechaHabilStr = siguienteFechaHabil.toISOString().split('T')[0];

  return {
      diasHabiles: diasHabiles,
      siguienteFechaHabil: siguienteFechaHabilStr
  };
}



  return (
    <>
     <ToastContainer 
     position="top-right"
     autoClose={5000}
     hideProgressBar={false}
     newestOnTop={false}
     closeOnClick
     rtl={false}
     pauseOnFocusLoss
     draggable
     pauseOnHover
     theme="colored"
     />
    <LayoutMovile showTabs={false}>
      <div className=" p-1">
        <div className="text-center">
        <h2>Mis vacaciones</h2>
        </div>

        <div className="input-group-sm">
          <Link className="btn btn-danger btn-sm" to={`/${urlToBack}`}>
            <i className="fa fa-reply font-12"></i>
          </Link>
        </div>
        <div className="card overflow-x-scroll " style={{ backgroundColor: "#f0f8ff08" }} >
          <div className="card-body d-flex ">
            <div class="col-12 col-xs-12 col-lg-3  text-center   shadow" style={{ backgroundColor: "#ffff", marginRight: "10px", borderRadius: "10px", }} >
              <div class="col-12 text-center">
                <h6 className="text-center  ">Disponibles este año {moment().format('YYYY')}: </h6>
                <h3 className="text-center text-danger">{totalDays}</h3>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-5 col-5 text-center mr-3  shadow" style={{ backgroundColor: "#ffff", marginRight: "10px", borderRadius: "10px", }}  >
              <div className="col-12 text-center">
                <h6 className="text-center  ">Disponibles </h6>
                <h3 className="text-center text-success">{days.disponibles}</h3>
             
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-5 col-5 text-center mr-3  shadow" style={{ backgroundColor: "#ffff", marginRight: "10px", borderRadius: "10px",  }}  >
              <div className="col-12 text-center">
                <h6 className="text-center  ">Programados </h6>
                <h3 className="text-center text-success">{days.programados}</h3>
             
              </div>
            </div>
            
          </div>
        </div>

      </div>
      <div className=" p-3 mb-5">
        
      <div className="d-flex justify-content-end" >
      <button className="btn btn-sm btn-info p-2" data-bs-toggle="modal" data-bs-target={`#ModalSolicitedVacation`}><i className="fa fa-plus font-15"></i></button>
     
      </div>
       

<div className="row">
        {userdata.map((day,i)=>{
        
           const {periodo,autorizacion,start,comments,end,dias} = day
         
           const resultado = diasHabilesEntreFechas(start, end,festivosArr);
          
          return(
                <div className="col-md-12 mb-2  p-2" key={i} >
                    <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12 text-center   shadow"  style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px'}}>
                      { day.consumido && <Ribbon text="Consumido" /> }
                            <h6 className=" ">  Periodo de vacaciones: {periodo} </h6>
                        <div  className="col-10 d-flex justify-content-between ">
                          <div className="ml-3 ">
                              <small>Dias:</small>
                              <h6 className="text-xs">{resultado.diasHabiles}</h6>
                          </div>
                          <div className="">
                             <small> Dia de presentacion:</small>
                              <h6 className="text-xs">{moment(resultado.siguienteFechaHabil).format('DD/MM/YYYY')}</h6>
                          </div>
                          <div className="">
                            
                           <small> Status:</small>
                           <h6 className="text-xs">{autorizacion}</h6>
                          </div>
                            
                        </div>
                       {comments && <div className="p-2">
                           <textarea cols="3" rows="2" className='form-control' disabled value={comments}></textarea>
                        </div>}
                      </div>
                  </div>

                  )
                  })}
              </div>
           </div>
     
    </LayoutMovile>
    
    <ModalSolicitedVacation reload={getData} user={user} timediference={timediference} festivosArr={festivosArr} />
    </>
  );
};

const ModalSolicitedVacation = ({reload,user,timediference,festivosArr})=> {

  const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
  const [solicitud,setSolicitud]=useState({
    type:1,
    start:moment().format('YYYY-MM-DD'),
    end:moment().format('YYYY-MM-DD'),
    dayToComeBack:'',
    comments:'',
  })
  const [dias,setTotalDays]=useState(0)

  const handleSubmit=async()=>{
    let vacationsDays=JSON.parse(JSON.stringify(solicitud))
    let inmmBoss= JSON.parse(user.personalData.inmBoss)
    let dept=JSON.parse(user.dept)
    vacationsDays.id_inmboss=inmmBoss.value
    vacationsDays.id_workareaBoss=dept.value
    
    const response = await request.post(`events/nuevaSolicitd/${user.id_personal}`,vacationsDays)
    if (response && response.statusCode==200) {
    
      reload(user)
      setSolicitud({
        type:0,
        start:'',
        end:'',
        dayToComeBack:'',
        comments:'',
      })
      setToastAlert({text:'Guardado con exito',type:'success'},)
    }
  }
const handleTime=(value,bandera) => {
 
  let  fecha1=''
  let  fecha2=''

  if (bandera=='start') {
     fecha1 = new Date(value); // Fecha inicial
     fecha2 = new Date(solicitud.end); 
  }else{
    fecha1 = new Date(solicitud.start); // Fecha inicial
    fecha2 = new Date(value); 
  }

  let retorno =diasHabilesEntreFechas(fecha1,fecha2,festivosArr)
  setSolicitud({...solicitud,[bandera]:value,dayToComeBack:moment(retorno.siguienteFechaHabil).format('YYYY-MM-DD')})
  setTotalDays( retorno.diasHabiles)
 
}



function esFinDeSemana(fecha) {
  const dia = fecha.getDay();
  return dia === 5 || dia === 6; // 0 es Domingo, 6 es Sábado
}

function esFestivo(fecha, festivos) {
  const fechaStr = fecha.toISOString().split('T')[0];
  return festivos.includes(fechaStr);
}

function diasHabilesEntreFechas(fecha1, fecha2, festivos) {
  // Convertir las fechas de formato 'YYYY-MM-DD' a objetos Date
  const date1 = new Date(fecha1);
  const date2 = new Date(fecha2);

  // Asegurarse de que date1 es la fecha menor
  const [startDate, endDate] = date1 < date2 ? [date1, date2] : [date2, date1];

  let diasHabiles = 0;
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
      if (!esFinDeSemana(currentDate) && !esFestivo(currentDate, festivos)) {
          diasHabiles++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
  }

  // Calcular la siguiente fecha habil más próxima
  let siguienteFechaHabil = new Date(endDate);
  siguienteFechaHabil.setDate(siguienteFechaHabil.getDate() + 1); // Empezar el conteo desde el día siguiente a endDate

  while (esFinDeSemana(siguienteFechaHabil) || esFestivo(siguienteFechaHabil, festivos)) {
      siguienteFechaHabil.setDate(siguienteFechaHabil.getDate() + 1);
  }

  // Formatear la siguiente fecha habil en 'YYYY-MM-DD'
  const siguienteFechaHabilStr = siguienteFechaHabil.toISOString().split('T')[0];

  return {
      diasHabiles: diasHabiles,
      siguienteFechaHabil: siguienteFechaHabilStr
  };
}

  return(
    <SimpleModal identifier='ModalSolicitedVacation' heightProp="530">
      <Toast {...{toastAlertProps}} />
      <div className='p-3 input-group-sm' >
        <div>
          <label >Seleccione el tipo de solicitud</label>
          <select onChange={e=>setSolicitud({...solicitud,type:e.target.value})} value={solicitud.type} className="form-control">
            <option value="0">Selecciona una opcion</option>
            <option value="1">Vacaciones</option>
            <option value="2">Permiso con goce de sueldo</option>
            <option value="3">Permiso sin goce de sueldo</option>
          </select>
        </div>
        <div>
          <label > Inicio</label>
          <input onChange={e=>handleTime(e.target.value,'start')} value={solicitud.start} type="date" class="form-control" />
        </div>
        <div>
          <label > Fin</label>
          <input onChange={e=>handleTime(e.target.value,'end')} value={solicitud.end} type="date" class="form-control" />
        </div>
        <div>
          <label > Fecha de Presentacion</label>
          <input onChange={e=>setSolicitud({...solicitud,dayToComeBack:e.target.value})} value={solicitud.dayToComeBack} type="date" class="form-control" />
        </div>
        <div className="  font-25 text-center"> Dias solicitados: {dias} </div>
        <div>
          <label > Comentarios</label>
          <textarea onChange={e=>setSolicitud({...solicitud,comments:e.target.value})} value={solicitud.comments} type="date" class="form-control" />
        </div>
        <div className="tex-end mt-2 text-end" >
          <button className="btn btn-sm bg bg-gradient-dark" onClick={handleSubmit}> Solicitar </button>
        </div>


      </div>

    </SimpleModal>
  )
  }