import React, { useEffect,useState } from 'react'
import Request from '../../../../utils/http'
import moment from 'moment-timezone'
import { calcularDiferenciaHoras } from '../../../../Hooks/timeTransform'
import { calcularDistanciaEnMetros } from '../../../../Hooks/calcularDistanciaEnMetros'
import Modal from 'react-modal'
import { TableInfinityFixed } from '../../../../components/TableInfinityFixed'
const request = new Request()
export const StaticsColaborator = () => {
    const [asistenciasState,setasistencias]=useState([])
    const [inasistenciaState,setinasistencia]=useState([])
    const [retardosState,setretardos]=useState([])
    const [fuerastate,setFuera]=useState([])
    const [generalState,setgeneral]=useState([])
    const [resumenState,setresumen]=useState([])
    const getData = async(inicio='',fin) =>{
        const response = await request.get(`asistences/getChecksReport?dateStart=${inicio}&dateEnd=${fin}`)
        if (response && response.statusCode === 200) {  
          
            transformData(response.result.data,response.result.fechas)
        }

    }
    const transformData=(personal,fechas) => {
        const baseLatitud = 20.6208046
    const baseLongitud = -87.080208;
        let asistencias=[]
        let inasistencia=[]
        let retardos = []
        let general=[]
        let resumen = []
        let fuera = []

        personal.forEach(element => {
            resumen.push({
               colaborador: element.name+' '+element.lastName,
               retardos:element.retardos.length,
               faltas:element.faltas.length,
               permiso_gose_sueldo:element.consueldo.length,
               permiso_sin_sueldo:element.sinsueldo.length,
               incapacidades:element.incapacidades.length,

            })
           resumen.push({
                colaborador:'',
                retardos:'',
                faltas:'RETARDOS',
                permiso_gose_sueldo:'',
                permiso_sin_sueldo:'',
                incapacidades:'',
            })
            element.retardos.forEach(retardo => {
                resumen.push({
                    colaborador:'',
                    retardos:retardo.fecha,
                    faltas:retardo.justificantes[0].time,
                    permiso_gose_sueldo:calcularDiferenciaHoras('08:55:59',moment(retardo.justificantes[0].time).format('HH:mm:ss')),
                    permiso_sin_sueldo:'',
                    incapacidades:'',
                })
            });
            resumen.push({
                colaborador:'',
                retardos:'',
                faltas:'FALTAS',
                permiso_gose_sueldo:'',
                permiso_sin_sueldo:'',
                incapacidades:'',
            })
            element.faltas.forEach(falta => {
                resumen.push({
                    colaborador:'',
                    retardos:falta.fecha,
                    faltas:falta.justificantes.length>0?falta.justificantes[0].file_url:'Sin justificante',
                    permiso_gose_sueldo:'',
                    permiso_sin_sueldo:'',
                    incapacidades:'',
                })
            });
            resumen.push({
                colaborador:'',
                retardos:'',
                faltas:'CON GOSE DE SUELDO',
                permiso_gose_sueldo:'',
                permiso_sin_sueldo:'',
                incapacidades:'',
            })
            element.consueldo.forEach(falta => {
                resumen.push({
                    colaborador:'',
                    retardos:falta.fecha,
                    faltas:'Sin justificante',
                    permiso_gose_sueldo:'',
                    permiso_sin_sueldo:'',
                    incapacidades:'',
                })
            });
            resumen.push({
                colaborador:'',
                retardos:'',
                faltas:'SIN GOSE DE SUELDO',
                permiso_gose_sueldo:'',
                permiso_sin_sueldo:'',
                incapacidades:'',
            })
            element.sinsueldo.forEach(falta => {
                resumen.push({
                    colaborador:'',
                    retardos:falta.fecha,
                    faltas:'Sin justificante',
                    permiso_gose_sueldo:'',
                    permiso_sin_sueldo:'',
                    incapacidades:'',
                })
            });
            resumen.push({
                colaborador:'',
                retardos:'',
                faltas:'INCAPACIDAD',
                permiso_gose_sueldo:'',
                permiso_sin_sueldo:'',
                incapacidades:'',
            })
            element.incapacidades.forEach(falta => {
                resumen.push({
                    colaborador:'',
                    retardos:falta.fecha,
                    faltas:falta.justificantes.length>0?falta.justificantes[0].file_url:'Sin justificante',
                    permiso_gose_sueldo:'',
                    permiso_sin_sueldo:'',
                    incapacidades:'',
                })
            });
        })
      

        fechas.forEach(fecha => {
            if (!esFinDeSemana(fecha))
                {
                    asistencias.push({ dia:fecha,estatus:'',colaborador:'',horaEntrada:'',latitud:'',longitud:'',distanciaOficina:'',horaSalida:'', tiempo:'',comentarios:''})
                    inasistencia.push({ dia:fecha,estatus:'',colaborador:'',horaEntrada:'',latitud:'',longitud:'',distanciaOficina:'',horaSalida:'', tiempo:'',justificante:''})
                    retardos.push({ dia:fecha,estatus:'',colaborador:'',horaEntrada:'',latitud:'',longitud:'',distanciaOficina:'',horaSalida:'', tiempo:'',justificante:''})
                   
                    personal.forEach(element => {
                        element.checks.forEach(check => {
                            if (check.fecha ==fecha) {
                                if(check.registros.length >0){
                                    check.registros.forEach(registro => {
                                        registro.colaborador=element.name+' '+element.lastName
                                        general.push(registro)
                                        
                                    });
                                    let distancia = calcularDistanciaEnMetros(baseLatitud, baseLongitud, check.registros[0].latitude, check.registros[0].longitude);
                                    if (distancia > 20 ) {
                                        fuera.push({
                                            dia:'',
                                            estatus:isRetardo(check.registros[0].time),
                                            colaborador:element.name+' '+element.lastName,
                                            horaEntrada:moment(check.registros[0].time).format('HH:mm:ss'),
                                            latitud:check.registros[0].latitude,
                                            longitud:check.registros[0].longitude,
                                            distanciaOficina:parseFloat(distancia).toFixed(2) +' mts de la oficina',
                                            horaSalida:moment(check.registros[check.registros.length-1].time).format('HH:mm:ss'),
                                            tiempo:calcularDiferenciaHoras(moment(check.registros[0].time).format('HH:mm:ss'),moment(check.registros[check.registros.length-1].time).format('HH:mm:ss')),
                                            comentarios:check.registros[0].comments,
                                        })
                                    }
                                    asistencias.push({
                                        dia:'',
                                        estatus:isRetardo(check.registros[0].time),
                                        colaborador:element.name+' '+element.lastName,
                                        horaEntrada:moment(check.registros[0].time).format('HH:mm:ss'),
                                        latitud:check.registros[0].latitude,
                                        longitud:check.registros[0].longitude,
                                        distanciaOficina:parseFloat(distancia).toFixed(2) +' mts de la oficina',
                                        horaSalida:moment(check.registros[check.registros.length-1].time).format('HH:mm:ss'),
                                        tiempo:calcularDiferenciaHoras(moment(check.registros[0].time).format('HH:mm:ss'),moment(check.registros[check.registros.length-1].time).format('HH:mm:ss')),
                                        comentarios:check.registros[0].comments,
                                    })
                                    let retardo = isRetardo(check.registros[0].time)
                                    if (retardo=='Retardo') {
                                        retardos.push({
                                            dia:'',
                                            estatus:isRetardo(check.registros[0].time),
                                            colaborador:element.name+' '+element.lastName,
                                            horaEntrada:moment(check.registros[0].time).format('HH:mm:ss'),
                                            latitud:check.registros[0].latitude,
                                            longitud:check.registros[0].longitude,
                                            distanciaOficina:parseFloat(distancia).toFixed(2) +' mts de la oficina',
                                            horaSalida:moment(check.registros[check.registros.length-1].time).format('HH:mm:ss'),
                                            tiempo:calcularDiferenciaHoras(moment(check.registros[0].time).format('HH:mm:ss'),moment(check.registros[check.registros.length-1].time).format('HH:mm:ss')),
                                            comentarios:check.registros[0].comments,
                                        })
                                    }
                                    
                                }else{
                                   let asistencia='No justificada'
                                   let url=''
                                    check.justificantes.forEach(element => {
                                        if (element.date==fecha) {
                                            console.log("🚀 ~ transformData ~ element:", element)
                                            asistencia=element.type==1?'justificada':element.type==2?'Permiso con gose de sueldo':'Permiso sin gose de sueldo'
                                            url=element.type==1?element.file_url:''
                                        }
                                    });
                                    
                                    inasistencia.push({
                                        dia:'',
                                        estatus:asistencia,
                                        colaborador:element.name+' '+element.lastName,
                                        horaEntrada:'',
                                        latitud:'',
                                        longitud:'',
                                        distanciaOficina:0 +' mts de la oficina',
                                        horaSalida:'',
                                        tiempo:'00:00:00',
                                        justificante:url
                                    })
                                }
                                
                            }
                        })
                                   
                    });
                }
                    
        });
       
        setasistencias(asistencias)
        setinasistencia(inasistencia)
        setretardos(retardos)
        setgeneral(general)
        setresumen(resumen)
        setFuera(fuera)
    }

    useEffect(()=>{
        let inicio = moment().format('YYYY-MM-DD')
        let fin= moment().format('YYYY-MM-DD')

        getData(inicio,fin)
    },[])
    function esFinDeSemana(fecha) {
        // Convertir la fecha a un objeto Date
        let date = new Date(fecha);
    
        // Obtener el día de la semana (0 = Domingo, 6 = Sábado)
        let diaSemana = date.getDay();
   
        // Verificar si es sábado o domingo
        return diaSemana === 5 || diaSemana === 6;
    }
    const isRetardo=(hora) => {
  
        let horaLimite = moment('08:55:00 AM', 'hh:mm:ss A');
          let horaLimiteString = horaLimite.format('HH:mm:ss');
          
          let entradaStatus = ''
          
          let entrada=moment(hora).format('HH:mm:ss')
          
          if (entrada>horaLimiteString) {
              entradaStatus ="Retardo"
              
              } else if (entrada==horaLimiteString) {
                entradaStatus ="Puntual"
                  
              } else {
                entradaStatus ="Temprano"
            }

          
            return entradaStatus;
          
      }
  return (
    <>
    <div class="row mt-4">
              <div class="col-md-6 ">
                <ModalAsistences {...{asistenciasState}}/>
              </div>
              <div class="col-md-6 mt-md-0 mt-4">
                <ModalInasistences {...{inasistenciaState}} />
                
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <ModalRetardos {...{retardosState}} />
              </div>
              <div class="col-md-6 mt-md-0 mt-4">
                <ModalOutOfRange {...{fuerastate}} />
             
              </div>
            </div>
    </>
  )
}

const ModalAsistences=({asistenciasState}) => {
   
    const header=[
        {label:'Estatus de la entrada'},
        {label:'Colaborador'},
        {label:'Hora de entrada'},
        {label:'Distancia a la oficina'},
    ]
 
    const [open, setopen] = useState(false);
    const [coords,setCoords]=useState([0,0])
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "-35%",
          width: "80%",
          height: "59%",
          transform: "translate(-50%, -50%)",
        },
      };
    return(
        <>
        <div class="card " onClick={e=>setopen(true)}>
                  <div class="card-body text-center bg-gradient-info hovercard shadow crecer"  style={{borderRadius:10}}>
                    <img  src="https://static.vecteezy.com/system/resources/previews/000/550/535/original/user-icon-vector.jpg" style={{width:100,borderRadius:20}} alt="" />
                    <h1 class="text-gradient-dark  text-white">
                    {(asistenciasState.length) - 1}
                    </h1>
                    <h6 class="mb-0 font-weight-bolder  text-white">Colaboradores </h6>
                    
                    <p class="opacity-8 mb-0 text-sm  text-white">en oficina</p>
                  </div>
                </div>
        
        <Modal  isOpen={open} onRequestClose={e=>setopen(false)} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} style={customStyles} >
<h2>Detalles de asistencia</h2>
            <div className="d-flex">
            <div>
            <TableInfinityFixed columnsParam={header} height={400} aditionalClass={'table-striped'}>
                {asistenciasState.map((line,i)=>{
                    console.log(line);
                    const {colaborador,estatus,horaEntrada,distanciaOficina}=line
                   return(
                    <tr key={i}onClick={e=>setCoords([line.latitud, line.longitud])}>
                        <td className={estatus=='Retardo'?'text-danger':'text-success'}>{estatus}</td>
                        <td>{colaborador}</td>
                        <td>{horaEntrada}</td>
                        <td>{distanciaOficina}</td>
                    </tr>
                   )
                })}
            </TableInfinityFixed>
            </div>
            <div style={{height: '400px'}} className=' m-3 card shadow p-3'>
                  <iframe src={`https://maps.google.com/maps?q=${coords[0]}, ${coords[1]}&z=15&output=embed`} width="600" height="500" frameBorder="0" style={{border:0}}></iframe>
             </div>
            </div>
        </Modal>
        </>
    )
}
const ModalInasistences=({inasistenciaState}) => {
   
    const header=[
        {label:'Estatus de la inasistencia'},
        {label:'Colaborador'},
    ]
 
    const [open, setopen] = useState(false);
    const [coords,setCoords]=useState([0,0])
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "-35%",
          width: "80%",
          height: "59%",
          transform: "translate(-50%, -50%)",
        },
      };
    return(
        <>
      <div class="card " onClick={e=>setopen(true)}>
                  <div class="card-body text-center  bg-gradient-danger shadow crecer" style={{borderRadius:10}}>
                    
                    <img src="https://cdn1.iconfinder.com/data/icons/people-62/32/16-_no_-_people-_warning-_delete-_user-512.png" style={{width:100}} alt="" />
                    <h1 class="text-gradient-dark text-white ">
                    {(inasistenciaState.length) - 1}
                    </h1>
                    <h6 class="mb-0 font-weight-bolder text-white">Inasistencias </h6>
                    
                    <p class="opacity-8 mb-0 text-sm text-white">hoy</p>
                  </div>
                </div>
        
        <Modal  isOpen={open} onRequestClose={e=>setopen(false)} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} style={customStyles} >
<h2>Detalles de asistencia</h2>
            
            <div>
            <TableInfinityFixed columnsParam={header} height={400} aditionalClass={'table-striped'}>
                {inasistenciaState.map((line,i)=>{
                  
                    const {colaborador,estatus,horaEntrada,distanciaOficina}=line
                   return(
                    <tr key={i}onClick={e=>setCoords([line.latitud, line.longitud])}>
                        <td className={'text-danger'}>{estatus}</td>
                        <td>{colaborador}</td>
                    </tr>
                   )
                })}
            </TableInfinityFixed>
            </div>
           
        </Modal>
        </>
    )
}
const ModalRetardos=({retardosState}) => {
   
    const header=[
        {label:'Estatus de la entrada'},
        {label:'Colaborador'},
        {label:'Hora de entrada'},
        {label:'Distancia a la oficina'},
    ]
 
    const [open, setopen] = useState(false);
    const [coords,setCoords]=useState([0,0])
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "-35%",
          width: "80%",
          height: "59%",
          transform: "translate(-50%, -50%)",
        },
      };
    return(
        <>
      
                <div class="card " onClick={e=>setopen(true)}>
                <div class="card-body text-center  bg-gradient-warning shadow crecer" style={{borderRadius:10}}>
                    
                  <img src="https://cdn-icons-png.flaticon.com/512/7609/7609957.png" style={{width:100,borderRadius:20}} alt="" />
                    <h1 class="text-gradient-dark text-white ">
                    {(retardosState.length) - 1}
                    </h1>
                    <h6 class="mb-0 font-weight-bolder text-white">Retardos </h6>
                    
                    <p class="opacity-8 mb-0 text-sm text-white">hoy</p>
                  </div>
                </div>
        
        <Modal  isOpen={open} onRequestClose={e=>setopen(false)} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} style={customStyles} >
<h2>Detalles de asistencia</h2>
            <div className="d-flex">
            <div>
            <TableInfinityFixed columnsParam={header} height={400} aditionalClass={'table-striped'}>
                {retardosState.map((line,i)=>{
                   
                const {colaborador,estatus,horaEntrada,distanciaOficina}=line
                   return(
                    <tr key={i}onClick={e=>setCoords([line.latitud, line.longitud])}>
                        <td className={estatus=='Retardo'?'text-danger':'text-success'}>{estatus}</td>
                        <td>{colaborador}</td>
                        <td>{horaEntrada}</td>
                        <td>{distanciaOficina}</td>
                    </tr>
                   )
                })}
            </TableInfinityFixed>
            </div>
            <div style={{height: '400px'}} className=' m-3 card shadow p-3'>
                  <iframe src={`https://maps.google.com/maps?q=${coords[0]}, ${coords[1]}&z=15&output=embed`} width="600" height="500" frameBorder="0" style={{border:0}}></iframe>
             </div>
            </div>
        </Modal>
        </>
    )
}
const ModalOutOfRange=({fuerastate}) => {
   
    const header=[
        {label:'Estatus de la entrada'},
        {label:'Colaborador'},
        {label:'Hora de entrada'},
        {label:'Distancia a la oficina'},
    ]
 
    const [open, setopen] = useState(false);
    const [coords,setCoords]=useState([0,0])
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "-35%",
          width: "80%",
          height: "59%",
          transform: "translate(-50%, -50%)",
        },
      };
    return(
        <>
       
                <div class="card " onClick={e=>setopen(true)}>
                <div class="card-body text-center  bg-gradient-warning shadow crecer" style={{borderRadius:10}}>
                  <img src="https://i.pinimg.com/originals/f4/90/ec/f490ecac149b5c233b46a096b38e6860.png" style={{width:100,borderRadius:20}} alt="" />
                    <h1 class="text-gradient-dark text-white ">
                    {(fuerastate.length) - 1}
                    </h1>
                    <h6 class="mb-0 font-weight-bolder text-white">Distancia check +40 mts </h6>
                    
                    <p class="opacity-8 mb-0 text-sm text-white">hoy</p>
                  
                  </div>
                </div>
        
        <Modal  isOpen={open} onRequestClose={e=>setopen(false)} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} style={customStyles} >
<h2>Detalles de asistencia</h2>
            <div className="d-flex">
            <div>
            <TableInfinityFixed columnsParam={header} height={400} aditionalClass={'table-striped'}>
                {fuerastate.map((line,i)=>{
                  
                    const {colaborador,estatus,horaEntrada,distanciaOficina}=line
                   return(
                    <tr key={i}onClick={e=>setCoords([line.latitud, line.longitud])}>
                        <td className={estatus=='Retardo'?'text-danger':'text-success'}>{estatus}</td>
                        <td>{colaborador}</td>
                        <td>{horaEntrada}</td>
                        <td>{distanciaOficina}</td>
                    </tr>
                   )
                })}
            </TableInfinityFixed>
            </div>
            <div style={{height: '400px'}} className=' m-3 card shadow p-3'>
                  <iframe src={`https://maps.google.com/maps?q=${coords[0]}, ${coords[1]}&z=15&output=embed`} width="600" height="500" frameBorder="0" style={{border:0}}></iframe>
             </div>
            </div>
        </Modal>
        </>
    )
}