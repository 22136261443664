// import React, { Fragment, useEffect, useState,useRef,useCallback   } from 'react'

// import {  useAppContext } from '../../../../Context/AppContext';
// import { collection, getDocs, addDoc, query, orderBy, where, doc, updateDoc, Timestamp, runTransaction } from 'firebase/firestore';
// import { db } from '../../../../firebaseConfig';
// export const IndividualChat = ({setTypeOfChat,idChat,chatWhith,lastDate}) => {
  
//     const {user,getInfo} = useAppContext()
//     const chatContainerRef = useRef(null);
//     const [chats, setChats] = useState([]);
//     const [newMessage, setNewMessage] = useState('');
//     const [sender, setSender] = useState({
//         id:0,
//         name:''
//     });
//     const [lastMessage, setLastMessage] = useState('')
//     const [firstVisible, setFirstVisible] = useState(null); 
//     const [currentChatId, setCurrentChatId] = useState(null);
//     const [messages, setMessages] = useState([]);
//   const messagesEndRef = useRef(null);
//   const batchSize = 15; // Tamaño de la página

  
  
//     useEffect(() => {
//         if(user!='tengo estado') {
           
//             loadMessages(idChat)
//             setSender({
//               id: user.personalData.id,
//               name: `${user.personalData.name} ${user.personalData.lastName}`
//             });
//         }else{
//          getInfo()
//         }
//     }, []);
 
//     useEffect(() => {
//         // Desplaza la vista hacia el final cuando se actualice la lista de chats
//         if (chatContainerRef.current) {
//           chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//         }
//       }, [chats]);


//       const loadMessages = async (chatId) => {
//         const chatMessages = await fetchMessages(chatId);
//         setMessages(chatMessages);
//         setCurrentChatId(chatId);
//       };
    
//       const handleSendMessage = async (e) => {
//         e.preventDefault();
//         if (newMessage.trim() !== '') {
//           await sendMessage(currentChatId, sender.id, newMessage);
//           setNewMessage('');
//           loadMessages(currentChatId); // Reload messages after sending a new one
//         }
//       };
  
//       const handleScroll = () => {
//         if (chatContainerRef.current) {
//           const { scrollTop } = chatContainerRef.current;
//           if (scrollTop === 0) {
//             fetchChats(sender.id);// Cargar más mensajes cuando el usuario se desplaza hacia arriba
//           }
//         }
//       };

//        const fetchChats = async (userId) => {
//         const chatsCollection = collection(db, 'chats');
//         const q = query(chatsCollection, where('participants', 'array-contains', userId));
//         const chatSnapshot = await getDocs(q);
//         const chatList = chatSnapshot.docs.map(doc => ({
//           id: doc.id,
//           ...doc.data()
//         }));
//         return chatList;
//       };
      
//       // Fetch messages for a specific chat
//        const fetchMessages = async (chatId) => {
//         const messagesCollection = collection(db, 'chats', chatId, 'messages');
//         const q = query(messagesCollection, orderBy('timestamp', 'asc'));
//         const messagesSnapshot = await getDocs(q);
//         const messageList = messagesSnapshot.docs.map(doc => ({
//           id: doc.id,
//           ...doc.data()
//         }));
//         return messageList;
//       };
      
      
      
//        const sendMessage = async (chatId, senderId, message) => {
//         const chatDocRef = doc(db, 'chats', chatId);
//         const messagesCollection = collection(chatDocRef, 'messages');
      
//         try {
//           await runTransaction(db, async (transaction) => {
//             // Add the new message
//             const newMessageRef = await addDoc(messagesCollection, {
//               sender: senderId,
//               message: message,
//               timestamp: Timestamp.now()
//             });
      
//             // Update the chat's lastMessage and timestamp fields
//             transaction.update(chatDocRef, {
//               lastMessage: message,
//               timestamp: Timestamp.now()
//             });
      
//             return newMessageRef;
//           });
//         } catch (error) {
//           console.error('Error sending message and updating chat: ', error);
//         }
//       };
      
 
      
//   return (
//     <div className="col-12">
//     <button className="btn btn-dark"onClick={e=>setTypeOfChat(0)}><i className="fa fa-reply"></i>&nbsp;regresar</button>
//       <div className="card blur shadow-blur max-height-vh-70">
//         <div className="card-header shadow-lg">
//           <div className="row">
//             <div className="col-md-10">
//               <div className="d-flex align-items-center">
//                 <img alt="Image" src="https://th.bing.com/th/id/R.c33d127fc827b9c519bd6cfe6d937e30?rik=TyLeJpJeJc9rdA&pid=ImgRaw&r=0" className="avatar"/>
//                 <div className="ms-3">
//                   <h6 className="mb-0 d-block"> {chatWhith}</h6>
//                   <span className="text-sm text-dark opacity-8">Ultimo mensaje {lastDate}</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="card-body overflow-auto overflow-x-hidden"  ref={chatContainerRef}  onScroll={handleScroll}>
//         {messages.length>0 &&(
//             messages.map((chat,key)=>{
//                 let fecha = chat.timestamp.toDate()
//                 return(
//                     <Fragment key={chat.id}>
//                         {
//                         chat.sender==sender.id? <SelfMessage message={chat.message} timestamp={fecha.toLocaleString()} />:
//                         <GenarlMessage message={chat.message} timestamp={fecha.toLocaleString()} name={chat.sender.name} />
//                     }
//                     </Fragment>
//                 )
//             })
//         )}
//          <div ref={messagesEndRef} /> 
//         </div>
//         <div className="card-footer d-block">
//           <form className="align-items-center">
//             <div className="d-flex">
//               <div className="input-group">
//                 <input type="text" value={newMessage} onChange={e=>setNewMessage(e.target.value)} className="form-control" placeholder="Escribe aqui ..." aria-label="Message example input"/>
//               </div>
//               <button className="btn bg-gradient-info mb-0 ms-2 btn-rounded" onClick={e=>handleSendMessage(e)}>
//                 <i className="ni ni-send"></i>
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   )
// }

// const SelfMessage=({message,timestamp})=>{
//     return(
//         <div className="row justify-content-end text-right mb-4">
//         <div className="col-auto">
//           <div className="card bg-gray-200">
//             <div className="card-body py-2 px-3">
//               <p className="mb-0">
//                {message}
//               </p>
//               <div className="d-flex align-items-center justify-content-end text-sm opacity-6">
//                 <i className="ni ni-check-bold text-sm me-1"></i>
//                 <small>{timestamp}</small>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
// }

// const GenarlMessage=({message,timestamp,name}) => {
  
//     return(
//         <div className="row justify-content-start mb-4">
//           <div className="col-auto">
//             <div className="card ">
//                 <div className="card-body py-2 px-3">
//                 <p className="mb-1">
//                     {message}
//                 </p>
//                 <div className="d-flex align-items-center text-sm opacity-6">
//                     <i className="ni ni-check-bold text-sm me-1"></i>
//                     <small>{name} {timestamp}</small>
//                 </div>
//                 </div>
//             </div>
//           </div>
//         </div>
//     )
// }


// {/* <div className="row justify-content-start mb-4">
// <div className="col-auto">
//   <div className="card ">
//     <div className="card-body py-2 px-3">
//       <p className="mb-1">
//         It contains a lot of good lessons about effective practices
//       </p>
//       <div className="d-flex align-items-center text-sm opacity-6">
//         <i className="ni ni-check-bold text-sm me-1"></i>
//         <small>3:14am</small>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// <div className="row justify-content-end text-right mb-4">
// <div className="col-auto">
//   <div className="card bg-gray-200">
//     <div className="card-body py-2 px-3">
//       <p className="mb-1">
//         Can it generate daily design links that include essays and data visualizations ?<br/>
//       </p>
//       <div className="d-flex align-items-center justify-content-end text-sm opacity-6">
//         <i className="ni ni-check-bold text-sm me-1"></i>
//         <small>4:42pm</small>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// <div className="row mt-4">
// <div className="col-md-12 text-center">
//   <span className="badge text-dark">Wed, 3:27pm</span>
// </div>
// </div>
// <div className="row justify-content-start mb-4">
// <div className="col-auto">
//   <div className="card ">
//     <div className="card-body py-2 px-3">
//       <p className="mb-1">
//         Yeah! Responsive Design is geared towards those trying to build web apps
//       </p>
//       <div className="d-flex align-items-center text-sm opacity-6">
//         <i className="ni ni-check-bold text-sm me-1"></i>
//         <small>4:31pm</small>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// <div className="row justify-content-end text-right mb-4">
// <div className="col-auto">
//   <div className="card bg-gray-200">
//     <div className="card-body py-2 px-3">
//       <p className="mb-1">
//         Excellent, I want it now !
//       </p>
//       <div className="d-flex align-items-center justify-content-end text-sm opacity-6">
//         <i className="ni ni-check-bold text-sm me-1"></i>
//         <small>4:42pm</small>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// <div className="row justify-content-start mb-4">
// <div className="col-auto">
//   <div className="card ">
//     <div className="card-body py-2 px-3">
//       <p className="mb-1">
//         You can easily get it; The content here is all free
//       </p>
//       <div className="d-flex align-items-center text-sm opacity-6">
//         <i className="ni ni-check-bold text-sm me-1"></i>
//         <small>4:42pm</small>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// <div className="row justify-content-end text-right mb-4">
// <div className="col-auto">
//   <div className="card bg-gray-200">
//     <div className="card-body py-2 px-3">
//       <p className="mb-1">
//         Awesome, blog is important source material for anyone who creates apps? <br/>
//         Beacuse these blogs offer a lot of information about website development.
//       </p>
//       <div className="d-flex align-items-center justify-content-end text-sm opacity-6">
//         <i className="ni ni-check-bold text-sm me-1"></i>
//         <small>4:42pm</small>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// <div className="row justify-content-start mb-4">
// <div className="col-5">
//   <div className="card ">
//     <div className="card-body p-2">
//       <div className="col-12 p-0">
//         <img
//           src="https://images.unsplash.com/photo-1602142946018-34606aa83259?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1762&q=80"
//           alt="Rounded image" className="img-fluid mb-2 border-radius-lg"/>
//       </div>
//       <div className="d-flex align-items-center text-sm opacity-6">
//         <i className="ni ni-check-bold text-sm me-1"></i>
//         <small>4:47pm</small>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// <div className="row justify-content-end text-right mb-4">
// <div className="col-auto">
//   <div className="card bg-gray-200">
//     <div className="card-body py-2 px-3">
//       <p className="mb-0">
//         At the end of the day … the native dev apps is where users are
//       </p>
//       <div className="d-flex align-items-center justify-content-end text-sm opacity-6">
//         <i className="ni ni-check-bold text-sm me-1"></i>
//         <small>4:42pm</small>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// <div className="row justify-content-start">
// <div className="col-auto">
//   <div className="card ">
//     <div className="card-body py-2 px-3">
//       <p className="mb-0">
//         Charlie is Typing...
//       </p>
//     </div>
//   </div>
// </div>
// </div> */}

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useAppContext } from '../../../../Context/AppContext';
import { collection, addDoc, query, orderBy, where, doc, runTransaction, Timestamp, onSnapshot } from 'firebase/firestore';
import { db,storage } from '../../../../firebaseConfig';
import Modal from 'react-modal'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
export const IndividualChat = ({ setTypeOfChat, idChat, chatWhith, lastDate }) => {
    const { user, getInfo } = useAppContext();
    const chatContainerRef = useRef(null);
    const [newMessage, setNewMessage] = useState('');
    const [sender, setSender] = useState({
        id: 0,
        name: ''
    });
    
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const [img,setImg] = useState('')
  const [openModal,setOpenModal] = useState(false)// Tamaño de la página

  const handleImg=(url)=>{
    setOpenModal(true)
    setImg(url)
}
const handleClose=()=>{
    setOpenModal(false)
    setImg('')
}
    useEffect(() => {
        if (user !== 'tengo estado') {
            setSender({
                id: user.personalData.id,
                name: `${user.personalData.name} ${user.personalData.lastName}`
            });
            listenForMessages(idChat);
        } else {
            getInfo();
        }
    }, [user, idChat]);
    
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const listenForMessages = (chatId) => {
        const messagesCollection = collection(db, 'chats', chatId, 'messages');
        const q = query(messagesCollection, orderBy('timestamp', 'asc'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const chatMessages = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(chatMessages);
        });

        return () => unsubscribe();
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (newMessage.trim() !== '') {
            await sendMessage(idChat, sender.id, newMessage);
            setNewMessage('');
        }
    };

    const sendMessage = async (chatId, senderId, message) => {
        const chatDocRef = doc(db, 'chats', chatId);
        const messagesCollection = collection(chatDocRef, 'messages');
    
        try {
            await runTransaction(db, async (transaction) => {
                // Add the new message
                const newMessageRef = await addDoc(messagesCollection, {
                    sender: senderId,
                    message: message,
                    timestamp: Timestamp.now()
                });
    
                // Update the chat's lastMessage and timestamp fields
                transaction.update(chatDocRef, {
                    lastMessage: message,
                    timestamp: Timestamp.now()
                });
    
                return newMessageRef;
            });
        } catch (error) {
            console.error('Error sending message and updating chat: ', error);
        }
    };
    const handleUploadFile = async (e) => {
        const file = e.target.files[0];
        let timestamp =new Date()
        if (file) {
            const chatIdIndividual =`chats/${idChat}`;  // Reemplaza esto con el chatId real
            const storageRef = ref(storage, `chatFiles/${chatIdIndividual}/${file.name}`);
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);

            const chatDocRef = doc(db, 'chats', idChat);  
            const messagesRef = collection(chatDocRef,'messages');

            await addDoc(messagesRef, {
                sender:sender.id,
                timestamp: timestamp,
                type: file.type.split('/')[0], // "image", "video", "audio", etc.
                content: downloadURL,
                fileName: file.name,
                message:'',
                id_user: sender.id
            });
        }
    };

    
    return (
        <div className="col-12">
            <button className="btn btn-dark" onClick={() => setTypeOfChat(0)}><i className="fa fa-reply"></i>&nbsp;regresar</button>
            <div className="card blur shadow-blur max-height-vh-70">
                <div className="card-header shadow-lg">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="d-flex align-items-center">
                                <img alt="Image" src="https://th.bing.com/th/id/R.c33d127fc827b9c519bd6cfe6d937e30?rik=TyLeJpJeJc9rdA&pid=ImgRaw&r=0" className="avatar" />
                                <div className="ms-3">
                                    <h6 className="mb-0 d-block"> {chatWhith}</h6>
                                    <span className="text-sm text-dark opacity-8">Ultimo mensaje {lastDate}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalImages open={openModal} handleClose={handleClose} imageUrls={[img]}  />
                <div className="card-body overflow-auto overflow-x-hidden" ref={chatContainerRef}>
                    {messages.length > 0 && (
                        messages.map((chat) => {
                            let fecha = chat.timestamp.toDate();
                            return (
                                <Fragment key={chat.id}>
                                    {chat.sender === sender.id ? (
                                        <SelfMessage handleImg={handleImg} message={chat.message} timestamp={fecha.toLocaleString()} chat={chat} />
                                    ) : (
                                        <GenarlMessage handleImg={handleImg} message={chat.message} timestamp={fecha.toLocaleString()} chat={chat} name={chat.sender.name} />
                                    )}
                                </Fragment>
                            );
                        })
                    )}
                    <div ref={messagesEndRef} />
                </div>
                {/* <div className="card-footer d-block">
                    <form className="align-items-center" onSubmit={handleSendMessage}>
                        <div className="d-flex">
                            <div className="input-group">
                                <input type="text" value={newMessage} onChange={(e) => setNewMessage(e.target.value)} className="form-control" placeholder="Escribe aqui ..." aria-label="Message example input" />
                            </div>
                            <button className="btn bg-gradient-info mb-0 ms-2 btn-rounded" type="submit">
                                <i className="ni ni-send"></i>
                            </button>
                        </div>
                    </form>
                </div> */}
                 <div className="card-footer footer-general d-block">
                    <form className="">
                        <div className="d-flex mb-0">
                            <div className="input-group" style={{width: '250px'}}>
                                <textarea type="text" rows={2} cols={1}  value={newMessage} onChange={e => setNewMessage(e.target.value)} className="form-control " placeholder="Escribe aqui ..." aria-label="Message example input" />
                            </div>
                        <div>
                           <button className="btn bg-gradient-info p-2 btn-rounded" disabled={newMessage.length<1?true:false} onClick={e => handleSendMessage(e)}>
                                <i className="font-15 ni ni-send"></i>
                            </button>
                           </div>
                            <div class="file-input-container">
                                <label for="file-upload" class="file-label"><i className="fa-solid fa-paperclip font-15"></i></label>
                                <input type="file" id="file-upload"   class="file-input " onChange={handleUploadFile}/>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const SelfMessage = ({ message, timestamp,chat,handleImg }) => {
    const renderContent = () => {
        if (chat.message != '') {
            return <p className="mb-0">{message}</p>;
        } else if (chat.type === 'image') {
            return (
                <div className="col-12 p-0">
                    <img 
                     onClick={e=>handleImg(chat.content)}
                        src={chat.content} 
                        style={{ width: '200px' }} 
                        alt="Rounded uploadimage" 
                        className="img-fluid mb-2 border-radius-lg" 
                    />
                </div>
            );
        } else {
            return (
                <div className="col-12 p-0">
                        <p>{chat.fileName} <i className="fa fa-file"></i></p>
                    <a href={chat.content} download className="btn btn-link">
                        <i className="fa fa-download"></i> Descargar archivo
                    </a>
                </div>
            );
        }
    };
    return (
        <div className="row justify-content-end text-right mb-4">
            <div className="col-auto">
                <div className="card bg-gray-200">
                    <div className="card-body py-2 px-3">
                      {renderContent()}
                        <div className="d-flex align-items-center justify-content-end text-sm opacity-6">
                            <i className="ni ni-check-bold text-sm me-1"></i>
                            <small>{timestamp}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const GenarlMessage = ({ message, timestamp, name,chat,handleImg }) => {
    const renderContent = () => {
        if (chat.message != '') {
            return <p className="mb-0">{message}</p>;
        } else if (chat.type === 'image') {
            return (
                <div className="col-12 p-0">
                    <img 
                     onClick={e=>handleImg(chat.content)}
                        src={chat.content} 
                        style={{ width: '200px' }} 
                        alt="Rounded uploadimage" 
                        className="img-fluid mb-2 border-radius-lg" 
                    />
                </div>
            );
        } else {
            return (
                <div className="col-12 p-0">
                        <p>{chat.fileName} <i className="fa fa-file"></i></p>
                    <a href={chat.content} download className="btn btn-link">
                        <i className="fa fa-download"></i> Descargar archivo
                    </a>
                </div>
            );
        }
    };
    return (
        <div className="row justify-content-start mb-4">
            <div className="col-auto">
                <div className="card ">
                    <div className="card-body py-2 px-3">
                    {renderContent()}
                        <div className="d-flex align-items-center text-sm opacity-6">
                            <i className="ni ni-check-bold text-sm me-1"></i>
                            <small>{name} {timestamp}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ModalImages=({imageUrls,open,handleClose})=>{
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "-35%",
            width: "90%",
            height: "55%",
            transform: "translate(-50%, -50%)",
          },
    }
    return(
       <Modal style={customStyles} isOpen={open} onRequestClose={handleClose} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
         <div className="col-md-8 mx-auto">
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
          {imageUrls.map((imageUrl,key)=>{
            return(
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={key} className="active" aria-current="true" aria-label="Slide 1"></button>
            )
          })}
          </div>
        
          <div className="carousel-inner">
          {imageUrls.map((imageUrl) =>{
        
            return(
            <div className="carousel-item active">
              <img src={imageUrl} className="d-block w-100" alt="..."/>
            </div>
              
            )
          })}
          </div>
        
      
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
        </div>
      </div>
       </Modal>
    )
}