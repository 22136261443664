import React, { useState, useEffect } from "react";
import Request from "../../utils/http";
import { SimpleModal } from "../../components/ReactModal/SimpleModal";
import { ModalButtons } from "../../components/ReactModal/ModalButtons";
import { CheckBox } from "../../components/CheckBox";
import Select from "react-select";
import { v4 as uuidv4 } from 'uuid';
import { Toast } from "../../components/Toast";
const request = new Request();
export const ModalUpdateDepartMent = ({ dataModal, url, reload ,setDataModal}) => {

  const [data, setData] = useState({
    description: "",
    Active: 0,
    iddepartment: 0,
    description_puesto: "",
    tagsSelected:[],
    areaboss:{label:'todos los colaboradores',value:'0'}
  });
  const [tags,setTags]=useState([])
  const [showToastProp,setShowToast]=useState({
    showToast:false,
    labelHeader:'Éxito',
    message:'Operacion exitosa',
    type:'success'
})
  const [addTagToSelect, setTagToSelect] = useState(false);
  const [colaborators,setColaborators]=useState([])
  const getData = async() =>{
  
      const response = await request.get("information/departments/personal", []);
      if (response && response.statusCode === 200) {
         
          let personas=[]
          response.result.datos.personal.forEach((item)=>{
            personas.push({
              label:`${item.name} ${item.lastName}`,
              value:item.id,
            
            })
          })
          setColaborators(personas)
      }
     
    
  }
  useEffect(() => {
    if (dataModal.length > 0) {
      setData({
        ...data,
        description: dataModal[0].description,
        Active: dataModal[0].Active,
        iddepartment: dataModal[0].iddepartment,
        description_puesto: dataModal[0].description_puesto,
        tagsSelected:dataModal[0].tagsSelected&&JSON.parse(dataModal[0].tagsSelected),
        areaboss:dataModal[0].areaboss&&JSON.parse(dataModal[0].areaboss)
      });
    }
    getTags()
    getData()
  }, [dataModal]);

  const handleChange = (id, value) => {
    setData({ ...data, Active: value == true ? 1 : 0 });
  };

  const handleSubmit = async () => {
  
    const response = await request.post(url, {
      descripcion: data.description,
      activo: data.Active,
      id: data.iddepartment,
      description_puesto: data.description_puesto,
      tagsSelected:JSON.stringify(data.tagsSelected),
      areaboss:JSON.stringify(data.areaboss)
    });
    if (response && response.statusCode==200) {
      setShowToast({...showToastProp,type:'success',showToast:true,message:'Guardado con exito!!'})
    
     
      }else{
      setShowToast({...showToastProp,type:'danger',showToast:true,labelHeader:'Error, intentelo de nuevo',message:'Favor de notificar a sistemas'})
      
    }
    reload();
      setTimeout(() => {
      setShowToast({...showToastProp,showToast:false})
      
      }, 2000);
    if (response && response.statusCode == 200) {
      reload();
      return "success";
    } else {
      reload();
      return "error";
    }
  };

  const addTag = () => {
    setTagToSelect(true);
  };
  const closeTag=()=>{
    getTags()
    setTagToSelect(false)
  }

  const getTags=async()=>{
    const response = await request.get(`tags/getTagUniversal/${3}`)
  
    if (response && response.statusCode==200){
      setTags(response.result.data)
    }
  }
  const handleCloseFunction = ()=>{
    setDataModal([])
    setData({
      description: "",
    Active: 0,
    iddepartment: 0,
    description_puesto: "",
    tagsSelected:[]
    })
  }
  return (
    <SimpleModal identifier={"department"} size="800" handleCloseFunction={handleCloseFunction} heightProp="500">
      {!addTagToSelect ? (
        <>
          <h6 className="text-md mt-3 ml-3 mb-0">Nombre de departamento</h6>
          <div className="form-input-group d-flex p-3  ">
            <input
              type="text"
              name="dep"
              id="dep"
              style={{ width: "900px" }}
              className="form-control mr-2"
              value={data.description}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
            <CheckBox
              typeActive="dark"
              id={0}
              checked={data.Active == 1 ? true : false}
              handleChange={handleChange}
            />
          </div>
          <div className="p-3">
            <h6>Aptitudes del puesto</h6>
            <div className="d-flex">
              <div style={{ width: "600px" }}>
                <Select isMulti options={tags} value={data.tagsSelected} onChange={value=>setData({...data,tagsSelected:value})} />
              </div>
              <button className="btn bg bg-gradient-dark" onClick={addTag}>
                <i className="fa fa-plus font-15"></i>
              </button>
            </div>
          </div>
          <div className="p-3">
            <h6>Jefe del departamento</h6>
            <div className="d-flex">
              <div style={{ width: "600px" }}>
                <Select  options={colaborators} value={data.areaboss} onChange={value=>setData({...data,areaboss:value})} />
              </div>
             
            </div>
          </div>
          <div className="p-3">
            <h6>Descripcion del puesto</h6>
            <textarea
              value={data.description_puesto}
              onChange={(e) =>
                setData({ ...data, description_puesto: e.target.value })
              }
              className="form-control"
              rows={5}
            />
          </div>
<div className="d-flex justify-content-end p-3">
  <button className="btn btn-dark btn-sm mr-3" onClick={handleSubmit}> <i className="fa-solid fa-check font-15"></i> guardar</button>
</div>
          {/* <ModalButtons handleSubmit={handleSubmit}  closeOption={false}  /> */}
          <Toast 
               showToast={showToastProp.showToast}
               labelHeader={showToastProp.labelHeader}
               message={showToastProp.message}
               type={showToastProp.type}     
            />
        </>
      ) : (
        <ModalTags closeTag={closeTag}/>
      )}
    </SimpleModal>
  );
};

const ModalTags = ({closeTag}) => {
  const [tag, setTag] = useState("");
  const handleSubmit = async () => {
    const response = await request.post("tags/createTagUniversal", {
      value: uuidv4(),
      label: tag,
      type: 3,
    });
    if (response && response.statusCode == 200) {
      setTag('')
      return "success";
    } else {
      return "error";
    }
  };
  return (
    <div className="p-3 input-group-sm">
      <button className="btn btn-danger btn-sm" onClick={closeTag}><i className="fa-solid fa-reply font-15"></i> Regresar</button>
      <input
        type="text"
        className="form-control mb-5"
        value={tag}
        onChange={(e) => setTag(e.target.value)}
      />

      <ModalButtons handleSubmit={handleSubmit} closeOption={false}  />
    </div>
  );
};
