import Modal from 'react-modal'
import { calcularDiferenciaHoras, sumarHoras, sumarTiempoHora } from "../../../../../Hooks/timeTransform";
import moment from "moment-timezone";
import { buscarPorId } from "../../../../../Hooks/buscarEnObjeto";
import { formatDate } from  "../../../../../Hooks/formatDates";
import { useEffect, useState } from 'react';
import { TableInfinityFixed } from '../../../../../components/TableInfinityFixed';
export const ModalInformation=({lineas,type,openModal,setOpenModal,esFinDeSemana,colaborador,setType}) => {
  
  
    const [data, setData]=useState([])
    const [horas,setHoras]=useState([])
    const header=[
        {label:'Tolerancia'},
        {label:'Fecha'},
        {label:'Tipo'},
        {label:'Diferencia retardo'},
        {label:'Entrada'},
        {label:'Salida a comer'},
        {label:'Tiempo trabajado antes de la comida'},
        {label:'Regreso de comida'},
        {label:'Tiempo de comida'},
        {label:'Salida de oficina'},
        {label:'Tiempo trabajado hasta la hora de la salida'},
        {label:'horas Totales'},
    ]
  
    useEffect(()=>{
        if (lineas && type==1) {
          setData(lineas)
          let horastotales = []
          lineas.forEach(element => {
            const {fecha,registros} = element
            let entrada  = buscarPorId(1,registros,'type')
            let scomida  = returnDates(2,registros,entrada?.time)
            let ecomida  = returnDates(3,registros,entrada?.time)
            let soficina = returnDates(4,registros,entrada?.time)
            
           
            let diferencias = calcularDiferencias(entrada?.time,scomida?.time,ecomida?.time,soficina?.time)
            
            
            horastotales.push( calularData(diferencias) )
        });
        setHoras(horastotales)
        }
    },[type])

    const isRetardo=(hora) => {
    
        let horaLimite = moment('08:55:00 AM', 'hh:mm:ss A');
            let horaLimiteString = horaLimite.format('HH:mm:ss');
            
            let entradaStatus = ''
            
            let entrada=moment(hora).format('HH:mm:ss')
            
            if (entrada>horaLimiteString) {
                entradaStatus ="Retardo"
                
                } else if (entrada==horaLimiteString) {
                entradaStatus ="Puntual"
                    
                } else {
                entradaStatus ="Temprano"
            }

            
            return entradaStatus;
            
    }

  const calcularDiferencias=(entrada,scomida,ecomida,soficina) => {
      
      let entradaDate=convertTime(entrada)
      let salidacomidaDate=scomida
        let entradaComidaDate=ecomida
        let salidaOficinaDate=soficina
    
        let diffComidaSalida='00:00:00'
        let timeLounch='00:00:00'
        let diffSalida='00:00:00'
        if (entrada) {
            
            if (scomida) {
                diffComidaSalida=calcularDiferenciaHoras(moment(entradaDate).format('HH:mm:ss'),moment(salidacomidaDate).format('HH:mm:ss'))
               
            }
            if (ecomida) {
                timeLounch=calcularDiferenciaHoras(moment(salidacomidaDate).format('HH:mm:ss'),moment(entradaComidaDate).format('HH:mm:ss'))
            }
            if (soficina) {
                    let comida= validateSalida(moment(entradaComidaDate).format('HH:mm:ss'),moment(salidaOficinaDate).format('HH:mm:ss'))
                    if (comida>0) {
                        diffSalida=calcularDiferenciaHoras(moment(entradaComidaDate).format('HH:mm:ss'),moment(salidaOficinaDate).format('HH:mm:ss'))
                    }else{
                        diffSalida=calcularDiferenciaHoras(moment(entradaDate).format('HH:mm:ss'),moment(salidaOficinaDate).format('HH:mm:ss'))
                        return ['00:00:00','00:00:00',diffSalida]
                    }
            }
        }

        return [diffComidaSalida,timeLounch,diffSalida]

    }

    const convertTime=(fechaparam) => {
      
        let base = moment(fechaparam).format('YYYY-MM-DD')
        let fecha1Str = fechaparam;
        let fecha2Str = `${base} 08:45:59`

      

        // Verificar si fecha1 es menor que fecha2 y cambiarla si es necesario
        if (fecha1Str < fecha2Str) {
            fecha1Str = fecha2Str;
        }
        let fecha1FinalStr = fecha1Str
        return fecha1FinalStr
    }
    const renderAsignTime=(time) => {
      return  time ? <strong> { moment(time.time).format('HH:mm:ss')} {time.incompleto ==1 && <small className='text-danger'>asignado automatico</small>}</strong> : '00:00:00'
    }
        
    const calularData=(diferencias)=> {
        if (diferencias.length==3) {
            return sumarHoras([diferencias[0], diferencias[2]])
        }else{
            return sumarHoras(diferencias)
        }
    }
    const validateData = (entrada, scomida, ecomida, soficina) => {
        if (entrada === undefined || scomida === undefined || ecomida === undefined || soficina === undefined) {
            return "incompleto";
        }
        // Puedes agregar lógica adicional aquí si es necesario
        return "completo";
    };

    const returnDates=(type,registros,base)=>{
        let baseTime=moment(base).format('YYYY-MM-DD')
      
        let registro = buscarPorId(type,registros,'type')
        
        if (registro) {
            registro.incompleto = 0
            return registro
        }else{
            let time=''
            let entrada = buscarPorId(1,registros,'type')
            switch (type) {
                case 2:
                if (entrada) {
                    time = sumarTiempoHora(entrada.time,1)
                    
                }else{

                    time= `${baseTime} 14:00:00`
                }
                
                break;
                case 3:
                   
                    if (entrada) {
                        time = sumarTiempoHora(entrada.time,2)
                        
                    }else{
    
                        time= `${baseTime} 15:00:00`
                    }
              
                break;
            
                default:
                    if (entrada) {
                        time = sumarTiempoHora(entrada.time,3)
                        
                    }else{
    
                        time= `${baseTime} 18:00:00`
                    }
                    break;
            }
           
            return {time:time,incompleto:1}
        }
    }

    const validateSalida=(f1,f2)=>{
        if (f1>f2) {
            return 0
        }else{
            return 1
        }
    }
    const handleClose=()=>{
        setOpenModal(false)
        setData([])
        setHoras([])
        setType(0)
    }

    return(
        <Modal isOpen={openModal} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} onRequestClose={e=>handleClose()}>
            <div className="text-center">
                <h4>{colaborador.nombre}</h4>
                <h4>{colaborador.rango}</h4>
                <h4>{horas.length>0?calularData(horas):''}</h4>
            </div>
            <TableInfinityFixed columnsParam={header} height={'60vh'} aditionalClass="table table-striped" >
                    {data.map((line,i)=>{
                        const {fecha,registros} = line

                        let entrada=null
                        let scomida=null
                        let ecomida=null
                        let soficina=null
                        let isretardo=null
                        let diffRetardo=null
                        let diferencias=null
                        let comida=null
                        let isFinDeSemana=null
                       if (registros.length>0) {
                            entrada  = buscarPorId(1,registros,'type')
                            scomida  = returnDates(2,registros,entrada?.time)
                            ecomida  = returnDates(3,registros,entrada?.time)
                            soficina = returnDates(4,registros,entrada?.time)
                            isretardo = entrada ? isRetardo(entrada.time):''
                            diffRetardo = isretardo=='Retardo' ? calcularDiferenciaHoras('08:55:59',moment(entrada?.time).format('HH:mm:ss')):''
                            diferencias = calcularDiferencias(entrada?.time,scomida?.time,ecomida?.time,soficina?.time)
                            comida= validateSalida(moment(scomida?.time).format('HH:mm:ss'),moment(soficina?.time).format('HH:mm:ss'))
                            isFinDeSemana =esFinDeSemana(fecha)
                       }
                    return(
                           registros.length>0&& <tr key={i}>
                                <td className='text-danger'>
                                  {'08:55:00'}
                                </td>
                                <td>
                                {formatDate(fecha)}
                                </td>
                                <td className='text-danger'>
                                {isretardo}
                                </td>
                                <td className='text-danger'>
                                {diffRetardo}
                                </td>
                                <td>
                                {isFinDeSemana?'': moment(entrada?.time).format('HH:mm:ss')}
                                </td>
                                <td>
                                {isFinDeSemana?'':comida>0? renderAsignTime(scomida):'00:00:00'}
                                </td>
                                <td>
                                {isFinDeSemana?'': diferencias[0]}
                                </td>
                                <td>
                                {isFinDeSemana?<h5>Es fin de semana</h5>:comida>0 ? renderAsignTime(ecomida):'00:00:00'}
                                </td>
                                <td>
                                {isFinDeSemana?'': diferencias[1]}
                                </td>
                                <td>
                                {isFinDeSemana?'': renderAsignTime(soficina) }
                                </td>
                                <td>
                                {isFinDeSemana?'': diferencias[2]}
                                </td>
                                <td>
                                {isFinDeSemana?'': calularData(diferencias)  }
                                </td>
                            </tr>
                        )
                    })}
            </TableInfinityFixed>
        </Modal>
    )
}