import React, { useEffect, useState } from 'react'
import { TableInfinityFixed } from '../../../../components/TableInfinityFixed'
import moment from 'moment-timezone'
import { UploadDocument } from '../../../../components/UploadDocuments'
import {ToastAlert} from '../../../../components/ToastAlert'
import Request from '../../../../utils/http'
const request = new Request()

export const DayTable = ({days=[],numdays,dateDefault}) => {

    const registros= [
        { label:'Entrada'},
        { label:'Estatus de llegada'},
        { label:'Estatus general',  },
        { label:'Colaborador',  },
        { label:'Estatus',},
        { label:'Registrar salida'},
        { label:'Justificante'},
  
    ]
    const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
    useEffect(()=>{
      const date = moment(dateDefault).format('YYYY-MM')
     
      dataTransform(days,date)
    },[days])

    const dataTransform =(info,date) => {
     
     
      let linea = []
      let inasistencias=[]
      let retardos=[]
      info.forEach((element) => {
        element.checs.forEach((line,k) => {
          if (line.length > 0) {
                line.forEach((chk,i) => {
                  let ent = i==0?isRetardo(chk.time):''
                  if (i==0) {
                       linea.push({  fecha:moment(chk.time).format('YYYY-MM-DD'), estatus:ent,colaborador:element.name+' '+element.lastName, entrada:chk.time, salida:line[line.length-1].time })
                    if (ent=='Retardo') {
                      retardos.push({  fecha:moment(chk.time).format('YYYY-MM-DD'), estatus:ent,colaborador:element.name+' '+element.lastName, entrada:chk.time, salida:line[line.length-1].time })
                    }
                  }
                    linea.push({ fecha:``,estatus:ent,colaborador:chk.time,entrada:chk.type==1?'Entrada':'Salida',salida:'' })

                });
            
              }else{
                let dia =k+1<10?`0${k+1}`:k+1
                linea.push({ 
                  fecha:`${date}-${dia}`,
                  estatus:'No asistio',
                  colaborador:element.name+' '+element.lastName,
                  entrada:'',
                  salida:''

                })
                inasistencias.push({ 
                  fecha:`${date}-${dia}`,
                  estatus:'No asistio',
                  colaborador:element.name+' '+element.lastName,
                  entrada:'',
                  salida:''

                })
              }
          });
          
      });
     
    }

    const isRetardo=(hora)=>{
      let horaLimite = moment('08:55:00 AM', 'hh:mm:ss A');
        let horaLimiteString = horaLimite.format('HH:mm:ss');
        let entradaStatus = ''
      
         let entrada=moment(hora).format('HH:mm:ss A')
         
          if (entrada>horaLimiteString) {
              entradaStatus ="Retardo"
            } else if (entrada==horaLimiteString) {
              entradaStatus ="Puntual"
                
            } else {
              entradaStatus ="Temprano"
          }
          return entradaStatus;
        
    }
  return (
   <>
 <ToastAlert {...{toastAlertProps}} /> 
      <TableInfinityFixed columnsParam={registros} aditionalClass={'table table-striped shadow clickeable'} height={'70vh'}>
    {
      days.map(((linea,i)=>{
   
        const {checs,name,lastName,id,justifys} = linea

        const asistenciaClass= checs[numdays+1].length>0?'text-success':'text-danger'
        let entrada=''
        let ulSal = ''
        let horaLimite = moment('08:55:59 AM', 'hh:mm:ss A');
        let horaLimiteString = horaLimite.format('HH:mm:ss');
        let entradaStatus = checs[numdays+1].length==0?'':''
        if (checs[numdays+1].length>0) {
          entrada=moment(checs[numdays+1][0]['time']).format('HH:mm:ss A')
         
          if (entrada>horaLimiteString) {
              entradaStatus ="Retardo"
            } else if (entrada==horaLimiteString) {
              entradaStatus ="Puntual"
                
            } else {
              entradaStatus ="Temprano"
          }
        }
        if(checs[numdays+1].length>0) ulSal =moment( checs[numdays+1][checs[numdays+1].length-1].time).format('HH:mm:ss')
        let asistenciaGeneral=checs[numdays+1].length>0?'Asistencia':'Inasistencia'
       let  statusInoffice = checs[numdays+1] % 2 === 0?'Fuera de oficina':'En oficina'

        return(
          <tr key={i}  >
            <td data-bs-toggle="modal" data-bs-target={`#ModalAsistencias`} className={'text-success font-15'}>
              {entrada}
            </td>
            <td data-bs-toggle="modal" data-bs-target={`#ModalAsistencias`} className={`text-${entradaStatus=='Retardo'?'danger':'success'} font-15`}>
              {entradaStatus}
            </td>
            <td data-bs-toggle="modal" data-bs-target={`#ModalAsistencias`} className={asistenciaClass}>{`${asistenciaGeneral}`}</td>
            <td data-bs-toggle="modal" data-bs-target={`#ModalAsistencias`} className='font-15'>{`${name} ${lastName}`}</td>
            <td data-bs-toggle="modal" data-bs-target={`#ModalAsistencias`}>{statusInoffice=='En oficina'?<h6 className='text-success font-12'> En oficina </h6>:<h6 className='text-danger font-12'> Fuera de oficina</h6>}</td>
           
            <td data-bs-toggle="modal" data-bs-target={`#ModalAsistencias`} className='text-danger font-15'>{ulSal}</td>
            <td className='text-danger font-15'>
              {entrada == '' && <AsignInasistences setToastAlert={setToastAlert} id={id} justifys={justifys} dateDefault={dateDefault}/>}
            </td> 
          </tr>
        )
      }))
    }
      </TableInfinityFixed>
   
   </>
  )
}

const AsignInasistences=({id,justifys,dateDefault,setToastAlert}) => {
 
  const [type,setType] =useState(0)
  const [url,setUrl]=useState('')
  const [disabled,setDisabled]=useState(false)
  useEffect(()=>{
    if(justifys.length>0){
      setUrl(justifys[0]['file_url'])
      setType(justifys[0]['type'])
      setDisabled(true)
    }
  },[justifys])
  const handleJustificate=async()=>{
    const response = await request.post('handleJustificate/justificate',{type:type,id:id,date:moment().format('YYYY-MM-DD')})
    if (response && response.statusCode==200) {
      setToastAlert({text:'Guardado con exito',type:'success'},)
    }

  }
  const handledes=(bandera)=>{
    if(bandera==1){
      setType(0)
    setDisabled(false)
    setUrl('')
  }else{
    setDisabled(true)
      setUrl(bandera)

    }
  }
  return(
    <>
      <div className='d-flex mb-1 input-group-sm'>
        <select  disabled={disabled}className="form-control" value={type} onChange={e=>setType(e.target.value)}>
          <option value="0">Selecciona una opcion</option>
          <option value="1">Justificada</option>
          <option value="2">Permiso con gose de sueldo</option>
          <option value="3">Permiso sin gose de sueldo</option>
          <option value="4">Incapacidad</option>
        </select>
        {type>0  && <UploadDocument urlPost='justifyCheck' date={dateDefault} typeDoc={type} url={url} user={{id:id}} reload={handledes} typeprop={{typeDoc:url}} urlDesactivate={'justifyCheckDelete'} metod='post'/>}

        {type>0&& type<5 && <button disabled={disabled} className='btn btn-danger ml-3' onClick={handleJustificate}>Guardar sin justificancte</button>}
      </div>
  
    
    </>
  )
}