 import enero from '../static/enero.jpg'
 import febrero from '../static/febrero.jpg'
 import marzo from '../static/marzo.jpg'
 import abril from '../static/abril.jpg'
 import mayo from '../static/mayo.jpg'
 import junio from '../static/junio.jpg'
 import julio from '../static/julio.jpg'
 import agosto from '../static/agosto.png'
 import septiembre from '../static/septiembre.jpg'
 import octubre from '../static/octubre.png'
 import noviembre from '../static/noviembre.jpg'
 import diciembre from '../static/diciembre.jpg'
 
 const januaryTheme={
  backgroundImage:enero,
  gradientColor:'bg d  gradient-primary'
}
 const februaryTheme={
  backgroundImage:febrero,
  gradientColor:'bg d gradient-warning'
}
 const marchTheme={
  backgroundImage:marzo,
  gradientColor:'bg d gradient-primary'
}
 const aprilTheme={
  backgroundImage:abril,
  gradientColor:'bg d gradient-success'
}
 const mayTheme={
  backgroundImage:mayo,
  gradientColor:'bg d gradient-danger'
}
 const juneTheme={
  backgroundImage:junio,
  gradientColor:'bg d gradient-success'
}
 const julyTheme={
  backgroundImage:julio,
  gradientColor:'bg d gradient-info'
}
 const augustTheme={
  backgroundImage:agosto,
  gradientColor:'bg d gradient-primary'
}
 const septemberTheme={
  backgroundImage:septiembre,
  gradientColor:'bg d gradient-warning'
}
 const octoberTheme={
  backgroundImage:octubre,
  gradientColor:'bg d gradient-warning'
}
 const novemberTheme={
  backgroundImage:noviembre,
  gradientColor:'bg d gradient-primary'
}
 const decemberTheme={
  backgroundImage:diciembre,
  gradientColor:'bg '
}

export function choseTheme (numberMonth){
let selected ={}
switch (numberMonth) {
  case 1:
  selected=januaryTheme
    break;
  case 2:
  selected=februaryTheme
    break;
  case 3:
  selected=marchTheme
    break;
  case 4:
  selected=aprilTheme
    break;
  case 5:
  selected=mayTheme
    break;
  case 6:
  selected=juneTheme
    break;
  case 7:
  selected=julyTheme
    break;
  case 8:
  selected=augustTheme
    break;
  case 9:
  selected=septemberTheme
    break;
  case 10:
  selected=octoberTheme
    break;
  case 11:
  selected=novemberTheme
    break;

  default:
  selected=decemberTheme
    break;
}

return selected
}