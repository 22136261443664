import React from 'react'

export const LoadingData = ({loading=false,quantity=0,colspan=1}) => {
  const label=loading?'Cargando informacion...':quantity==0&&'Sin registros'
  return (
   <>
    <tr>
        <td className='text-center  ' colSpan={colspan}>{loading&&<i className=' font15 fa fa-spin fa-spinner'/>}&nbsp;{label}</td>  
    </tr>
    <tr><td className='text-' colSpan={colspan}></td></tr>
    <tr><td className='text-' colSpan={colspan}></td></tr>
    <tr><td className='text-' colSpan={colspan}></td></tr>
    <tr><td className='text-' colSpan={colspan}></td></tr>
    <tr><td className='text-' colSpan={colspan}></td></tr>
   
   
   </> 
  )
}
