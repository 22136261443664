import { Carousel } from 'react-responsive-carousel';
import { Encuesta } from './Encuesta';
import { useState } from 'react';
import Modal from 'react-modal';
export const PublicationCaption=({post,userDataInfo})=>{
    
    const textPublication = post.caption
    const isEncuesta = post.type?true:false
    const [open,setOpen]=useState(false)
    const [postImg,setImage]=useState('')

    const handleOpen=(img) => {
      console.log("🚀 ~ handleOpen ~ img:", img)
   
      setOpen(true)
      setImage(img)
    }
    return(
      <>
          <h6 className="text-xs">
            {textPublication}
          </h6>
    
          <div className="row">
            <div className="col-md-12 mx-auto" onClick={e=>handleOpen(post.imageUrls[0])}>
             
             {!isEncuesta ? 
             
              <Carousel showThumbs={false}>
                  {post.imageUrls.map((imageUrl,j) =>{
                  
                  return(
                  <div className="carousel-item active">
                    <img src={imageUrl} key={j} className="d-block w-100 img-post" alt="..."/>
                  </div>
                    
                  )
                })}
              </Carousel>:
             <Encuesta opciones={post.data} userDataInfo={userDataInfo} id={post.id} answered={post.answered}/>
              }
            </div>
          </div>
          <ModalImages {...{open,postImg,setOpen}} />
      </>
    )
  }

  const ModalImages = ({ open, postImg, setOpen }) => {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        width: "auto",
        height: "auto",
        maxWidth: "90vw", // Limita el ancho al 90% de la vista
        maxHeight: "90vh", // Limita la altura al 90% de la vista
        padding: 0, // Elimina los paddings por defecto si hay
      },
    };
  
    return (
      <Modal
        isOpen={open}
        shouldCloseOnEsc={true}
        onRequestClose={() => setOpen(false)}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
      >
        <img
          src={postImg}
          alt=""
          className="img-fluid"
          style={{
            width: "100%", // Se ajusta al ancho del modal
            height: "auto", // Mantiene la proporción original
            maxHeight: "90vh", // Limita la altura máxima para no desbordar
          }}
        />
      </Modal>
    );
  };