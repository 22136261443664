export function buscarPorId(idABuscar,arrayDeObjetos,propfind='id') {
  
    return arrayDeObjetos.find(objeto =>  objeto[propfind] == idABuscar);
  }

  export function buscarPorMultiplesPropiedades(array, busqueda,propiedades) {
    // Convertimos la búsqueda a minúsculas para hacerla insensible a mayúsculas/minúsculas
    const busquedaLower = busqueda.toLowerCase();

    return array.filter(item => {
      // Verificamos si alguna de las propiedades especificadas coincide con la búsqueda
      return propiedades.some(prop => 
        item[prop] && item[prop].toString().toLowerCase().includes(busquedaLower)
      );
    });
  }
//   ejemplo:
//   const arrayDeUsuarios = [
//     { userId: 1, nombre: 'Juan', edad: 25 },
//     { userId: 2, nombre: 'María', edad: 30 },
//     { userId: 3, nombre: 'Pedro', edad: 22 },
//   ];
//   const usuarioEncontrado = buscarPorId(2, arrayDeUsuarios, 'userId');
// console.log(usuarioEncontrado);
// { userId: 2, nombre: 'María', edad: 30 }


// const personas = [
//   { nombre: "Juan Pérez", email: "juan@example.com", fecha: "2023-09-12" },
//   { nombre: "Ana Gómez", email: "ana@example.com", fecha: "2024-01-01" },
//   { nombre: "Carlos López", email: "carlos.lopez@example.com", fecha: "2022-05-30" }
// ];






// // Buscando por nombre y email
// let resultado = buscarPorMultiplesPropiedades(personas, "ana", ["nombre", "email"]);
// console.log(resultado);
// // Resultado: [{ nombre: "Ana Gómez", email: "ana@example.com", fecha: "2024-01-01" }]

// // Buscando por fecha
// resultado = buscarPorMultiplesPropiedades(personas, "2024-01-01", ["fecha"]);
// console.log(resultado);
// // Resultado: [{ nombre: "Ana Gómez", email: "ana@example.com", fecha: "2024-01-01" }]
