import { Fragment, useEffect, useState } from "react";
import { db } from '../../../../../../../firebaseConfig';
import { collection, query, getDoc,doc,updateDoc,orderBy, limit, startAfter, getDocs ,onSnapshot, serverTimestamp } from 'firebase/firestore';
import moment from "moment-timezone";
import user from '../../../../../../../static/img/user.png'
import Modal from "react-modal";
export const CommentsSection=({post,useDataInfo}) => {
  
    const [newComments,setComments]=useState('')
    function isNumberInArray(number, array=[]) {
        return array.includes(number);
    }
    function removeNumberFromArray(number, array) {
        const index = array.indexOf(number);
        if (index !== -1) {
            array.splice(index, 1);
            return true; // Indica que el número fue encontrado y eliminado
        }
        return false; // Indica que el número no fue encontrado
    }
   
    async function handleUpdate( valor) {
      const postRef = doc(db, 'posts', post.id);
    
      try {
        // Obtener el post
        const postDoc = await getDoc(postRef);
    
        if (postDoc.exists()) {
          // Obtener los datos actuales del post
          let postData = postDoc.data();
   
          // Crear el nuevo comentario sin la marca de tiempo
          const newComment = {
            userId: `${useDataInfo.id_personal} `,
            userName: `${useDataInfo.personalData.name} ${useDataInfo.personalData.lastName} `,
            comment: newComments,
            likes:[],
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),  // Inicialmente sin la marca de tiempo
          };
          postData.likes.comments.push(newComment)
       
    
          // Guardar los cambios en Firestore
          await updateDoc(postRef, {
            'likes.comments': postData.likes.comments
          });
    
          console.log('Comentario agregado exitosamente.');
    
        } else {
          console.log('El post no existe.');
        }
      } catch (error) {
        console.error('Error al agregar el comentario: ', error);
      }
    }
    // Seleccionar los últimos 2 elementos del array
const lastTwoElements = post.likes.comments.slice(-2);
const sortedLastTwoElements = lastTwoElements.sort((a, b) => b - a);

const handleLikeComments=async(position)=>{




    const postRef = doc(db, 'posts', post.id);
    const postDoc = await getDoc(postRef);
    if (postDoc.exists()) {
        // Obtener los datos actuales del post
        let postData = postDoc.data();
        // Crear el nuevo comentario sin la marca de tiempo
         let isinarr=isNumberInArray(useDataInfo.id_personal,postData.likes.comments[position].likes)
    if (isinarr) {
        removeNumberFromArray(useDataInfo.id_personal,postData.likes.comments[position].likes)
    }else{
        postData.likes.comments[position].likes.push(useDataInfo.id_personal)
    }





     
  
        // Guardar los cambios en Firestore
        await updateDoc(postRef, {
          'likes.comments': postData.likes.comments
        });
  
    } 
    
    
}
  
    return(
      <div >
            
    
        <div className="mb-1 p-1" style={{height:'160px',overflowY:'scroll'}}>
  
        {sortedLastTwoElements.map((commentario,k)=>{
          const {userName,comment,likes} = commentario
          const liked = isNumberInArray(useDataInfo.id_personal,likes)
          let realPos =post.likes.comments.length - (k)
         console.log(realPos);

          return(
            <Fragment key={k}>
            <div className="d-flex  rounded" >
               <div className="flex-shrink-0">
                  <img
                    style={{width:'40px',height:'40px'}}
                    alt="Image placeholder"
                    className="avatar rounded-circle"
                    src={user}
                  />
                </div>
                <div className="flex-grow-1 ms-3">
                  <div className='d-flex'>
                  <h6 className="font-10 text-dark mt-0"><strong>{userName}</strong></h6> &nbsp;
                      <i className={`ni ni-like-2 me-1 ${liked?'text-info':''} cursor-pointer`} onClick={e=>handleLikeComments(realPos,liked)}></i>
                      
                    <span className="text-sm me-2">{likes.length} </span>
                    </div>
                  <p className="text-sm">
                  {comment}
                  </p>
                 
                </div>
              </div>
            
            
            </Fragment>
          )
        })}
       
        </div>
      <ModalComentarios {...{post,handleUpdate,newComments,setComments,handleLikeComments,useDataInfo,isNumberInArray}} />
    
      <div className="d-flex mt-4">
       
        <div className="d-flex flex-grow-1 my-auto">
         
            <textarea
              className="form-control"
              placeholder="Write your comment"
              rows="1"
              value={newComments}
              onChange={e=>setComments(e.target.value)}
            ></textarea>
         <button className='btn btn-sm btn-dark mt-2 p-2' onClick={handleUpdate}><i className="fa fa-paper-plane font-15"></i></button>
        </div>
      </div>
    </div>
    )
  }
  
const ModalComentarios =({post,handleUpdate,newComments,setComments,handleLikeComments,useDataInfo,isNumberInArray})=>{
const [isOpen,setIsOpen]=useState(false)
function timeSince(dateString) {
    const now = new Date();
    const pastDate = new Date(dateString);
    
    const diffInMilliseconds = now - pastDate;
    const diffInMinutes = Math.floor(diffInMilliseconds / 1000 / 60);
    
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    let result = 'publicado hace ';
    
    if (hours > 0) {
        result += `${hours} hora${hours > 1 ? 's' : ''} `;
    }
    
    if (minutes > 0) {
        result += `${minutes} minuto${minutes > 1 ? 's' : ''}`;
    }
    
    return result.trim();
}
    return(
        <>
        <div className="cursor-pointer" onClick={e=>setIsOpen(true)} >
              <p className="text-dark blur">ver todos los comentarios..  </p>
            </div>
        
        <Modal isOpen={isOpen} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} onRequestClose={e=>setIsOpen(false)} >
            <button className="btn btn-danger btn-sm p-2 font-10" onClick={e=>setIsOpen(false)}><i className="font-10 fa fa-reply"></i> regresar</button>
            <h4>Todos los comentarios</h4>
        <div className="mb-1 p-1" style={{height:'60vh',overflowY:'scroll'}}>
            {post.likes.comments.map((commentario,k)=>{
                const {userName,comment,timestamp,likes} = commentario
                const liked = isNumberInArray(useDataInfo.id_personal,likes)
         
                return(
                <Fragment key={k}>
                <div className="d-flex  rounded" >
                    <div className="flex-shrink-0">
                        <img
                        style={{width:'20px',height:'20px'}}
                        alt="Image placeholder"
                        className="avatar rounded-circle"
                        src={user}
                        />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <div className='d-flex justify-content-between' >
                        <h6 className="font-10 text-dark mt-0">{userName}  </h6> &nbsp;
                            <div onClick={e=>handleLikeComments(k)}>
                            <i className={`ni ni-like-2 me-1 ${liked?'text-info':''} cursor-pointer`} onClick={e=>handleLikeComments(k,liked)}></i>
                            <span className="text-sm me-2">{likes.length    } </span>
                            </div>
                        
                        </div>
                        <p className="text-sm">{comment} </p>
                       
                        <div className="d-flex justify-content-end">
                       <p className="text-danger  text-xs"> {timeSince(timestamp)}</p>
                      
                           
                        </div>
                    </div>
                    </div>
                
                    
                   
                </Fragment>
                )
            })}
 
  </div>
  <div className="d-flex mt-4">
                
                <div className="d-flex flex-grow-1 my-auto">
                    
                    <textarea
                        className="form-control"
                        placeholder="escribe tu comentario"
                        rows={1}
                        value={newComments}
                        onChange={e=>setComments(e.target.value)}
                    ></textarea>
                    <button className='btn btn-sm btn-dark mt-2 p-2' onClick={handleUpdate}><i className="fa fa-paper-plane font-15"></i></button>
                </div>
                </div>
        </Modal>
        </>
    )
}