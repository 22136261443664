import React,{useState,useEffect, Fragment} from 'react'
import { VerticalTabs } from '../../../../components/VerticalTabs';
import Select from 'react-select';
import { SimpleModal } from '../../../../components/ReactModal/SimpleModal';
import { TableInfinityFixed } from '../../../../components/TableInfinityFixed';
import {ModalButtons}  from '../../../../components/ReactModal/ModalButtons'
import Request from '../../../../utils/http'
import { ModalUpdateGroup } from '../../../GroupsAndDepartments/ModalUpdateGroup';
const request = new Request();
export const TabsPuestos = ({puestos,colaborators,closeDetails,reload}) => {
    
    const dataParam = puestos
   
    const [puesto,setPuesto]=useState({description:'',active:1,idgroup:0,idDepartment:0,description_puesto:'',tagsSelected:`[]`,sueldo_base:0,moneda:'MXP'})
    
    useEffect(() => {
        if (puestos) {
            setPuesto({...puesto,idDepartment:puestos.iddepartment})
         let tabs=[]
            dataParam.groups.forEach((element,i) => {
                let itemsPersonal = []
                element.personal.forEach((persona,o)=>{
                    itemsPersonal.push({
                        Posicion: 0,Sueldo: persona.sueldo,edit: false,
                        label: `${persona.name} ${persona.lastName} `,value: persona.id_user
                    })
                })
                tabs.push({ 
                    idgroup:element.idgroup,
                    icono:"fa-solid fa-user-shield",
                    id:i,
                    titulo:element.description,
                    personal:itemsPersonal,
                    renderComponent:()=><DetailsPuestos data={itemsPersonal} colaborators={colaborators} id={element.idgroup} />
                })
             });
         setTabs(tabs)
        }
     }, [puestos]);
    const [tabs,setTabs]=useState([])
  return (
    <div>
        <div className="d-flex justify-content-between">
            <button className="btn btn-danger btn-sm" onClick={closeDetails}><i className="fa fa-reply font-15"></i> Volver</button>
            
            <button className="btn btn-dark btn-sm" data-bs-toggle="modal" data-bs-target={`#groups`}><i className="fa-solid fa-building font-15"></i> &nbsp;Agregar puesto</button>
     
        </div>
        <ModalUpdateGroup  dataModal={[puesto]} url={'information/groups/create'} reload={reload} />
         <VerticalTabs renderTabs={tabs} initialTab={0} />
    </div>
  )
}
const DetailsPuestos=({data,colaborators,id}) => {
    const [loadingAdd,setLoadingAdd] = useState(false)
    const [personal,setpersonal] =useState([])
    const [loading,setLoading] =useState(false)
    const [userSelected,setUserSelected] =useState({label:'seleccione para agregar al puesto',value:'0'})
    const [itemToDelete,setItemToDelete] = useState(null)
    const [colaboratorsParam,setColaboratorsParam] = useState([])
    const columns=[
        {classColumn:'',label:'Colaborador'},
        {classColumn:'ocultarmobile',label:'Sueldo'},
        {classColumn:'ocultarmobile',label:'Comentarios'},
        {classColumn:'ocultarmobile',label:'Acciones'}
    ]
    useEffect(() => { 
        handleCol(data)
    }, [data]);
    const handleCol=(data) => {
        let col=[]
        if (data.length > 0) {
        colaborators.forEach(element => {
            data.forEach(elementd => {
                if (element.value != elementd.value) {
                col.push(element)
                    
                        }
                    });
            });
        }else{
            col=colaborators
        }
    setColaboratorsParam(col)
        setpersonal(data)
    }
    const handleActive=(position,bandera,quantity) => {
        const copy = JSON.parse(JSON.stringify(personal))
        if (bandera=='comments') {
            copy[position]['comments']=quantity
        }else{

            bandera=='edit'?copy[position]['edit']=true:copy[position]['Sueldo']=quantity
        }
        setpersonal(copy)  
    }
    const handleUpdate=async(position) => {
        setLoading(true)
        const copy = JSON.parse(JSON.stringify(personal))
        let data={
            idUser:copy[position].value,
            sueldo:copy[position].Sueldo,
            comments:copy[position].comments,
            idgroup:id
       }  
       let response =await request.post('puestos/adduser',data)
       if (response && response.statusCode==200) {
        
           copy[position]['edit']=false
           setpersonal(copy)  
        }
        setLoading(false)
      
    }
    const handleUser=(user)=> setUserSelected(user)

    const addUser= async() => {
        setLoadingAdd(true)
        let copy = JSON.parse(JSON.stringify(personal))
        let response = await request.post('puestos/adduser',{idUser:userSelected.value ,idgroup:id,sueldo:0,comments:''})
        if (response && response.statusCode==200) {
            let col=[]
            colaboratorsParam.forEach(element => {
               
                        if (element.value != userSelected.value ) {
                        col.push(element)
                            
                        }
                 
            });
            setColaboratorsParam(col)
        }
        copy.push({ Posicion: 0,Sueldo: 0,edit: false,label: userSelected.label,value: userSelected.value,comments:'' })
        setpersonal(copy);
        setLoadingAdd(false)
    }
   const handleDelete = (position)=>{
    setItemToDelete(personal[position]['value'])
   }
   const reloadColab=(id)=>{
    let personalP=[]

    personal.forEach((p,i)=>{
        p.show=false
        p.value!=id&&personalP.push(p)
    })
    setpersonal(personalP)
   }
   const showMore=(position)=>{
    const copy=JSON.parse(JSON.stringify(personal))

    copy[position]['show']=!personal[position]['show']
    setpersonal(copy)
    
  }
    return(
     <>
     <div className="p-3 puestoscontainer shadow">
       <div className="d-flex justify-content-end">
            
                <Select options={colaboratorsParam} value={userSelected} onChange={value=>handleUser(value)} />
                <button className="btn btn-info btn-sm ml-3 mr-3" onClick={addUser} disabled={loadingAdd}> <i className="fa-solid fa-user-plus font-15"></i>&nbsp; Asignar</button>
             
          
       </div>
     <TableInfinityFixed aditionalClass='table-striped' columnsParam={columns}>
            {personal.map((element,i) => {
            const {label,Sueldo,edit,comments,show} = element;
            return(
                <Fragment key={i}>
                <tr >
                    <td className='d-flex justify-content-between p-3'>
                        <h6>{label}</h6>
                        <button className='btn btn-dark btn-sm p-2 buttonMovile rounded-circle'onClick={e=>showMore(i)} ><i className={`fa-solid fa-angle-${show?'down':'right'} font-15`}></i></button>
               
                    </td>
                    <td className="ocultarmobile">
                        {edit ? <input type="text" style={{width:'200px'}} className='form-control ml-3 mb-2' value={Sueldo} onChange={e=>handleActive(i,'quantity',e.target.value)} /> : <h6 className='text-xs'>$ {Sueldo} </h6>}
                    </td>
                    <td className='ocultarmobile'>
                        {edit && <textarea className="form-control" value={comments} onChange={e=>handleActive(i,'comments',e.target.value)}  placeholder='Motivo de la actualizacion?' />}
                    </td>
                    <td className='input-group-sm ocultarmobile'>
                        { edit ? 
                            <button className='btn btn-sm btn-danger ' onClick={e=>handleUpdate(i)} ><i className={`fa ${loading && ' fa-spin'} fa-sync font-15`} /> actualizar</button> :
                            <button className='btn btn-dark btn-sm ml-2' onClick={e=>handleActive(i,'edit',0)} > <i className='fa fa-edit font-15' /> </button>
                        }
                        <button className='btn btn-sm ml-2' onClick={e=>handleDelete(i)} data-bs-toggle="modal" data-bs-target={`#modaldelete`}> <i className='fa fa-trash font-15 text-danger' /> </button>

                    </td>
                </tr> 
               {show&&(
                 <tr className='shadow'>
                    <div className='d-flex justify-content-between input-group-sm'>
                        { edit ? 
                            <button className='btn btn-sm btn-danger ' onClick={e=>handleUpdate(i)} ><i className={`fa ${loading && ' fa-spin'} fa-sync font-15`} /> actualizar</button> :
                            <button className='btn btn-dark btn-sm ml-2' onClick={e=>handleActive(i,'edit',0)} > <i className='fa fa-edit font-15' /> </button>
                        }
                        <button className='btn btn-sm ml-2' onClick={e=>handleDelete(i)} data-bs-toggle="modal" data-bs-target={`#modaldelete`}> <i className='fa fa-trash font-15 text-danger' /> </button>
                    </div>
                    {edit ? <input type="text" style={{width:'200px'}} className='form-control ' value={Sueldo} onChange={e=>handleActive(i,'quantity',e.target.value)} /> : <h6 className='text-xs '>$ {Sueldo} </h6>} 
                </tr>
               )}
                </Fragment>
            )
            })}
            <ModalDelete {...{itemToDelete,reloadColab}}/>
       </TableInfinityFixed>
     </div>
     </>
    )
}

const ModalDelete=({itemToDelete,reloadColab})=>{
    const handleSubmit =async()=>{
        const response=await request.delete(`personal/desactivateSalary/${itemToDelete}`)
        if (response && response.statusCode==200) {
            reloadColab(itemToDelete)
            return 'success'
        }else{
            return 'error'
        }
    }
    return(
        <SimpleModal identifier={'modaldelete'} heightProp='150'>
            <div className="text-center p-3">
                 <h3>¿ELIMINAR REGITRO?</h3>
                 <ModalButtons handleSubmit={handleSubmit} labelSave='Si, eliminar' />
            </div>
        </SimpleModal>
    )
}


