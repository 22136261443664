import React, { useEffect, useState } from "react";
import { Aside } from "../../components/Aside";
import { NavSection } from "../../components/NavSection";
import { UserInfo } from "../../components/UserInfo";
import { CoverPick } from "../../components/CoverPick";
import { choseTheme } from "../../themes/teme";
import defaultImage from "../../static/enero.jpg";
import { SimpleModal } from "../../components/ReactModal/SimpleModal";
import { decryptString, encryptString } from "../../utils/encrypt";
import Request from "../../utils/http"
import { BottomMenu } from "./BottomMenu";
import { openDB } from "idb";
import { useNavigate } from "react-router-dom";

// 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
const request = new Request()
export const LayoutMovile = ({ children,  showTabs = true,coverPick=true,setToastAlert }) => {
  const navigate = useNavigate ();
  const [toogled, setToogled] = useState(false);
  const [theme, setTeme] = useState({
    backgroundImage: defaultImage,
    gradientColor: "bg bg-gradient-dark",
  });
  const [user, setUser] = useState({
    name: "",
    lastName: "",
    img: "https://th.bing.com/th/id/OIP.4-g8iHzmoxK1nsA0zc0oXwHaHa?pid=ImgDet&rs=1",
    puesto: "",
    wArea: "",
  });
  const [token, setToken] = useState(null);
 
  const toogleElement = () => {
    const elemento = document.querySelector(
      ".g-sidenav-show:not(.rtl) .sidenav"
    );
    if (!toogled) {
      elemento.style.transform = "translateX(0rem)";
    } else {
      elemento.style.transform = "translateX(-17.125rem)";
    }
    setToogled(!toogled);
  };
  useEffect(() => {
   getUser()
   
    var fechaActual = new Date();
    // Obtiene el mes (0-11)
    //var mes =4
    var mes = fechaActual.getMonth();
    // Suma 1 al mes para obtener el mes actual (1-12)
    mes = mes + 1;
    if (mes == 13) {
      mes = 12;
    }
    let themeimg = choseTheme(mes);
    setTeme(themeimg);
  }, []);
  const getUser=async()=>{

    let user = sessionStorage.getItem("user");
    user = JSON.parse(decryptString(user));
    
   
    const response = await request.get(`fotoUser/${user.id_personal}`);
  if (response && response.statusCode==200) {
  
    user.personalData.img=response.result.data.img
    user.personalData.id_personal=user.id_personal
  }
  checkTokenInIndexedDB(user.device)
    setUser(user.personalData);
  }
  const updateImg=(image)=>{
    setUser({...user,img:image})
    let userstorage = sessionStorage.getItem("user");
        userstorage = JSON.parse(decryptString(userstorage));
        userstorage.personalData.img=image
        userstorage = encryptString(JSON.stringify(userstorage))
    sessionStorage.setItem('user', userstorage);
  }

  
    const checkTokenInIndexedDB = async (device) => {
     
      try {
        // Abrir la base de datos (asegúrate de usar el nombre correcto de tu BD y object store)
        const db = await openDB("my-database", 1, {
          upgrade(db) {
            if (!db.objectStoreNames.contains("authStore")) {
              db.createObjectStore("authStore");
            }
          },
        });

        // Obtener el token del object store "authStore"
        const storedToken = await db.get("authStore", "token");
     

        // Actualizar el estado con el token encontrado
        if (storedToken) {
          if (device ==storedToken) {
            
            setToken(storedToken);
          }else{
          
            setToken('error');
          }
        }
      } catch (error) {
        console.error("Error al acceder a IndexedDB:", error);
      }
    };
    // Función para generar un token aleatorio
    const generateToken = () => {
      return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    };
  

    // Función para guardar el token en IndexedDB
    const storeTokenInIndexedDB = async (newToken) => {

      
      try {
        const response = await request.post(`authStore/registerdevice/${user.id}`,{token: newToken})
        if (response && response.statusCode === 200) {
          const db = await openDB("my-database", 1);
          await db.put("authStore", newToken, "token"); // Guardar el token en IndexedDB
          
          setToken(newToken); // Actualizar el estado del token
        }else{
          setToastAlert({text:'Error al guardar, ya existe otro dispositivo vinculado',type:'error'},)
        }

      } catch (error) {
        console.error("Error al guardar el token en IndexedDB:", error);
      }
    };
  
    // Manejador del evento de clic para crear y almacenar un token
    const handleCreateToken = () => {
      const newToken = generateToken();
      storeTokenInIndexedDB(newToken);
    };
    const logout=async() => {
      const response = await request.post('logout')
      if (response && response.statusCode === 200) {
        sessionStorage.clear();
     
        navigate('/');
      }
    }
  
  return (
    <>
    
      <div className={`main-content position-relative ${theme.gradientColor} mt-n7 max-height-vh-100 h-100 container-fluid-movile`} >
        <Aside toogleElement={toogleElement} />
       { <NavSection toogleElement={toogleElement} title={"title"} />}
        <UserPhotoModal {...{ user,updateImg }} />
        <CoverPick backgroundImage={theme.backgroundImage} coverPick={coverPick}/>
        <div className="container-fluid ">
          <div className="card card-body blur shadow-blur mx-0 mt-n7 overflow-hidden">
            <div className="row gx-4 mt-1">
              {showTabs && <UserInfo {...{ user}} />}
              {/* {showTabs && <TabsSections handleRender={handleRender} />} */}
            </div>
            {children}
          </div>
        </div>

        <div className="container-fluid py-4 mt-n4 bg-gradient- shadow p-3 showInMovile">
        <div className="bottom-navbar rounded text-center">
        {/* {token === 'error' && 
            <>
            <span className="text-bold text-danger" onClick={logout}>Dispositivo Incorrecto. <strong>Cerrar Sesion</strong></span>
        
            </>
            
            }

            {!token && (
              <button className="btn btn-danger btn-block" onClick={handleCreateToken}>
                REGISTRAR ESTE DISPOSITIVO
              </button>
            )}

            {token && token !== 'error' && <BottomMenu token={token} />} */}
            <BottomMenu token={token} />
          </div>

         {/* { token != null ? <BottomMenu {...{ token }} />: token !='error'?
         <div className="bottom-navbar rounded text-center">
           <button className="btn btn-danger btn-block"  onClick={handleCreateToken}>REGISTRAR ESTE DISPOSITIVO</button> 
         </div>:'ya existe un dispositivo vinculado '
        } */}
        </div>
      </div>
    </>
  );
};
const UserPhotoModal = ({ user ,updateImg}) => {
  const [userFoto, setUserFoto] = useState("");
  const [loading, setLoading]=useState(false)
  const [newPhoto, setNewPhoto] = useState(false)
  const defaultImage =
    "https://th.bing.com/th/id/OIP.4-g8iHzmoxK1nsA0zc0oXwHaHa?pid=ImgDet&rs=1";

  function openCamera() {
    var cameraInput = document.getElementById("cameraInput");
    cameraInput.click();
    
    // Capturar el cambio en el input de tipo "file"
    cameraInput.addEventListener("change", function () {
      setNewPhoto(true)
      displayImage(this);
    });
  }
  useEffect(() => {
    setUserFoto({
      name: user.name,
      lastName: user.lastName,
      img: user.img,
      puesto: user.puesto,
      wArea: user.wArea,
      id: user.id,
      idPersonal:user.id_personal
    });
  }, [user]);

  function displayImage(input) {
    // Verificar si se seleccionó un archivo
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        // Crear un elemento de imagen y establecer la fuente con la imagen cargada

        setUserFoto({ ...userFoto, img: e.target.result });
      };

      // Leer el archivo como una URL de datos
      reader.readAsDataURL(input.files[0]);
      // saveImage(input.files[0])
    }
  }
  const saveImage=async(file)=> {
      setLoading(true)
      // Enviar la imagen al servidor para guardar en la base de datos
      var formData = new FormData();
      formData.append('photo', file);
  
    const response = await request.post(`updateImageUser/${userFoto.idPersonal}`, formData)
    if (response && response.statusCode === 200) {
      
      updateImg(response.result.data)
    }
    setLoading(false) 
  }
  
  const closeFunction =()=>{
    setNewPhoto(false)
    setUserFoto({
      name: user.name,
      lastName: user.lastName,
      img: user.img,
      puesto: user.puesto,
      wArea: user.wArea,
      id: user.id,
      idPersonal:user.id_personal
    });
  } 
   
    
    
    
  return (
    <SimpleModal identifier={"UserPhotoModal"} size="400" heightProp="500" handleCloseFunction={closeFunction}>
      <div id="imageContainer" className="p-2">
        <img src={userFoto.img} alt="" style={{ width: 330, height: 300 }} />
      </div>

      <input type="file" accept="image/*" capture="camera" id="cameraInput" style={{ display: "none" }} />
      <div className="d-flex justify-content-center p-2">
        <button className="btn btn-dark mt-3" onClick={openCamera}>
          <i className="fa fa-camera font-15 " /> &nbsp; Abrir Cámara
        </button>
        {newPhoto&&
        <button className="btn btn-dark mt-3" onClick={e=>saveImage(userFoto.img)}>
          <i className={ `fa fa-sync ${loading?'fa-spinner fa-spin':''} font-15` }/> &nbsp; actualizar imagen
        </button>
        }
      </div>
    </SimpleModal>
  );
};
