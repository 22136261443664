import React, { useState } from 'react'

export const SearchComponent = ({handleSearch,clearFunction}) => {
    const [text,setText]=useState('')
    const [searching,setSearching]=useState(false)
    const [empty,setEmty]=useState(false)
    
    const handleSearchMode=(status,word)=>{
      setText(word)
        setSearching(status)
        if (!status) {
            setText('')
            clearFunction([])
            setSearching(false)
            setEmty(false)
            return;
        }
      let response=  handleSearch(word)
   
        setEmty(response)
      
      }

  return (
    <div className='d-flex justify-content-start p-3 '>
        <div>
            <input type="text" value={text} placeholder='escribe para buscar ' onChange={e=>handleSearchMode(true,e.target.value)} className="form-control input-sm" style={{width:'300px',height:'40px'}} />
           {empty&& <small className='text-danger'>No se encontraron resultados <i class="fa-solid fa-heart-crack"></i> </small> }
        </div>
        {/* <button onClick={e=>handleSearchMode(true)} className='btn bg bg-gradient-success btn-sm ml-3 mt-1'> <i className='fa fa-search font-15 '></i> buscar</button> */}
        {searching&& <button onClick={e=>handleSearchMode(false)} className='btn bg bg-gradient-danger btn-sm ml-3 mt-1'> <i className="fa-solid fa-circle-xmark font-15"></i> limpiar resultados</button>}
  </div>
  )
}
