import React, { Fragment } from 'react'
import {Layout} from '../Layouts/Layout'
import Request from '../../utils/http'
import { useState } from 'react'
import { useEffect } from 'react'
import { ModalUpdateDepartMent } from './ModalUpdateDepartMent'
import { ItemRow } from './ItemRow'
import { ModalUpdateGroup } from './ModalUpdateGroup'
import { TableInfinityFixed } from '../../components/TableInfinityFixed'
import { LoadingData } from '../../components/LoadingData'
import { TableDescription } from './TableDescription'
const request = new Request()
const header = [
    {label:'Puestos'},
    {label:'Departamento'},
    {label:'Descripcion del departamento '},
    {label:'Sueldo promedio'},
    {label:'Estatus'},
]
export const GroupsAndDepartments = () => {

  const [loading,setisloading]=useState(false)
  const [data,setData] = useState([])
  const [dataModal,setDataModal]=useState([])
  const [dataModalGroups,setDataModalGroups]=useState([])
  const [editGroup,setEditGroup] = useState(false)
  const [editDepto,setEditDepto] = useState(false)
  const [showPersonal,setShowPersonal]=useState(false)

  const getInfo = async () => {
    setisloading(true)
    try {
      const response = await request.get("information/departments", []);
    
     
      if (response && response.result.success === true) {
        setData(response && response.result.datos.Department);
      }
    } catch (error) {
      console.log("getData -> error", error);
    }
    setisloading(false)
  };

  useEffect(() => {
    getInfo()
  },[])

  const handleChange=async(element) => {
    setEditDepto(true)
    setDataModal([element])
    
  }
  const handleChangeGroup=async(element) => {
    setEditGroup(true)
    setDataModalGroups([element])
    
  }

  const handleAddGroup=async(iddep) => {
    
    setEditGroup(false)
    setDataModalGroups([{
      active: 1,
      description:'',
      idDepartment:data[iddep].iddepartment
    }])
  }
  const handleAddNewDepartment=async() => {
    
    setEditDepto(false)

    setDataModal([{
      Active: 1,
      description: ''
    }])
  }

  const UPDATEURL='information/groups/update'
  const ADDGROUPURL='information/groups/create'
  const UPDATEURLDPTO='information/departments/editar'
  const ADDGROUPURLDPTO='information/departments/save'
  
  return (
    <Layout title='Grupos y departamentos' >
        <div className="card p-3">
  
          <div className="d-flex input-group-sm justify-content-end">
          <button className="btn btn-dark" data-bs-toggle="modal" data-bs-target={`#department`} onClick={handleAddNewDepartment}> <i className="fa fa-plus font-12"/> Agregar departamento</button>
          </div>

          <TableInfinityFixed columnsParam={header} aditionalClass='table-striped clickeable'>
            {data.map((element,key) => 
              <ItemRow 
                handleAddGroup={handleAddGroup} 
                key={key} 
                uuid={key} 
                element={element} 
                handleChange={handleChange} 
                handleChangeGroup={handleChangeGroup} 
                setShowPersonal={setShowPersonal}
                reload={getInfo}
                />
            )}
            <LoadingData colspan={5} loading={loading} quantity={data.length}/>
          </TableInfinityFixed>
          <ModalUpdateDepartMent setDataModal={setDataModal}  dataModal={dataModal} url={editDepto?UPDATEURLDPTO:ADDGROUPURLDPTO} reload={getInfo}/>
          <ModalUpdateGroup  dataModal={dataModalGroups} url={editGroup?UPDATEURL:ADDGROUPURL} reload={getInfo}/>

      
         
        </div>


    </Layout>
  )
}



