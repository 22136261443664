import React, { Fragment, useEffect, useState } from 'react'
import { LoadingData } from '../../components/LoadingData'
import Request from '../../utils/http'
import { ToastAlert } from '../../components/ToastAlert'
const request = new Request()

export const ItemRow =({element,handleChange,uuid,handleChangeGroup,handleAddGroup,setShowPersonal,reload}) => {
 
    const {description,Active,groups,description_puesto} = element
    const [showGroups,setShowGroups]=useState(false)
    const [total,setTotal]=useState(0)
    const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
    const [loading,setLoading]=useState(false)

    const handleDelete=async(id) => {
      setLoading(true)
      const response = await request.delete(`groups/delete/${id}`)
      if (response && response.statusCode ==200) {
        setToastAlert({text:'Guardado con exito',type:'success'},)
        reload()
      }
      setLoading(false)
    }


    const calculatePromedio=()=>{
      let i=0
      let moneda='MXP'
      let copy = JSON.parse(JSON.stringify(groups))
      copy.forEach(element => {
        i=i+(parseFloat(element.sueldo_base)/groups.length)
        moneda=element.moneda?element.moneda:'MXP'
      });
      i= formatCurrency(i)
      setTotal((i)+moneda)
    }
    useEffect(()=>{
      calculatePromedio()
    },[])

    const formatCurrency=(quantity)=>{
      const cantidadFormateada = quantity.toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN', // Puedes cambiar 'USD' a otras abreviaturas de moneda según tu necesidad
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return cantidadFormateada
    }
    return(
      <Fragment key={uuid} >
        <ToastAlert {...{toastAlertProps}} /> 
      <tr key={`p${uuid}`}>
        <td className='pb-0 input-group-sm'>
            <button className="btn btn-sm  btn-dark font-8" onClick={e=>setShowGroups(!showGroups)}> 
              <i className={`fa-solid fa-chevron-${showGroups?'down':'right'}`}></i> ver Puestos
            </button>
        </td>
        <td className='p-1' data-bs-toggle="modal" data-bs-target={`#department`} onClick={e=>handleChange(element)}>
          <h6 className='text-xs'>{description}</h6>
        </td>
        
        <td className='p-1 input-group-sm' data-bs-toggle="modal" data-bs-target={`#department`} onClick={e=>handleChange(element)}>
         <h6 className='text-xs'> {description_puesto?.slice(0,60)} {description_puesto&&<small>(...ver mas)</small>} </h6>
  
        </td>
        <td className='p-1 input-group-sm text-center'>
       <h6 className='text-xs'> {total}</h6>
        </td>
        <td className='p-1' data-bs-toggle="modal" data-bs-target={`#department`} onClick={e=>handleChange(element)}>
          <span className={`shadow bg bg-gradient-${Active==1?'success':'danger'} text-xxs text-white font-8 p-2 -sm`} style={{borderRadius:'5px'}}> 
           <i className='fa fa-checkmark'></i> {Active==1?'Activo':'Inactivo'}
          </span>
        </td>
       
      </tr>

      {showGroups ?
        groups.length>0?
        <>
          <tr style={{backgroundColor:'#e4efff8f'}}>
            <td colSpan={7} className='d-flex  justify-content-start p-1'>
            <div className="input-group-sm m-0">

              <button 
                className="btn btn-sm m-0 bg bg-gradient-dark font-8" 
                data-bs-toggle="modal" 
                data-bs-target={`#groups`} 
                onClick={()=>handleAddGroup(uuid)}>
                    <i className="fa fa-plus font-8"/> agregar puesto
              </button>
            </div>
            </td>
           
          </tr>
        
           {   groups.map((group,i)=>{
          
          
            const {description,active,sueldo_base,moneda,idgroup}=group
            let q=formatCurrency(parseFloat(sueldo_base))
            return(
              <>
              
              <tr key={`k${i}`} style={{backgroundColor:'#e4efff8f'}} >
             
                    <td data-bs-toggle="modal" data-bs-target={`#groups`} onClick={e=>handleChangeGroup(group)} className='text-end' >
                  
                  </td>
                  <td data-bs-toggle="modal" data-bs-target={`#groups`} onClick={e=>handleChangeGroup(group)}className='pb-0' >
                      <h6 className="text-xs  ">Puesto: <strong className='text-dark'>{description}</strong></h6>
                    
                    </td>
                  
                  
                  
                    <td data-bs-toggle="modal" data-bs-target={`#groups`} onClick={e=>handleChangeGroup(group)} className='pb-0'>
                      <h6 className='text-xs  '>Sueldo base: <strong className='text-dark'> {q}{moneda}</strong></h6>
                      
                    </td>
                    <td data-bs-toggle="modal" data-bs-target={`#groups`} onClick={e=>handleChangeGroup(group)}><h6 className='text-xs  '>{description?.slice(0,60)} <small>(...ver mas)</small></h6></td>
                    <td   className='pb-0 input-group-sm'>
                      <button className={`btn btn-sm bg bg-gradient-${active==1?'warning':'warning'}  text-white  font-8 -sm`} > 
                        {active==1?'Activo':'Inactivo'}
                      </button>
                      <button className={`btn btn-sm bg bg-danger text-white   ml-3`} disabled={loading} onClick={e=>handleDelete(idgroup)} > 
                        <i className={'fa fa-trash font-15'}></i>
                      </button>
                    </td>
                
              </tr>
              </>
            )
          })}
        </>
          :
          <tr>
            <td className="p-0 input-group-sm">
            <button 
                className="btn btn-sm ml-1 bg bg-gradient-dark" 
                data-bs-toggle="modal" 
                data-bs-target={`#groups`} 
                onClick={()=>handleAddGroup(uuid)}>
                    <i className="fa fa-plus font-12"/> agregar grupo
              </button>
            </td>
            <td className='d-flex justify-content-center'> 
              <LoadingData widthImage='80' dataCount={groups.length} />
            </td>
            <td></td>
          </tr>:null
    }

      </Fragment>
    )
  
}
