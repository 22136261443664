import React, { useState } from 'react'
import { TableInfinityFixed } from '../../../../components/TableInfinityFixed'
import { SimpleModal } from '../../../../components/ReactModal/SimpleModal';
import { Toast } from '../../../../components/Toast';
import Request from '../../../../utils/http'
import { LoadingData } from '../../../../components/LoadingData';
const request = new Request();
export const TableColaborators = ({colaborators,userImage,handleUpdate,reload,loading,statusColaborator}) => {
  
  const [dataToDelete,setDataToDelete]=useState({})
    const column=[
        {label:'Colaborador',classColumn:'text-uppercase text-secondary text-xxs font-weight-bolder opacity-7  ps-2'},
        {label:'Departamento',classColumn:'text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'},
        {label:'Jefe inmediato',classColumn:'text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'},
        {label:'Ingreso',classColumn:'text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'},
        {label:'Eliminar',classColumn:'text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'},
      ]
      function isValidJSON(str) {
        try {
          let dep= JSON.parse(str);
          return  dep.length>0?   dep[0].label: dep.label
        } catch (error) {
          return str;
        }
      }
  
      const getInfoPersonal=(birthday,ingreso)=>{
        let nac =new Date(birthday).getTime();
        let today =new Date().getTime();
        var diff =( today - nac)/(1000*60*60*24).toFixed(2);
        let annios =(diff/365 ).toFixed(2)
        let arr= annios.split('.')
        let meses= ((arr[1]/100)*365)/30
    
        let ing =new Date(ingreso).getTime();
        let todayAnt =new Date().getTime();
        var diffAnt =( todayAnt - ing)/(1000*60*60*24).toFixed(2);
        let anniosAnt =(diffAnt/365 ).toFixed(2)
        let arrAnt= anniosAnt.split('.')
        let mesesAnt= ((arrAnt[1]/100)*365)/30
  
        let formatIng=ingreso.split('-')
        let d= formatIng.length>0 && `${formatIng[2]}/${formatIng[1]}/${formatIng[0]}`
  
        return [ `${arr[0]} años ${Math.round(meses)} meses`,`${arrAnt[0]} años ${Math.round(mesesAnt)} meses`,d]
  
  
    }
   
  return (
    <div className="card shadow p-2">
    <TableInfinityFixed columnsParam={column} aditionalClass='table-striped clickeable'>
      {colaborators.map((colaborator,key) => {
      const {name,lastName,rfc,wArea,puesto,inmBoss,ingreso,birthday} =colaborator
    
      let dep = wArea
      let psto = puesto
      let boss = isValidJSON(inmBoss,name)
     

      let dates=getInfoPersonal(birthday,ingreso)
        return(
          <tr key={key} >
            <td onClick={e=>handleUpdate(colaborator)}>
              <div className="d-flex px-2 py-1">
                <div>
                  <img src={userImage} className="avatar avatar-sm me-3" alt="foto colaborador"/>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <h6 className="mb-0 text-xs">{name} {lastName}</h6>
                  <p className="text-xs text-secondary mb-0">{dates[0]}</p>
                </div>
              </div>
            </td>
            <td onClick={e=>handleUpdate(colaborator)}>
              <p className="text-xs font-weight-bold mb-0">{dep}</p>
              <p className="text-xs text-secondary mb-0">{psto}</p>
            </td>
            <td className="align-middle text-center text-sm" onClick={e=>handleUpdate(colaborator)}>
              <span className="text-xs text-secondary mb-0">{boss}</span>
            </td>
            <td className="align-middle text-center" onClick={e=>handleUpdate(colaborator)}>
            <p className="text-xs font-weight-bold mb-0">{dates[2]}</p>
              <p className="text-xs text-secondary mb-0">{dates[1]}</p>
            </td>
            <td className="align-middle text-center input-group-sm">
            <button className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target={`#handledelete`} onClick={e=>setDataToDelete(colaborator)} >
              <i className="font-15 fa fa-trash"></i>
            </button>
            </td>
          </tr>
        )
      })}
    <LoadingData loading={loading} quantity={colaborators.length} colspan={5}/>
    </TableInfinityFixed >
    <SimpleModalDelete dataToDelete={dataToDelete} reload={reload} statusColaborator={statusColaborator}/>
    
  </div>
  )
}

const SimpleModalDelete=({dataToDelete,reload,statusColaborator})=>{
  const [showToastProp,setShowToast]=useState({
    showToast:false,
    labelHeader:'Éxito',
    message:'Operacion exitosa',
    type:'success'
})
  const [loading,setLoading] =useState(false)
  const deleteUser=async(data) => {
    setLoading(true)
      const response=await request.put(`personalHana/deletePersonal/${dataToDelete.id}`)
      if (response && response.statusCode === 200) {
      }
      if (response && response.statusCode==200) {
        setShowToast({...showToastProp,type:'success',showToast:true,labelHeader:'Guardado con exito!!',message:'Por favor espere'})
        reload(statusColaborator)
        
    }else{
        setShowToast({...showToastProp,type:'danger',showToast:true,labelHeader:'Error, intentelo de nuevo',message:'Favor de notificar a sistemas'})
        
    }
    setLoading(false)
    setTimeout(() => {
        setShowToast({...showToastProp,showToast:false})
    }, 2500);
    
  
  }
  return(
    <>
   
    
    <SimpleModal identifier={'handledelete'} heightProp='200' >
      <div className="card p-4 justify-content-center text-center">
        <h6>¿Deseas dar de baja este registro?</h6>
        <h4 className="  ">{dataToDelete?.name} {dataToDelete?.lastName}</h4>

        <p>esta accion no se puede deshacer!! </p>
        <div className="d-flex justify-content-center">
        <button className="btn btn-danger" disabled={loading} onClick={()=>deleteUser(dataToDelete)}>Si,DAR DE BAJA USUARIO</button>
        <button className="btn btn-dark ml-2" data-bs-dismiss="modal">NO,CANCELAR</button>
        </div>
      
      </div>
    </SimpleModal>
    <Toast
        showToast={showToastProp.showToast}
        labelHeader={showToastProp.labelHeader}
        message={showToastProp.message}
        type={showToastProp.type}
      
      />
    </>
  )
}