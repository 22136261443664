import React, { useEffect, useState } from 'react'
import {Publications} from './components/Publications'
import { Apps } from '../apps'
import { openDB } from "idb";

import { decryptString, encryptString } from "../../../utils/encrypt";
import Request from "../../../utils/http";
import { useNavigate } from 'react-router-dom';
const request = new Request();
export const TabOne = ({loggedIn,useDataInfo, setToastAlert}) => {
  const navigate = useNavigate ();
  const [token, setToken] = useState(null);
 
  const [user, setUser] = useState({
    name: "",
    lastName: "",
    img: "https://th.bing.com/th/id/OIP.4-g8iHzmoxK1nsA0zc0oXwHaHa?pid=ImgDet&rs=1",
    puesto: "",
    wArea: "",
  });
  useEffect(()=>{
    let userCheck = sessionStorage.getItem("user");
    userCheck = JSON.parse(decryptString(userCheck));
    setUser(userCheck)
    checkTokenInIndexedDB(userCheck.deviceOffice)
  },[])

  const checkTokenInIndexedDB = async (device) => {
    
    try {
      // Abrir la base de datos (asegúrate de usar el nombre correcto de tu BD y object store)
      const db = await openDB("my-database", 1, {
        upgrade(db) {
          console.log(1)
          if (!db.objectStoreNames.contains("authStore")) {
            
            db.createObjectStore("authStore");
          }
        },
      });

      // Obtener el token del object store "authStore"
      const storedToken = await db.get("authStore", "tokenOffice");
   

      // Actualizar el estado con el token encontrado
      if (storedToken) {
        if (device ==storedToken) {
          
          setToken(storedToken);
        }else{
        
          setToken('error');
        }
      }
    } catch (error) {
      console.error("Error al acceder a IndexedDB:", error);
    }
  };
  // Función para generar un token aleatorio
  const generateToken = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };


  // Función para guardar el token en IndexedDB
  const storeTokenInIndexedDB = async (newToken) => {

    
    try {
      const response = await request.post(`authStore/registerdeviceOffice/${user.id_personal}`,{token: newToken})
      if (response && response.statusCode === 200) {
        const db = await openDB("my-database", 1);
       
        await db.put("authStore", newToken, "tokenOffice"); // Guardar el token en IndexedDB
        
        setToken(newToken); // Actualizar el estado del token
      }else{
        setToastAlert({text:'Ya existe otro dispositivo vinculado',type:'error'},)
      }

    } catch (error) {
      console.error("Error al guardar el token en IndexedDB:", error);
    }
  };

  // Manejador del evento de clic para crear y almacenar un token
  const handleCreateToken = () => {
    const newToken = generateToken();
    storeTokenInIndexedDB(newToken);
  };
  const logout=async() => {
    const response = await request.post('logout')
    if (response && response.statusCode === 200) {
      sessionStorage.clear();
   
      navigate('/');
    }
  }
  return (
    <>
      
      {/* <CardsAlert {...{loggedIn}} /> */}
     
     <div className="row">
      <div className="col-md-12  col-xl-4 isMOvile" >
      <ul className="">
      {/* {token === 'error' && 
            <>
            <span className="text-bold text-danger" onClick={logout}>Dispositivo Incorrecto. <strong>Cerrar Sesion</strong></span>
        
            </>
            
            }

            {!token && (
              <button className="btn btn-danger btn-block" onClick={handleCreateToken}>
                REGISTRAR ESTE DISPOSITIVO
              </button>
            )}

            {token && token !== 'error' &&  <Apps showLayout={false}  />} */}
<Apps showLayout={false}  />
       
    </ul>
      </div>
      <div className="col-md-8 col-xl-8 " >
      <Publications {...{useDataInfo}} />
      </div>
     </div>
     
    </>
  )
}
