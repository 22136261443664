import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

export const ToastComponent = ({ message, type }) => {

  useEffect(() => {
    if (message != '') {
      switch (type) {
        case "success":
          toast.success(message);
          break;
        case "warning":
          toast.warning(message);

          break;
        case "error":
          toast.error(message);
          break;

        default:
          toast.info(message);

          break;
      }
    }
  }, [message]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={800}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
};
// setType('success')
// setMessage('Guardado con exito!')

// const [message, setMessage] = useState('')
// const [type, setType] = useState('success')