import React, { useEffect, useState } from 'react'
import { TableInfinityFixed } from '../../../components/TableInfinityFixed'
import Modal from 'react-modal'
import Select from 'react-select'
import Request from '../../../utils/http'
import { useAppContext } from '../../../Context/AppContext'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid';
import { ToastAlert } from '../../../components/ToastAlert'
const request = new Request()
export const TableEncuesta =({encuestas})=>{
  
    const {user,getPermision} = useAppContext()
    const [personal,setPersonal] = useState([])
    const [deptos,setdeptos] = useState([])
    
  const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
    useEffect(() => {
        getInfo()
        user!='tengo estado' &&    getUser();
      }, [user]);
      const getUser = async () => {
        
          getData(user);
      };
      const getData = async (user) => {
          
          let datos = [];
          let data = {
            token: user.token,
            id: user.userId,
          };
      
            const response = await request.post("users/controlAccess", data);
           
            if (response && response.statusCode==200) {
              response.result.error.forEach((element) => {
                datos.push({
                 
                  value: element.id_personal,
                  label: element.name,
                 
                });
            });
        }
        
    
          setPersonal(datos);
         
        };
 
    const getInfo = async () => {
       
        try {
          
          const response = await request.get("information/departments", []);
          
          
          if (response && response.result.success === true) {
            let d = []
            response.result.datos.Department.forEach(element => {
              let ids=[]
              element.groups.forEach(personal=>{
                 
                  personal.personal.forEach(item => {
                    ids.push(item.id)
                  });
                })
                d.push({
                    value:element.iddepartment,
                    label:element.description,
                    ids:ids
                })
            });
            setdeptos(d);
          }
        } catch (error) {
          console.log("getData -> error", error);
        }
       
      };
    return(
        <>
         <ToastAlert {...{toastAlertProps}} />
            <TableInfinityFixed height={'500px'}  aditionalClass='table-striped clickeable shadow rounded' 
            columnsParam={[{label:'#'},{label:'Descripción'},{label:'Numero de preguntas'},{label:'Fecha de cierre'},{label:'Publicar encuesta'},{label:'Eliminar encuesta'}]}>
                {encuestas.map((encuesta,i)=>{
                const {id,published}=encuesta;
                console.log("🚀 ~ {encuestas.map ~ encuesta:", encuesta)
                return(
                    <tr key={i}>
                    <td>{i+1}</td>
                    <td>{encuesta.description}</td>
                    <td>{encuesta.preguntas.length}</td>
                    <td>{published.length>0?published[0].dateLimit:''}</td>
                    <td>
                    {published.length>0?<ModalCloseSurvey id={id} />:  <ModalToPublish {...{deptos,personal,id,setToastAlert}} />}
                    </td>
                    <td><button className="btn btn-danger p-2"><i className='fa fa-trash font-15'></i></button></td>
                    </tr>
                )
                })}
            </TableInfinityFixed>
        
        </>
    )
  }

  const ModalToPublish=({deptos,personal,id,setToastAlert})=>{
    const [openModal, closeModal] =useState(false)
    const [loading,setLoading] =useState(false)
    const [comments,setComments] =useState('')
    const [publish,setPublish]=useState({
        dateLimit:moment().format('YYYY-MM-DD'),
        visibility:0,
        participants:[]

    })
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "10%",
          bottom: "-35%",
          width: "37%",
          height: '55%',
          transform: "translate(-50%, -50%)",
        }
    }
    const handleClose=()=>{
       
        closeModal(false)
      }
    const handlePublish=async()=>{
        let copy = JSON.parse(JSON.stringify(publish))
            copy.id=id
            copy.identifierToPublish=uuidv4();
            copy.participants=JSON.stringify(copy.participants)
            copy.comments=comments
            console.log("🚀 ~ handlePublish ~ copy:", copy)

        const response = await request.post('encuestas/publish',copy) 
        if (response && response.statusCode==200) {
         setToastAlert({text:'Guardado con exito',type:'success'},)
        }
    }
    const handleVisibility=(value)=>{
        setPublish({...publish,visibility:value,participants:[]})
    }
    return(
        <>
            <button className="btn btn-dark p-1" onClick={e=>closeModal(true)}>Publicar &nbsp;<i className="font-15  fa-solid fa-square-share-nodes"></i></button>
            <Modal isOpen={openModal} style={customStyles} onRequestClose={handleClose} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} >
               <div>
                  <label >Fecha limite para responder</label>
                  <input type="date" className="form-control" value={publish.dateLimit} onChange={e=>setPublish({...publish,dateLimit:e.target.value})} />
               </div>
               <div>
                <label >¿Quien respondera la encuesta?</label>
                <select className='form-control' value={publish.visibility} onChange={e=>handleVisibility(e.target.value)}>
                    <option value="0">Selecciona una opción</option>
                    <option value="1">Todos los colaboradores</option>
                    <option value="2">Departamento</option>
                    <option value="3">Algunos colaboradores</option>
                </select>
               </div>
               <div className='mt-2'>
                  {publish.visibility==2&&  <Select onChange={value=>setPublish({...publish,participants:value})} value={publish.participants} options={deptos} isMulti={true} />}
                  {publish.visibility==3&&  <Select onChange={value=>setPublish({...publish,participants:value})} value={publish.participants} options={personal}  isMulti={true} />}
               </div>
               <div>
                <label >Comentarios adicionales (opcional)</label>
                <textarea value={comments} onChange={e=>setComments(e.target.value)} className='form-control'></textarea>
               </div>

               <div className="text-end mt-2">
                <button className='btn btn-dark' onClick={handlePublish}>{loading?'publicando':'publicar'} <i className={loading?'fa fa-spin fa-spinner':'fa fa-upload'}></i></button>
               </div>
               
            </Modal>
        </>
    )
  }

  const ModalCloseSurvey=({id})=>{

    const [openModal, closeModal] =useState(false)
    const [loading,setLoading] =useState(false)

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "10%",
        bottom: "-35%",
        width: "37%",
        height: '25%',
        transform: "translate(-50%, -50%)",
      }
  }
  const handleClose=()=>{
    closeModal(false)
  }

  const handleCancel=async()=>{
    const response = await request.put(`surveysdesactivate/${id}`)
    if (response && response.statusCode==200) {
      
    } 
  }
    return(
      <>
      <button className='btn btn-sm p-2 btn-danger' onClick={e=>closeModal(true)}>Cerrar encuesta &nbsp;<i class="fa-regular fa-circle-xmark font-20"></i></button>
      <Modal isOpen={openModal} style={customStyles} onRequestClose={handleClose} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} >
           
        <div className="text-center">
          <h3>¿Cerrar esta encuesta?</h3>
          <h5>No se podra reactivar nuevamente para esta ronda de encuestados</h5>

        <div className='mt-4'>
          <button className='btn btn-dark mr-2' onClick={handleCancel}> si, cerrar encuesta</button>
          <button className='ml-2 btn' onClick={e=>closeModal(true)}> cancelar</button>
        </div>
        </div>

      </Modal>
      </>
    )
  }