import React, { useEffect, useState } from 'react'
import { LayoutMovile } from '../../Layouts/LayoutMovile'
import { CardsAlert } from '../HomePage/components/CardsAlert'
import { decryptString } from '../../../utils/encrypt';
import Request from '../../../utils/http';
import Modal from 'react-modal';
import moment from 'moment-timezone';
import { ToastAlert } from '../../../components/ToastAlert';

const request = new Request();
export const Apps = ({showLayout=true}) => {
    const [useData, setUser] = useState(0);
    const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
    const [isOpen,setIsOpen] = useState(false)
   
    useEffect(() => {
  
      let userstorage = sessionStorage.getItem("user");
      userstorage = JSON.parse(decryptString(userstorage));
      setUser(userstorage.id_personal);
      checkLoggedIn();
  
      // Configurar un intervalo para llamar a checkLoggedIn cada minuto
      const interval = setInterval(checkLoggedIn, 60000);
  
      // Limpiar el intervalo al desmontar el componente para evitar fugas de memoria
      return () => clearInterval(interval);
    
    }, []);
  
    const [loggedIn, setLoggedIn] = useState(false); // Estado para almacenar si el usuario está logueado
    const [lastModified, setLastModified]=useState(null)
    
    
    const checkLoggedIn = async () => {
      try {
        // Realizar una llamada al servidor para comprobar check
        
          let userstorage = sessionStorage.getItem("user");
          userstorage = JSON.parse(decryptString(userstorage));
          
          const response = await request.get(`locations/getCheckToDay/${userstorage.id_personal}`);
          if (response && response.statusCode==200) {
            
            setLastModified(response.result.count)
            setLoggedIn(response.result.data); // Actualizar el estado según la respuesta del servidor
          }
        } catch (error) {
          console.error('Error al comprobar si el usuario está logueado:', error);
        }
      };
      const handleOpen=() => {
        setIsOpen(true);
      }
      const closeModal=() => {
        setIsOpen(false);
      }
    
     
      return (
        <>
    
       
    <ModalCheckIn {...{useData,checkLoggedIn,loggedIn,toastAlertProps,setToastAlert,isOpen,closeModal,lastModified}}/> 
    
    {!showLayout?
      <>
         <CardsAlert {...{loggedIn,handleOpen,lastModified}} urlToBack={'homapage'} />
      </>:
    <>
    <ToastAlert {...{toastAlertProps}} />
     <LayoutMovile showTabs={false}>
         <CardsAlert {...{loggedIn,handleOpen,lastModified}} urlToBack={'apps'} />
     </LayoutMovile>
    </>
     }
    </>
  )
}
const ModalCheckIn=({useData,checkLoggedIn,loggedIn,setToastAlert,isOpen,closeModal,lastModified}) => {

 
  const [loading,setLoading]=useState(false)
  const [checking, setChecking] = useState({
    type: 1,
    comments: "",
  });

  
const handleSubmit = async () => {
  setLoading(true);

  if ('geolocation' in navigator) {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted') {
        // Permiso concedido
        getPosition();
      } else if (result.state === 'prompt') {
        // Permiso no ha sido solicitado aún
        getPosition();
      } else if (result.state === 'denied') {
        // Permiso denegado
        alert('Permiso de geolocalización denegado. Por favor, habilítelo en la configuración de su navegador.');
        setLoading(false);
      }

      result.onchange = function () {
        if (result.state === 'granted') {
          getPosition();
        }
      };
    });
  } else {
    alert('Geolocalización no es compatible en este navegador');
    setLoading(false);
  }
};

const getPosition = () => {
  navigator.geolocation.getCurrentPosition(
    async (position) => {
      let data = {
        latitud: position.coords.latitude,
        longitud: position.coords.longitude,
        type: checking.type,
        comments: checking.comments,
        id_user: useData,
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

      const response = await request.post('locations/registerCheck', data);
      if (response && response.statusCode === 200) {
        setToastAlert({ text: 'Guardado con éxito', type: 'success' });
        setChecking({ type: 1, comments: '' });
        checkLoggedIn();
        setLoading(false);
        closeModal();
      }
    },
    (error) => {
      sendNoPositionCords(checking)
      // alert('Error obteniendo la ubicación: ' + error.message);
      setLoading(false);
    }
  );
};

const sendNoPositionCords =async(checking)=>{
  let data = {
    latitud: '0',
    longitud: '0',
    type: checking.type,
    comments: checking.comments,
    id_user: useData,
    time: moment().format('YYYY-MM-DD HH:mm:ss'),
  };

  const response = await request.post('locations/registerCheck', data);
  if (response && response.statusCode === 200) {
    setToastAlert({ text: 'Guardado con éxito', type: 'success' });
    setChecking({ type: 1, comments: '' });
    checkLoggedIn();
    setLoading(false);
    closeModal();
  }
}
  useEffect(()=>{

    if (lastModified) {
      
      switch (lastModified.type) {
        case 1:
          
          setChecking({...checking,  type: 2 })
          break;
          case 2:
            
            setChecking({...checking,  type: 3 })
            break;
            case 3:
              
              setChecking({...checking,  type: 4 })
            

          break;
        // case 4:
          
        //   setChecking({...checking,  type: 4 })
        //   break;
      
        default:
          break;
      }
    }else{
      setChecking({...checking,  type: 1 })

    }
  },[lastModified])

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "-35%",
      width: window.innerWidth <= 768 ? "95%" : "45%", 
      height: "40%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <Modal isOpen={isOpen} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} onRequestClose={closeModal} style={customStyles} >
        <div className='card p-3'>
            <select value={checking.type} onChange={e=>setChecking({...checking,type:e.target.value})} className='form-control mb-3' >
              <option className='d-none' value="1">Entrada</option>
              <option className='d-none' value="2">Salida a comer</option>
              <option className='d-none' value="3">Entrada de comida</option>
              <option className='d-none' value="4">salida de oficina</option>
            </select>
            <textarea className="form-control " placeholder="comentarios (opcional)" cols="30" rows="3" value={checking.comments} onChange={e=>setChecking({...checking,comments:e.target.value})} />
            <button disabled={loading} className="mt-3 btn btn-danger btn-sm" onClick={(e) => handleSubmit()}>
                <i className={`font-15 ${loading?'fa fa-spinner fa-spin':'fa-solid fa-location-crosshairs'}`}></i>{loading?' guardando...':' registrar'}
            </button>
        </div>

      

     </Modal>
  )
  
}
