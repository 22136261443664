import React from 'react'
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { Tooltip, Marker,Popup } from "react-leaflet";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
const LeafIcon = L.Icon.extend({ options: {}});
const icon = new LeafIcon({iconUrl:`http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=E|18e083|000000|15` });
const iconOut = new LeafIcon({iconUrl:`http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=S|e01875|000000|15` });


export const MapUbications = ({coords}) => {
  return (
    <>
      <div className="col-md-12 col-lg-12 shadow  p-3 card" style={{ height: "80vh"}}>
      { coords.in.length>0 && <MapContainer 
        center={coords.in} 
        zoom={14} scrollWheelZoom={true} zoomControl={false}  style={{ height: '80vh', width: '100wh' }}>
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
       <Marker position={coords.in} icon={icon}></Marker>
        { coords.out.length>0&& <Marker position={coords.out} icon={iconOut}></Marker>}
        </MapContainer>}
      </div>
    </>
  )
}
