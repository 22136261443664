import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-modal'
import Request from '../../utils/http'
import './styles.css'
import { useNavigate  } from 'react-router-dom';
import { FiHome,FiCommand,FiBookOpen,FiLogIn   } from "react-icons/fi";
import { LuBellRing } from "react-icons/lu";
import { ModalCloseSesion } from "./ModalCloseSesion";
const request = new Request();
export const BottomMenu = ({ path, label }) => {
  
  const [currentLocationHome,setcurrentLocationHome]=useState(true)
  const [currentLocationApps,setcurrentLocationApps]=useState(false)
  const [currentLocationMessages,setcurrentLocationMessages]=useState(false)
  const [currentLocationFaq,setcurrentLocationFaq]=useState(false)
  const [currentLocationmessages,setcurrentLocationmessages]=useState(false)


  useEffect(()=>{
    let location = window.location.pathname
   
    switch (location) {
      case '/apps':
        setcurrentLocationHome(false)
        setcurrentLocationApps(true)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        break;
      case '/homapage':
        setcurrentLocationHome(true)
        setcurrentLocationApps(false)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        break;
      case '/CheckOfTheDays':
        setcurrentLocationHome(false)
        setcurrentLocationApps(true)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        break;
      case '/faq':
        setcurrentLocationHome(false)
        setcurrentLocationApps(false)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(true)
        break;
      case '/messages':
        setcurrentLocationHome(false)
        setcurrentLocationApps(false)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        setcurrentLocationmessages(true)
        break;
    
      default:
        setcurrentLocationHome(false)
        setcurrentLocationApps(true)
        setcurrentLocationFaq(false)
        // setcurrentLocationFaq(false)
        break;
    }
   
  },[])

  
  return (
    <nav className="bottom-navbar rounded ">
    <ul className="m-2">
      <li>
       
          <Link  className="text-not " to="/homapage"> 
          {/* <i className="fa-light fa-house font-30"></i>  */}
          <FiHome size={'35px'} color={currentLocationHome?'#006298':''} title='home' />
          </Link>
       
      </li>
      <li>
       
          <Link  className="text-not" to="/apps">
          <FiCommand size={'35px'}color={currentLocationApps?'#006298':''}title='Apss'  />
            {/* <i className="fa-solid fa-shapes text-not font-30"></i> */}
         </Link>
       
      </li>
    
        <li>
        
            <Link   to="/messages">{currentLocationMessages&&'#006298'} <i className="fa-solid fa-comments text-not font-30"></i></Link>
        
        </li> 
      
      <li>
       
          <Link   className="text-not" to="/NotificationGeo">
             {/* <i className="fa-solid fa-circle-question text-not font-30">            </i> */}
             {/* <FiBookOpen size={'35px'} color={currentLocationFaq?'#006298':''} /> */}
             <LuBellRing size={'40px'} color={'red'} />
            </Link>
       
      </li>
      <li>
       
         <ModalCloseSesion {...{useNavigate,request,FiLogIn,Modal}}  />
       
      </li>
     
      {/* Agrega más elementos de navegación según sea necesario */}
    </ul>
  </nav>
  );
};



