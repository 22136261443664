import React, { useState,useEffect } from 'react'
import { decryptString } from "../../utils/encrypt";
import Request from "../../utils/http";
import { timeago } from '../../Hooks/timeTransform';
import { Link } from "react-router-dom";
const request = new Request();
export const NavSection = ({toogleElement}) => {



  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
    useEffect(() => {
      let user = sessionStorage.getItem("user");
      user = JSON.parse(decryptString(user));
    
      const fetchNotifications = async () => {
        try {
          setLoading(true);
          const response = await request.get(`notifications/${user.id_personal}`);
          setNotifications(response.result.data);
          console.log("🚀 ~ fetchNotifications ~ response:", response)
        
        } catch (err) {
          
          setError(err);
        } finally {
          setLoading(false);
        }
      };


      fetchNotifications();

      const interval = setInterval(fetchNotifications, 120000); // 2 minutos

      return () => clearInterval(interval);
    
    }, []);




 
  return (
    <nav class="navbar navbar-main navbar-expand-lg bg-transparent shadow-none position-absolute px-4 w-100 z-index-2">
    <div class="container-fluid py-0">
    
      <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none">
        <a href="javascript:;" class="nav-link text-white p-0">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line bg-white"></i>
            <i class="sidenav-toggler-line bg-white"></i>
            <i class="sidenav-toggler-line bg-white"></i>
          </div>
        </a>
      </div>
      <div
        class="collapse navbar-collapse me-md-0 me-sm-4 mt-sm-0 mt-2"
        id="navbar"
      >
        <div class="ms-md-auto pe-md-3 d-flex align-items-center">
       
          
        </div>
        <ul class="navbar-nav justify-content-between font-15">
         
          <li class="nav-item d-xl-none ps-3 pe-0 d-flex align-items-center " onClick={e=>toogleElement()}>
            <a href="#" class="nav-link text-body p-0" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white "></i>
                <i class="sidenav-toggler-line bg-white "></i>
                <i class="sidenav-toggler-line bg-white "></i>
              </div>
            </a>
          </li>
          
          <li class="nav-item dropdown pe-2 d-flex align-items-center">
            <a
              href="#"
              class="nav-link text-white p-0"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-bell cursor-pointer">
             {notifications.length>0&& <span className="notification-count">{notifications.length}</span>}
              </i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end px-2 py-3 ms-n4" aria-labelledby="dropdownMenuButton">

              {notifications.map((notification,k) =>{
              
                return(
                  <li class="mb-2" key={k}>
                    <Link
                      class="dropdown-item border-radius-md shadow"
                      to={notification.link}
                    >
                      <div class="d-flex py-1 ">
                        <div class="my-auto">
                          <img
                            src="../../../assets/img/team-2.jpg"
                            class="avatar avatar-sm me-3"
                            alt="user image"
                          />
                        </div>
                        <div class="d-flex flex-column justify-content-center ">
                          <h6 class="text-sm font-weight-normal mb-1">
                            <span class="font-weight-bold">{notification.titulo}</span>
                            <p style={{marginTop:'-5px'}}> {notification.byUser.toLowerCase()} </p>
                          </h6>
                          <p class="text-xs text-secondary mb-0">
                            <i class="fa fa-clock me-1"></i>
                            {timeago(notification.fechaCreacion)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              })}
         
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  )
}
