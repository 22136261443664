import React, { useEffect } from 'react'
import { TableInfinityFixed } from '../../../components/TableInfinityFixed'
import { db } from '../../../firebaseConfig';
import { collection, query, where,doc,updateDoc,orderBy, limit, startAfter, getDocs ,onSnapshot, serverTimestamp } from 'firebase/firestore';
export const GeneralSurvey = () => {
    const header=[
        {label:'Fecha de la encuesta'},
        {label:'Encuesta'},
        {label:'Encuestados'},
        {label:'Numero de preguntas'},
    ]
    useEffect(() => { getData() },[])

    const getData = async() =>{

// Referencia a la colección 'posts'
const postsRef = collection(db, 'posts');

    // Crea una consulta para los documentos donde el campo 'type' es igual a 1
    const q = query(postsRef, where('type', '==', 1));
    // Obtén los documentos que cumplen con la consulta
    const querySnapshot = await getDocs(q);
    // Procesa y muestra los resultados
    querySnapshot.forEach((doc) => {
        console.log(`${doc.id} =>`, doc.data());
    });
        // const q = query(collection(db, 'posts'), orderBy('timestamp', 'desc'), limit(25));
    }
  return (
    <>
        Encuestas Publicadas
        <TableInfinityFixed columnsParam={header}>

        </TableInfinityFixed>
    </>
  )
}
