import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Toast } from '../../../components/Toast'
import Request from '../../../utils/http'
const request = new Request()
   export const ModalPermisions =({user,isOpenPermision,setIsOpenPermisions,reload}) => {
  
        const [modulos, setModulos] = useState([]);
        const [icon, setIcon] = useState({ posicion: -1, valor: false });
        const [loading, setLoading]= useState(false);
        const [submodules, setSubmodules] = useState([])
        const [renderedModule,setRenderedModule] = useState(0)
        const [renderedModulePosition,setRenderedModulePosition] = useState(-1)
        const [nameModule,setNameModule]=useState('Modulos')
        const [showToastProp,setShowToast]=useState({
          showToast:false,
          labelHeader:'Éxito',
          message:'Operacion exitosa',
          type:'success'
      })
        useEffect(() => {
          user && getData();
          }, [user]);
        async function getData() {
          setModulos(user.modulos);
          
        }
        const handleSubmodules = (id,subModulos,key) => {
         setNameModule(modulos[key]['module_name'])
          setSubmodules(subModulos)
          setRenderedModule(id)
          setRenderedModulePosition(key)
        }
    
        const handleChange=(checked,position,bandera) => { 
          
          
          let copia=JSON.parse(JSON.stringify(modulos))
          if (bandera=='home') {
         
              copia[renderedModulePosition]['submodulos'].forEach(p=>{
                p.permisions.forEach(item=>{
                  item.home=0
                })
              })
              copia[renderedModulePosition]['submodulos'][position]['permisions'][0]['read_p']=1
          }
          copia[renderedModulePosition]['submodulos'][position]['permisions'][0][bandera] = checked
            if (bandera == 'write_p' && checked  ) {
              copia[renderedModulePosition]['submodulos'][position]['permisions'][0]['read_p'] = 1
            }
            setModulos(copia)
            setSubmodules(copia[renderedModulePosition]['submodulos'])
        }
    
    
        async function handleSubmit() {
        
          setLoading(true)
          let p=[]
          submodules.forEach(element => {
            p.push(element.permisions[0])
          });
          let data = {
              data:p,
              
          }
     
          const response = await request.post('users/savePermisionsUser',data)
          
        if (response && response.statusCode === 200) {
          setShowToast({...showToastProp,type:'success',showToast:true,labelHeader:'Guardado con exito!!',message:'Por favor espere'})
                
          reload()
        }else{
            setShowToast({...showToastProp,type:'danger',showToast:true,labelHeader:'Error, intentelo de nuevo',message:'Favor de notificar a sistemas'})
            
        }
        setLoading(false)
        setTimeout(() => {
            setShowToast({...showToastProp,showToast:false})
        },2000);
      }
      const handleClose=() => {
        setIsOpenPermisions(false)
        setModulos([])
        reload()
      } 
      return(
        <Modal isOpen={isOpenPermision} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} onRequestClose={handleClose}> 
        <div className="container-fluid my-3 py-3">
          <div className="row mb-5">
            <div className="col-lg-4">
              <div className="card position-sticky top-1">
                <ul className="nav flex-column bg-white border-radius-lg p-3 shadow" >
                 {modulos.map((modulo,key)=>{
                   
                    const {icon,module_name,id,submodulos}= modulo
    
                    return(
                      <li key={key} className={`nav-item pt-2 radius-15 clickeableItem ${renderedModule==id&&'active'} `} onClick={e=>handleSubmodules(id,submodulos,key)}  >
                        <a className="nav-link text-body" data-scroll href="#notifications">
                          <div className="icon me-2">
                            <i className={`${icon} text-dark`}></i>
                          </div>
                          <span className="text-sm ">{module_name}</span>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className="col-lg-8 mt-lg-0 ">
              <div className="card mt-1 shadow" id="notifications"  >
                <div className="card-header">
                  <h5>Gestionar acceso a {nameModule}</h5>
                </div>
                <div className="p-3 card-body pt-0" style={{ height:'350px',overflowY:'scroll' }}>
                  <div className="" >
                    <table className="table mb-0 table-striped  shadow  clickeable table-responsive ">
                      <thead className="shadow">
                        <tr>
                          <th className="ps-1" colspan="4">
                            Submodulo
                          </th>
                          <th className="text-center">
                            Escritura
                          </th>
                          <th className="text-center">
                            Lectura
                          </th>
                          {renderedModule==1&& 
                            <th className="text-center">
                                  Principal
                            </th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {submodules.map((submod, i) =>{
                         const {submodule,permisions}=submod;
                        
                         const read_p=permisions[0]['read_p']==0?false:true
                         const write_p=permisions[0]['write_p']==0?false:true
                         const principal_p =permisions[0]['home']==0?false:true
    
                            return(
                           
                         
                                <tr key={i} >
                                  <td className="" colspan="4">
                                    <div className="my-auto">
                                      <span className="text-dark d-block text-sm">{submodule}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                      <input className="form-check-input" onChange={e=>handleChange(e.target.checked,i,'write_p')}  checked={write_p} type="checkbox" id="flexSwitchCheckDefault12"/>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                      <input className="form-check-input" onChange={e=>handleChange(e.target.checked,i,'read_p')}  checked={read_p} type="checkbox" id="flexSwitchCheckDefault11"/>
                                    </div>
                                  </td>
                                  {renderedModule==1 && 
                                  <td>
                                    <div className="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                      <input className="form-check-input" onChange={e=>handleChange(e.target.checked,i,'home')}  checked={principal_p} type="checkbox" id="flexSwitchCheckDefault13"/>
                                    </div>
                                  </td>
                                  }
                                </tr>
                          )
                        })}
                       
                      </tbody>
                    </table>
                    
                  </div>
                </div>
              </div>
    
             
            </div>
        <div className="d-flex justify-content-end mt-2">
          <button className="btn btn-dark btn-sm" onClick={handleSubmit}><i className='fa fa-save font-15'></i> Guardar cambios en {nameModule}</button>
        </div>
          </div>
         
        </div>
          <Toast
            showToast={showToastProp.showToast}
            labelHeader={showToastProp.labelHeader}
            message={showToastProp.message}
            type={showToastProp.type}
          />
        </Modal>
      )
    }
  
