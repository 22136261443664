import React,{useState,useEffect} from 'react'

import Request from '../../utils/http'
import { SimpleModal } from '../../components/ReactModal/SimpleModal'
import { ModalButtons } from '../../components/ReactModal/ModalButtons'
import { CheckBox } from '../../components/CheckBox'
import Select from 'react-select'

import { v4 as uuidv4 } from 'uuid';
const request = new Request()
export const ModalUpdateGroup=({dataModal,url,reload}) => {
   
  const [tags,setTags]=useState([])
  const [loading,setLoading] = useState(false)
  const [addTagToSelect, setTagToSelect] = useState(false);
    const [data,setData] = useState({description:'',active:0,idgroup:0,idDepartment:0,description_puesto:'',tagsSelected:[],sueldo_base:0,moneda:'MXP'})
     useEffect(()=>{
       if (dataModal.length>0) {
         setData({...data,
          description:dataModal[0].description,
          active:dataModal[0].active,
          idgroup:dataModal[0].idgroup,
          idDepartment:dataModal[0].idDepartment,
          description_puesto: dataModal[0].description_puesto,
          sueldo_base: dataModal[0].sueldo_base,
          moneda: dataModal[0].moneda,
          tagsSelected:dataModal[0].tagsSelected&&JSON.parse(dataModal[0].tagsSelected)

        }
          )
       }
       getTags()
     },[dataModal])
    
   const handleChange=(id,value) => setData({...data,active:value==true?1:0})
   
   
     const handleSubmit=async()=>{
      setLoading(true)
      
      
     const response =await request.post(url,{
          gdescription:data.description,
          activo:data.active,
          idGroup:data.idgroup,
          idDep: data.idDepartment,
          description_puesto:data.description_puesto,
          tagsSelected:JSON.stringify(data.tagsSelected),
          moneda:data.moneda,
          sueldo_base:data.sueldo_base,

          })
        if (response && response.statusCode==200) {
            reload()
            setLoading(false)
            return 'success'
          }else{
            reload()
            setLoading(false)
            return 'error'
          }
     }
     const addTag = () => {
      setTagToSelect(true);
    };
    const closeTag=()=>{
      getTags()
      setTagToSelect(false)
    }
  
    const getTags=async()=>{
      const response = await request.get(`tags/getTagUniversal/${3}`)
    
      if (response && response.statusCode==200){
        setTags(response.result.data)
      }
    }
    const handleCloseFunction=()=>{
    
      setData({description:'',active:0,idgroup:0,idDepartment:0,description_puesto:'',tagsSelected:[],sueldo_base:0,moneda:'MXP'})
    }
     return(
       <SimpleModal identifier={'groups'} size='750' handleCloseFunction={handleCloseFunction} heightProp='600'>
     {!addTagToSelect ?   
       <>
         <div className="d-flex justify-content-between">
         <h6 className="text-md mt-3 ml-2 mb-0">Nombre del puesto</h6>
          <div style={{width:'300px'} } className='mt-3'>
          <CheckBox
             typeActive='dark' 
             id={0}
             checked={data.active==1?true:false} 
             handleChange={handleChange} 
           />
          </div>
         </div>
        <div className="row p-3">
          <div className="col-md-5">
            <h6 className='text-xs'>puesto</h6>
           <input 
             type="text" 
             name="dep" 
             id="dep" 
             className='form-control ' 
             value={data.description} 
             onChange={e=>setData({...data,description:e.target.value})} 
           />
          </div>
          <div className="col-md-3">

            <h6 className='text-xs'>sueldo base</h6>
           <input type="number" className='form-control' value={data.sueldo_base} onChange={e=>setData({...data,sueldo_base:e.target.value})}  />
          </div>
          <div className="col-md-3">

            <h6 className='text-xs'>moneda</h6>
           
           <select className='form-control' value={data.moneda} onChange={e=>setData({...data,moneda:e.target.value})} >
            <option value="MXN">MXN</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
           </select>
          </div>
        </div>
        
          
          <h6 className='text-xs ml-2'> Aptitudes / Responsabilidades</h6>
         <div className="d-flex ml-2">
              <div style={{ width: "600px" }}>
                <Select isMulti options={tags} value={data.tagsSelected} onChange={value=>setData({...data,tagsSelected:value})} />
              </div>
              <div><button className="btn bg bg-gradient-dark btn-sm ml-1" onClick={addTag}>
                <i className="fa fa-plus font-15"></i>
              </button></div>
            </div>
            <div className="p-3">
            <h6>Descripcion del puesto</h6>
            <textarea
              value={data.description_puesto}
              onChange={(e) =>
                setData({ ...data, description_puesto: e.target.value })
              }
              className="form-control"
              rows={5}
            />
          </div>
       
           <ModalButtons handleSubmit ={handleSubmit} closeOption={false} loading={loading} />
           </>
           :
           <ModalTags closeTag={closeTag}/>}
       </SimpleModal>
     )
   }
   const ModalTags = ({closeTag}) => {
    const [tag, setTag] = useState("");
    const handleSubmit = async () => {
      const response = await request.post("tags/createTagUniversal", {
        value: uuidv4(),
        label: tag,
        type: 3,
      });
      if (response && response.statusCode == 200) {
        setTag('')
        return "success";
      } else {
        return "error";
      }
    };
    return (
      <div className="p-3 input-group-sm">
        <button className="btn btn-danger btn-sm" onClick={closeTag}><i className="fa-solid fa-reply font-15"></i> Regresar</button>
        <input
          type="text"
          className="form-control mb-5"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        />
  
        <ModalButtons handleSubmit={handleSubmit} closeOption={false}  />
      </div>
    );
  };
  