import React from 'react'

export const Toast = ({showToast=false,labelHeader='Éxito',message='Operacion exitosa',type='success'}) => {
const icon = type =='success'? 'fa-regular fa-thumbs-up text-white':'fa-solid fa-triangle-exclamation text-danger text-white'
  return (
    <div className="p-3 m-3" >
      <div id="successToast" className={`toast ${showToast?'show':''} `} role="alert" aria-live="assertive" aria-atomic="true" data-delay="3000" style={{borderRadius:'15px' ,position: 'absolute', top: 2, right: 0}}>
        <div style={{borderRadius:'10px 10px 0px 0px',}} className={`toast-header bg-${type} text-white`}>
          <i className={icon}></i> &nbsp; <strong className="mr-3">{labelHeader}</strong>
        </div>
        <div className="toast-body">
            {message}
        </div>
      </div>
    </div>
  )
}
