import { collection, getDocs, addDoc, query, orderBy, where, doc, updateDoc, Timestamp, runTransaction } from 'firebase/firestore';
import { db } from '../../../firebaseConfig'; // Asegúrate de configurar Firebase correctamente

// Fetch chats involving the current user
export const fetchChats = async (userId) => {
  const chatsCollection = collection(db, 'chats');
  const q = query(chatsCollection, where('participants', 'array-contains', userId));
  const chatSnapshot = await getDocs(q);
  const chatList = chatSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  return chatList;
};

// Fetch messages for a specific chat
export const fetchMessages = async (chatId) => {
  const messagesCollection = collection(db, 'chats', chatId, 'messages');
  const q = query(messagesCollection, orderBy('timestamp', 'asc'));
  const messagesSnapshot = await getDocs(q);
  const messageList = messagesSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  return messageList;
};



export const sendMessage = async (chatId, senderId, message) => {
  const chatDocRef = doc(db, 'chats', chatId);
  const messagesCollection = collection(chatDocRef, 'messages');

  try {
    await runTransaction(db, async (transaction) => {
      // Add the new message
      const newMessageRef = await addDoc(messagesCollection, {
        sender: senderId,
        message: message,
        timestamp: Timestamp.now()
      });

      // Update the chat's lastMessage and timestamp fields
      transaction.update(chatDocRef, {
        lastMessage: message,
        timestamp: Timestamp.now()
      });

      return newMessageRef;
    });
  } catch (error) {
    console.error('Error sending message and updating chat: ', error);
  }
};

// Create a new chat
export const createChat = async (userIds,usuarioOne,usuarioTwo) => {
  const chatsCollection = collection(db, 'chats');
  const chatDoc = await addDoc(chatsCollection, {
    participants: userIds,
    nameParticipants:{participantOne:usuarioOne,participantTwo:usuarioTwo},
    lastMessage: '',
    timestamp: new Date()
  });
  return chatDoc.id;
};
