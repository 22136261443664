import React,{useState,useEffect,useRef} from 'react'
import { db } from '../../../../../firebaseConfig';
import { collection, query,orderBy, limit, startAfter, getDocs ,onSnapshot } from 'firebase/firestore';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


import { LikesSection } from './components/LikesSection';
import { CommentsSection } from './components/CommentsSection';
import { PublicationCaption } from './components/PublicationCaption';
import Request from '../../../../../utils/http';
import moment from 'moment-timezone';
import { RenderAnyOption } from './components/RenderAnyOption';
const request = new Request()
export const Publications = ({useDataInfo}) => {

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const initialLoad = useRef(true);

  // useEffect(() => {
  // }, []);
  
  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      listenForNewPosts();
    }else{
      
      fetchInitialPosts();
    }
  }, []);


  const fetchInitialPosts = async() => {
   
    setLoading(true);
  
    // Crear la consulta
    const q = query(collection(db, 'posts'), orderBy('timestamp', 'desc'), limit(50));
   
  
    // Escuchar los cambios en tiempo real
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const postsArray = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    
      setPosts(postsArray);
  
      // Obtener el último documento visible
      const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastVisibleDoc);
  
      setLoading(false);
    }, (error) => {
      console.error("Error fetching posts: ", error);
      setLoading(false);
    });
  
    // Devuelve la función de cancelación de la suscripción
    return unsubscribe;
  };
  const fetchMorePosts = async () => {
    if (!hasMore || loading) return;

    setLoading(true);
    const q = query(
      collection(db, 'posts'),
      orderBy('timestamp', 'desc'),
      startAfter(lastVisible),
      limit(5)
    );
    const documentSnapshots = await getDocs(q);

    if (documentSnapshots.docs.length < 5) {
      setHasMore(false);
    }

    const lastVisibleDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    setLastVisible(lastVisibleDoc);

    const morePosts = documentSnapshots.docs.map(doc => ({ ...doc.data(), id: doc.id }));

    setPosts(prevPosts => [...prevPosts, ...morePosts]);
    setLoading(false);
  };

  const listenForNewPosts = () => {
    const q = query(collection(db, 'posts'), orderBy('timestamp', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newPosts = [];
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          newPosts.push({ ...change.doc.data(), id: change.doc.id });
        }
      });
     
      
      // Evitar duplicados
      setPosts(prevPosts => {
        const postIds = new Set(prevPosts.map(post => post.id));
        const nonDuplicatePosts = newPosts.filter(post => !postIds.has(post.id));
        
       
        return [...nonDuplicatePosts, ...prevPosts];
      });
    });

    return () => unsubscribe();
  };

  const lastPostElementRef = (node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        fetchMorePosts();
      }
    });
    if (node) observer.current.observe(node);
  };

  return (
    <div className="row mt-4 padding-movile">
      {posts.map((post,index) =>{
       
        return(

          <div className=" col-md-12 d-flex justify-content-center row">
            <Publication key={index} quantity={posts.length} lastPostElementRef={lastPostElementRef} post={post} useDataInfo={useDataInfo} />
          </div>
        )
      })

      }
    </div>
  )
}
const Publication=({index,quantity,lastPostElementRef,post,useDataInfo}) => {


  let fecha = post.timestamp?post.timestamp.toDate():moment().format('DD/MM/YYYY HH:mm:ss');
  const urlImg="../../../assets/img/team-4.jpg"
  const [showComents,setShowComents]=useState(false)
 const [countComments,setcountComments]=useState(0)

 

  useEffect(()=>{
    setcountComments(post.likes.comments.length)
  },[post])

  return(
   <CardContainer index={index} quantity={quantity} lastPostElementRef={lastPostElementRef}>
     
     <CardHeader>
        {/* <img src={urlImg} className="avatar" alt="profile-image"  /> */}
        <i className='fa fa-user font-25'></i>
        <div className="d-flex justify-content-between">
        <div className="mx-3">
            <h6 className="text-dark font-weight-600 font-10" >
              {post.postedBy}
            </h6>
            <small className="d-block font-10 text-muted">{fecha.toLocaleString()}</small>
          </div>
       
          <div className="text-end ms-auto input-group-sm input-group-xl">
              {post.userId ==useDataInfo.id_personal &&<RenderAnyOption post={post} />}
          </div>
        </div>
     </CardHeader>
      
      <CardBody>
        <PublicationCaption post={post}  userDataInfo={useDataInfo} />
        <LikesSection userDataInfo={useDataInfo} setShowComents={setShowComents} likes={post} showComents={showComents} countComments={countComments} />
       {showComents && <CommentsSection post={post} useDataInfo={useDataInfo} />}
      </CardBody>
    </CardContainer>
  )
}

const CardContainer=({children,index,quantity,lastPostElementRef})=>(
  <div className="col-xs-12 col-sm-12  col-md-12 col-lg-9 mt-1 mb-2"   ref={index === quantity - 1 ? lastPostElementRef : null}>
    <div className="card ">
      {children}
    </div>
  </div>
)
const CardHeader=({children})=>(
  <div className="card-header d-flex align-items-center border-bottom py-3">
      <div className="d-flex align-items-center">
          {children}
      </div>
  </div>

)
const CardBody=({children})=>(<div className="card-body shadow">{children}</div>)




