import React, { useEffect, useState } from "react";
import { VacationCalendar } from "../../../components/VacationCalendar";


export const DisponibleDays = ({ id }) => {
  const [rendered, setRendered] = useState(null);
  const [yearsArray,setYears]=useState([])
  const [currentyear,setCurrentYear]=useState('2022')

  useEffect(() => {
    setRendered(1);
     getYears()
  }, []);


  const getYears = () =>{
    const currentYear = new Date().getFullYear();
    const years = [currentYear - 2, currentYear - 1, currentYear, currentYear + 1];
    setCurrentYear(currentYear)
    setYears(years);
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
         <div className="mt-3">
            <span style={{backgroundColor:'#5278FA',borderRadius:'50px',padding:'5px',marginLeft:'5px',marginTop:'5px',color:'white'}} className="color-white">Disponibles para todo el personal</span>
            <span style={{backgroundColor:'#FC8F5F',borderRadius:'50px',padding:'5px',marginLeft:'5px',marginTop:'5px',color:'white'}} className="color-white">Dia festivo</span>
            <span style={{backgroundColor:'#FA5A52',borderRadius:'50px',padding:'5px',marginLeft:'5px',marginTop:'5px',color:'white'}} className="color-white">Dia no disponible</span>
            <span style={{backgroundColor:'#99A500',borderRadius:'50px',padding:'5px',marginLeft:'5px',marginTop:'5px',color:'white'}} className="color-white">Dia sujeto a autorización</span>
         </div>
          <select className="form-control mt-3" value={currentyear} onChange={e=>setCurrentYear(e.target.value)} style={{width: '200px'}}>
            {yearsArray.map((year,idx) =>  <option key={idx} value={year}>{year}</option> )}
          </select>
      </div>


      {rendered == 1 && (
        <div className="shadow" style={{ height:'630px',overflowY:'scroll'}}>
          <div className="d-flex ">
              
              <div style={{width: '33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-01-01`} />
              </div>
              <div style={{width: '33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-02-01`} />
              </div>
              <div style={{width: '33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-03-01`} />
              </div>
            
          </div>
          <div className="d-flex">
            
              <div style={{width:'33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-04-01`} />
              </div>
              <div style={{width:'33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-05-01`} />
              </div>
              <div style={{width:'33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-06-01`} />
              </div>
            
          </div>
          <div className="d-flex">
            
              <div style={{width:'33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-07-01`} />
              </div>
              <div style={{width:'33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-08-01`} />
              </div>
              <div style={{width:'33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-09-01`} />
              </div>
    
          </div>
          <div className="d-flex">
          
              <div style={{width:'33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-10-01`} />
              </div>
              <div style={{width:'33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-11-01`} />
              </div>
              <div style={{width:'33%'}}>
                  <VacationCalendar defaultDateParam={`${currentyear}-12-01`} />
              </div>
            
          </div>
        </div>
      )}
    </>
  );
};
