import React, { useState, useEffect, Fragment } from "react";
import { Layout } from "../Layouts/Layout";
import { TableInfinityFixed } from "../../components/TableInfinityFixed";
import CSVDownloadButton from "../../components/CSVDownloadButton";
import { SearchBar } from "./components/SearchBar";
import { MapUbications } from "./components/MapUbications";
import { LoadingData } from "../../components/LoadingData";
import { SimpleModal } from "../../components/ReactModal/SimpleModal";
import moment from "moment-timezone";
const header = [{ label: "Colaborador" }, { label: "checkings" }];

export const CheckingTimeUsers = () => {
  const [latitudesUser, setLatitudesUsers] = useState([]);
  const [latitudesUserExcel, setLatitudesUsersExcel] = useState([]);
  const [latitudesUserMap, setLatitudesUsersMap] = useState("");
  const [orarios,setorarios] = useState({in:'',out:''})
  const [loading, setLoading] = useState(false);
  const [coords, setCoords] = useState({
    in: [],
    out: [],
  });

  const handleOpen = async (position) => {
    let copy = JSON.parse(JSON.stringify(latitudesUser));
    copy[position]["open"] = !copy[position]["open"];
    setLatitudesUsers(copy);
  };
  const handleClick = (coords) => {
    setorarios({in:coords.time,out:coords.timeout});
    let coord = [];
    if (coords.latitudeOut != null) {
      setCoords({
        in: [coords.latitude, coords.longitude],
        out: [coords.latitudeOut, coords.longitudeOut],
      });
      coord = [
        { lat: coords.latitude, lng: coords.longitude },
        { lat: coords.latitudeOut, lng: coords.longitudeOut },
      ];
    } else {
      setCoords({
        in: [coords.latitude, coords.longitude],
        out: [0, 0],
      });
      coord = [
        { lat: 0, lng: 0 },
        { lat: 0, lng: 0 },
      ];
    }
    coord = [
      { lat: coords.latitude, lng: coords.longitude },
      { lat: coords.latitudeOut, lng: coords.longitudeOut },
    ];
    const mapURL = `https://maps.google.com/maps?q=${coord
      .map((loc) => `${loc.lat},${loc.lng}`)
      .join("&markers=")}&z=15&output=embed`;

    setLatitudesUsersMap(mapURL);
  };

  return (
    <>
      <Layout>
        <SearchBar {...{ setLatitudesUsers, setLoading ,setLatitudesUsersExcel }} />
        <CSVDownloadButton
          csvData={latitudesUserExcel}
          buttonText="exportar"
          filename="locations.csv"
          className="btn btn-success"
        />
        <ModalUbications {...{ coords ,orarios}} />
        
        <div className="row">
          <div className="col-md-12 mt-2 col-lg-12 ">
            <TableInfinityFixed
              columnsParam={header}
              aditionalClass="table-striped clickeable"
            >
              {latitudesUser.map((lat, i) => {
                return (
                  <Fragment key={i}>
                    <tr onClick={(e) => handleOpen(i)}>
                      <td>{lat.colaborator}</td>
                      <td>{lat.ubications.length}</td>
                    </tr>
                    {lat.open && (
                      <tr>
                        <td colSpan={2}>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th
                                  className="text-white"
                                  style={{ backgroundColor: "#105512e6" }}
                                >
                                  Lugar
                                </th>
                                <th
                                  className="text-white"
                                  style={{ backgroundColor: "#105512e6" }}
                                >
                                  Entrada
                                </th>
                                <th
                                  className="text-white"
                                  style={{ backgroundColor: "#105512e6" }}
                                >
                                  salida
                                </th>
                                <th
                                  className="text-white"
                                  style={{ backgroundColor: "#105512e6" }}
                                >
                                  Comentarios
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {lat.ubications.map((coords, h) => {
                                let ubicacion = JSON.parse(coords.location);
                                return (
                                  <tr
                                    onClick={(e) => handleClick(coords)}
                                    data-bs-toggle="modal"
                                    data-bs-target={`#modalubications`}
                                    key={h}
                                  >
                                    <td>{ubicacion.label}</td>
                                    <td className="  font-12">
                                      {coords.time}
                                    </td>
                                    <td className="  font-12">
                                      {coords.timeout}
                                    </td>
                                    <td>
                                      <textarea
                                        value={coords.comments}
                                        cols="30"
                                        rows="2"
                                        className="form-control"
                                        disabled
                                      ></textarea>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
              <LoadingData
                loading={loading}
                colspan={2}
                quantity={latitudesUser.length}
              />
            </TableInfinityFixed>
          </div>
        </div>
      </Layout>
    </>
  );
};

const ModalUbications = ({ coords,orarios }) => {
  return (
    <SimpleModal  identifier={"modalubications"}>
      <div className="row p-3">
        <div className="col-md-6 p-3">
        <h3>Entrada</h3>
        <h5>{moment(orarios.in).format('DD/MM/YYYY HH:mm:ss')}</h5>
          <iframe
            src={`https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3722.539874882029!2d${coords.in[1]}!3d${coords.in[0]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDA1JzI3LjciTiA4NsKwNTEnMjIuNSJX!5e0!3m2!1ses-419!2smx!4v1702092523273!5m2!1ses-419!2smx`}
           
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="col-md-6 p-3">
            <h3>Salida</h3>
            <h5>{moment(orarios.out).format('DD/MM/YYYY HH:mm:ss')}</h5>
      <iframe
        src={`https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3722.539874882029!2d${coords.out[1]}!3d${coords.out[0]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDA1JzI3LjciTiA4NsKwNTEnMjIuNSJX!5e0!3m2!1ses-419!2smx!4v1702092523273!5m2!1ses-419!2smx`}
       
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

        </div>
      </div>

    </SimpleModal>
  );
};
