import React, { Fragment, useEffect, useState } from "react";
import { UploadDocument } from "../../../../../../components/UploadDocuments";
import { TableInfinityFixed } from "../../../../../../components/TableInfinityFixed";
import Request from "../../../../../../utils/http";
const request = new Request();
const header = [
  { classColumn: "ocultarmobile", label: "Tipo de Comprobante" },
  { classColumn: "ocultarmobile", label: "Documento" },
];
export const TableUploads = ({ user }) => {
  const [docs, setDocs] = useState([]);
  const types = [
    { typeDoc: "32", label: "Recibo de pago de finiquito", url: "" },
    { typeDoc: "33", label: "Documento de renuncia/despido", url: "" },
    { typeDoc: "34", label: "Constancia laboral", url: "" },
    { typeDoc: "35", label: "Entrevista de salida", url: "" },
    { typeDoc: "36", label: "Calculo de finiquito", url: "" },
    { typeDoc: "37", label: "Recibo de nomina de finiquito", url: "" },
  ];
  const getData = async () => {
    let copy = JSON.parse(JSON.stringify(types));
    const response = await request.get(`getDocuments/${user.id}`);

    if (response && response.statusCode === 200) {
      response.result.data.forEach((element) => {
        copy.forEach((cop) => {
          cop.show = false;
          if (cop.typeDoc == element.type) {
            cop.url = element.file_url;
          }
        });
      });
    }
    setDocs(copy);
  };
  

  const showMore = (position) => {
    const copy = JSON.parse(JSON.stringify(docs));

    copy[position]["show"] = !docs[position]["show"];
    setDocs(copy);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="col-md-12 p-3 mt-3" style={{ height: "400px", overflowY: "hidden" }}>
      <h2>Documentacion</h2>
      <TableInfinityFixed
        columnsParam={header}
        aditionalClass="table-striped shadow "
      >
        {docs.map((type, i) => {
          return (
            <Fragment key={i}>
              <tr>
                <td className="d-flex justify-content-between">
                  <h6 className="text-xs">{type.label}</h6>
                  <button
                    className="btn btn-dark btn-sm p-1 buttonMovile rounded-circle"
                    onClick={(e) => showMore(i)}
                  >
                    <i
                      className={`fa-solid fa-angle-${
                        type.show ? "down" : "right"
                      } font-15`}
                    ></i>
                  </button>
                </td>
                <td className="ocultarmobile p-0">
                  <UploadDocument
                    typeDoc={type.typeDoc}
                    url={type.url}
                    urlPost='uploadDocumentation'
                    user={user}
                    reload={getData}
                    typeprop={type}
                  />
                </td>
              </tr>
              {type.show && (
                <tr>
                  <td className="pt-3 d-flex input-group-sm justify-content-between">
                    <UploadDocument
                      typeDoc={type.typeDoc}
                      url={type.url}
                      user={user}
                      reload={getData}
                      typeprop={type}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </TableInfinityFixed>
    </div>
  );
};
