import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { TableInfinityFixed } from '../../../../../components/TableInfinityFixed'
import { formatDate } from '../../../../../Hooks/formatDates'

export const ModalInasistences = ({data,isOpen,setIsOpen,type,colaborador}) => {
  const [lineas,setLineas]=useState([])
  const [justificadas,setJustificadas]=useState(0)
  const [nojustificadas,setNoJustificadas]=useState(0)
 
  const header=[
    {label:'Fecha'},
    {label:'justificada'},
    {label:'Justificante'},
  ]

  useEffect(() => {
    if (type==3) {
      setLineas(data)
      let totalFaltas=0
      let totalFaltasNoJustificadas=0
      data.forEach(element => {
       
         let isFin=esFinDeSemana(element.fecha)
         if (!isFin) {
          
           if (element.justificantes.length>0) {
             totalFaltas++
            
           }else{
            totalFaltasNoJustificadas++
           }
         }
        
      });
      setJustificadas(totalFaltas)
      setNoJustificadas(totalFaltasNoJustificadas)
     
    }
  },[type])
  function esFinDeSemana(fecha) {
    // Convertir la fecha a un objeto Date
    let date = new Date(fecha);

    // Obtener el día de la semana (0 = Domingo, 6 = Sábado)
    let diaSemana = date.getDay();

    // Verificar si es sábado o domingo
    return diaSemana === 5 || diaSemana === 6;
}
  return (
      <Modal isOpen={isOpen} onRequestClose={e=>setIsOpen(false)} shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true} >
    <div className="text-center">
      <h4 className='text-danger'>{colaborador.rango}</h4>
      <h4>{colaborador.nombre}</h4>
      <h4>Sin justificar:{nojustificadas} </h4>
      <h4>Justificadas:{justificadas}</h4>
    </div>
         <TableInfinityFixed columnsParam={header}>
            {lineas.map((line,k)=> {
                const {fecha,justificantes} = line
                let isFinDeSemana =esFinDeSemana(fecha)
                let file = justificantes.length> 0?justificantes[0].file_url:''
             
              return(
                <tr key={k} >
                  <td> {formatDate(fecha)}</td>
                  <td>{isFinDeSemana?'Fin de semana':justificantes.length> 0?'Se justifico la falta del colaborador':'Falta no justficada'}</td>
                  <td>{file=='no_file'?<strong className="text-dark">Justificada sin archivo</strong>:file!=''&&<a href={file} className='btn btn-dark' target='_blank'><i className='fa fa-link'></i></a>}</td>
                </tr>
              )
            })}
         </TableInfinityFixed>
    </Modal>
  )
}
