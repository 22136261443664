import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import Modal from "react-modal";
import Request from "../../utils/http";
import esLocale from "@fullcalendar/core/locales/es";
// import { Alert } from "../Alerts/Alert";
import { sustractDays } from "../../Hooks/sustractDays";
const request = new Request();

export const VacationCalendar = ({ defaultDateParam }) => {
  const [openModal, setOpenModal] = useState(false);
  const [dataArguments, setDataArguments] = useState(null);
  const [defaultDate, setDefaultDate] = useState(null);
  const [data, setData] = useState([]);
  const [arg, setArg] = useState(null);

  const handleMouseOver = (info) => {
    // alert("¡El mouse está sobre el elemento!");
    // Ejecuta acciones adicionales aquí
  };
  async function getDaily(argumentos) {
    
    setArg(argumentos);
    let datestart = moment(argumentos.startStr).format('YYYY-MM-DD')
    let dateend =   moment(argumentos.endStr).format('YYYY-MM-DD')

    const response = await request.get(`vacationEvents/getEvents?start=${datestart}&end=${dateend}`)
    if (response && response.statusCode === 200) {
      let lineas=[]

      response.result.data.forEach(element => {
        lineas.push({
          title:element.type==3||element.type==5?element.identifier:'',
          start:element.start,
          end:sustractDays(element.end,'suma',1),
          backgroundColor:element.color,
          type:element.type,
          info:element
         })
      
      });
      setData(lineas)
    }
  
  }

  const handleDateSelect = (selectInfo) => {
    setOpenModal(true);
    setDataArguments(selectInfo);
  };
  useEffect(() => {
    setDefaultDate(null);
    changeYear(defaultDateParam);
  }, [defaultDateParam]);
  const changeYear = (defaultDateParam) => {
    setTimeout(() => {
      setDefaultDate(defaultDateParam);
    }, 200);
  };
  const reload=async()=>{
    getDaily(arg)
  }
  const handleEvents=async(info) => {
    let copy = info.event.extendedProps.info 
    copy.endStr=copy.end
    copy.startStr=copy.start
    setOpenModal(true)
    setDataArguments( copy)
   
   
  }
  return (
    <>

      <ModalDateRange {...{ openModal, setOpenModal, dataArguments ,reload}} />
      {defaultDate != null && (
        <FullCalendar
          showNonCurrentDates={false}
          headerToolbar={{ start: "title", center: "", end: false }}
          viewClassNames={"shadow"}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          display="list-item"
          editable={true}
          selectable={true}
          selectMirror={false}
          // className={'vacationCalendar'} 
          initialDate={defaultDate}
          locale={esLocale}
          height={600}
          events={data}
          // dayMaxEvents={1}
          eventMouseEnter={handleMouseOver}
          select={handleDateSelect}
          datesSet={(args) => getDaily(args)}
            eventClick={(info)=>handleEvents(info)}
        />
        )}
    </>
  );
};
//   dateClick={(args)=>handleDateClick(args)}


const ModalDateRange = ({ openModal, setOpenModal, dataArguments,reload }) => {
 
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "-35%",
      width: "23%",
      height: "98%",
      transform: "translate(-50%, -50%)",
    },
  };
  const [evento, setEvento] = useState({
    type: 0,
    start: "",
    end: "",
    color: "",
    identificador: "",
    id:0
  });
  useEffect(() => {

    if(dataArguments != null) {
      let end = sustractDays(dataArguments.endStr,'resta',1)
      setEvento({
        id: dataArguments.id,
        type: dataArguments.type,
        start: dataArguments.startStr,
        end: end,
        color: dataArguments.color,
        identificador:dataArguments.identifier,

      });
    }

     

  }, [dataArguments]);

  const handleChange = (dataArguments) => {
    switch (dataArguments) {
      case "0":
        setEvento({ ...evento, type: 0, color: "#F5D626", identificador: "" });
        break;
      case "1":
        setEvento({
          ...evento,
          type: 1,
          color: "#2626F5",
          identificador: "Disponibles",
        });
        break;
      case "2":
        setEvento({
          ...evento,
          type: 2,
          color: "#26F529",
          identificador: "Disponibles por antiguedad",
        });
        break;
      case "3":
        setEvento({
          ...evento,
          type: 3,
          color: "#FC8F5F",
          identificador: "Dia festivo",
        });
        break;
      case "4":
        setEvento({
          ...evento,
          type: 4,
          color: "#FA5A52",
          identificador: "Dias no disponible",
        });
        break;
        
      default:
        setEvento({
          ...evento,
          type: 5,
          color: "#99A500",
          identificador: "Dias sujetos a autorización",
        });
        break;
    }
  };
  const handleSubmit = async () => {
    const response = await request.post("calendarVacation/create", evento);
    if (response && response.statusCode == 200) {
      // Alert("success", "Guardado con exito");
      reload()
    }
  };
  const handleDelete =async()=>{
    const response = await request.delete(`calendarVacation/delete?id=${evento.id}`);
    if (response && response.statusCode == 200) {
      // Alert("success", "eliminado con exito");
      reload()
    }
  }
  return (
    <Modal isOpen={openModal} shouldCloseOnEsc={true} onRequestClose={(e) => setOpenModal(false)} shouldCloseOnOverlayClick={true} style={customStyles} >
        <div>
            <label>Tipo de selección</label>
                <select className="form-control mb-4" value={evento.type} onChange={(e) => handleChange(e.target.value)}>
                  <option value="0">Selecciona una opción</option>
                  <option value="1">Seleccionable todo personal</option>
                  <option value="2">Seleccionable personal +5</option>
                  <option value="3">Dia festivo</option>
                  <option value="4">Dias no disponibles</option>
                  <option value="5">Dias sujetos a consideracion de Direccion general</option>
                </select>
              <label>Fecha de inicio</label>
              <input type="date" className="form-control mb-4" onChange={(e) => setEvento({ ...evento, start: e.target.value }) } value={evento.start} />
              <label>Fecha de fin</label>
              <input type="date" className="form-control mb-4" onChange={(e) => setEvento({ ...evento, end: e.target.value }) } value={evento.end} />
              <label>Identificador</label>
              <input type="text" className="form-control mb-4" value={evento.identificador} onChange={(e) => setEvento({ ...evento, identificador: e.target.value }) } />
              <input type="color" id="colorPicker" name="colorPicker" value={evento.color} disabled className="form-control" />
            
            <div className="mt-3 d-flex justify-content-end">
              <button className="btn btn-dark" disabled={evento.type == 0 ? true : false} onClick={handleSubmit}> <i className="fa fa-save"></i> Guardar  </button>
            </div>
        </div>
        {evento.id > 0 && (
          <div className="mt-5">
              <button className="btn btn-danger mt-4" onClick={handleDelete}><i class="fa fa-trash "></i> Eliminar periodo</button>
          </div>
        )}
    </Modal>
  );
};
