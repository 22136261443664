import React, { useEffect, useState } from 'react'
import Request from '../../../../utils/http'
import { Toast } from '../../../../components/Toast'
const request = new Request()
export const PersonalContract = ({user,setUser}) => {
     const [data,setData]=useState({
          id:0,
          actualContract:'',
        
     })
     const [showToastProp,setShowToast]=useState({
          showToast:false,
          labelHeader:'Éxito',
          message:'Operacion exitosa',
          type:'success'
      })
     const [contratos,setContratos]= useState([
          {tipo:'Prueba',inicio:'',termino:''},
          {tipo:'Capacitacion',inicio:'',termino:''},
          {tipo:'Evaluacion',inicio:'',termino:''},
          {tipo:'Indeterminado',inicio:'',termino:''}
        ])
     const handleContract = (posicion, date, bandera) => {
         
          let copia = JSON.parse(JSON.stringify(contratos));
          copia[posicion][bandera] = date;
          
          if (bandera == "termino" ) {
            copia[posicion]['dateContractFinish'] = date
           
          }
       


          setContratos(copia);
          bandera == "termino" && setData({ ...data, dateContractFinish: date });
        };
    

        useEffect(()=>{
        
          if (user!=null) {
              let copy = JSON.parse(JSON.stringify(user))
             let contract= JSON.parse(copy.contracts)
           
             setContratos(contract.length>0?contract:contratos);
              setData(copy)
          }
         
      },[user])
      const handleData=(event)=>{
         
          setData({...data,[event.target.name]:event.target.value})
      }
      const handleSubmit =async()=>{
          const copy = JSON.parse(JSON.stringify(data))
         
          copy.contracts = JSON.stringify(contratos)
        

               let response=''
               if (copy.id==0) {
               response = await request.post('user/createUser',copy)
               
               }else{
               response = await request.put(`user/updateColaborator/${data.id}`,copy)
               }
               
               if (response && response.statusCode==200) {
               setShowToast({...showToastProp,type:'success',showToast:true,message:'Guardado con exito!!'})
               
               }else{
               setShowToast({...showToastProp,type:'danger',showToast:true,labelHeader:'Error, intentelo de nuevo',message:'Favor de notificar a sistemas'})
               
               }
               setTimeout(() => {
               setShowToast({...showToastProp,showToast:false})
               
               }, 2000);

      }
  return (
    <>
  
     <div className="col-md-12 p-3 d-flex justify-content-between row mt-4 p-1" >
             
               <label style={{color: 'red'}}>Contrato Actual </label>
           <div className="justify-end col-md-12 mb-2 input-group-sm ">
                <select value={data.actualContract} onChange={handleData} name='actualContract'  className="form-control" style={{width:'300px'}}>
                    <option value="Prueba">Prueba</option>
                    <option value="Capacitacion">Capacitacion</option>
                    <option value="Evaluacion">Evaluacion</option>
                    <option value="Indeterminado">Indeterminado</option>
                </select>
           </div>
            {contratos.map((c,i) => {
                return(
                <div className='col-md-6 col-lg-5 col-xxl-3 col-xl-6 col-lg-3 row' key={i}>
                   <div className='col-md-12'> 
                        <h5 type="text">{c.tipo}</h5>
                    </div>
                   <div className='col-md-6 input-group-sm'>
                        <label >Inicio</label>
                        <input type="date" disabled={data.actualContract!=c.tipo} className='form-control' value={c.inicio} onChange={e=>handleContract(i,e.target.value,'inicio')}/>
                   </div>
                   <div className='col-md-6 input-group-sm'>
                        <label >Fin</label>
                        <input type="date" disabled={data.actualContract!=c.tipo} className='form-control'  value={c.termino} onChange={e=>handleContract(i,e.target.value,'termino')}/>
                   </div>
                </div>
                )
            })}
            </div>
            <div className='d-flex justify-content-end p-3'>
               <button className='btn btn-success btn-sm' onClick={handleSubmit}> <i className="fa fa-save font-15"></i> Guardar contrato</button>
            </div>
            <Toast 
               showToast={showToastProp.showToast}
               labelHeader={showToastProp.labelHeader}
               message={showToastProp.message}
               type={showToastProp.type}     
            />

    </>
  )
}
