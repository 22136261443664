import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { TableInfinityFixed } from '../../../../../components/TableInfinityFixed'
import { formatDate } from '../../../../../Hooks/formatDates'
import { buscarPorId } from '../../../../../Hooks/buscarEnObjeto'
import { calcularDiferenciaHoras, sumarHoras } from '../../../../../Hooks/timeTransform'
import moment from 'moment-timezone'
export const ModalRetardos = ({data,isOpen,setIsOpen,type,colaborador}) => {
  
  const [lineas,setLineas]=useState([])
  const [horasParam,setHoras]=useState([])

  const header=[
    {label:'Fecha'},
    {label:'Tolerancia'},
    {label:'Entrada'},
    {label:'Tipo'},
    {label:'Diferencia retardo'},
]
const isRetardo=(hora) => {
    
  let horaLimite = moment('08:55:00 AM', 'hh:mm:ss A');
      let horaLimiteString = horaLimite.format('HH:mm:ss');
      
      let entradaStatus = ''
      
      let entrada=moment(hora).format('HH:mm:ss')
      
      if (entrada>horaLimiteString) {
          entradaStatus ="Retardo"
          
          } else if (entrada==horaLimiteString) {
          entradaStatus ="Puntual"
              
          } else {
          entradaStatus ="Temprano"
      }

      
      return entradaStatus;
      
}
useEffect(() => {
if (type==2) {
  let horas=[]
  setLineas(data)
  data.forEach(element => {
    let entrada  = buscarPorId(1, element.justificantes,'type')
    let isretardo = entrada ? isRetardo(entrada.time):''
    let diffRetardo = isretardo=='Retardo' ? calcularDiferenciaHoras('08:55:59',moment(entrada?.time).format('HH:mm:ss')):''
    horas.push(diffRetardo)
  });
  setHoras(horas)
}
},[type,data])
const calularData=(diferencias)=> {
     
  return sumarHoras(diferencias)
}


  return (
    <Modal isOpen={isOpen} onRequestClose={e=>setIsOpen(false)} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
 <div className="text-center">
                <h4>{colaborador.nombre}</h4>
                <h4>{colaborador.rango}</h4>
                <h4>{horasParam.length>0?calularData(horasParam):''}</h4>
            </div>
        <TableInfinityFixed columnsParam={header}>
          {lineas?.map((line,key)=>{
              const {fecha, justificantes} = line
              let entrada  = buscarPorId(1, justificantes,'type')
              let isretardo = entrada ? isRetardo(entrada.time):''
              let diffRetardo = isretardo=='Retardo' ? calcularDiferenciaHoras('08:55:59',moment(entrada?.time).format('HH:mm:ss')):''
              return(
                <tr key={key}>
                  <td>
                  {formatDate(fecha)}
                  </td>
                  <td className='text-danger'>
                    {'08:55:00'}
                  </td>
                  <td>{moment(entrada?.time).format('HH:mm:ss')}</td>
                  <td className='text-danger'>
                   {isretardo}
                  </td>
                  <td className='text-danger'>
                   {diffRetardo}
                  </td>
                </tr>

              )
          })}
        </TableInfinityFixed>
        
    </Modal>
  )
}
