export function calcularDistanciaEnMetros(lat1, lon1, lat2, lon2) {
    // Radio de la Tierra en kilómetros
    const radioTierra = 6371;
  
    // Convertir las coordenadas de grados a radianes
    const latitud1Rad = toRadians(lat1);
    const longitud1Rad = toRadians(lon1);
    const latitud2Rad = toRadians(lat2);
    const longitud2Rad = toRadians(lon2);
  
    // Diferencias en las coordenadas
    const dLat = latitud2Rad - latitud1Rad;
    const dLon = longitud2Rad - longitud1Rad;
  
    // Fórmula de Haversine para calcular la distancia
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(latitud1Rad) * Math.cos(latitud2Rad) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
  
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    // Distancia en kilómetros
    const distanciaEnKilometros = radioTierra * c;
  
    // Convertir a metros
    const distanciaEnMetros = distanciaEnKilometros * 1000;
  
    return distanciaEnMetros;
  }
  
  function toRadians(grados) {
    return grados * (Math.PI / 180);
  }
  
  // Ejemplo de uso
//   const distancia = calcularDistanciaEnMetros(37.7749, -122.4194, 34.0522, -118.2437);
//   console.log('La distancia es: ' + distancia + ' metros');
  