import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { SimpleModal } from '../../../../components/ReactModal/SimpleModal'
import Request from '../../../../utils/http'
import { ModalButtons } from '../../../../components/ReactModal/ModalButtons'
const request = new Request()
export const CompanyInformation = ({data,setData,department,tags, getDataAditional}) => {
    
    const [tagsBoss, setTagsBoss] = useState([])
    
    const [antiguedad,setAntiguedad] = useState('')
    const [emailValidator,setEmailValidator] = useState({
        email:false,
        emailCompany:false,
    })
   
    const handleData=(e)=> { setData({...data,[e.target.name]:e.target.value}) }

    const  validarEmail=(email,bandera)=> {
        // Expresión regular para validar direcciones de correo electrónico
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        setEmailValidator({...emailValidator,[bandera]:!regex.test(email)})
        setData({...data,[bandera]:email})
    }

    
    const handleDataSelect=(value,label)=>{setData({...data,[label]:value})}
    
    const reload=()=> getDataAditional(1)
    
            // if (response && response.statusCode === 200) {
            //     setTagsBoss(response.result.tagsBoss)
            // }
    
    const getTags =async ()=>{
        const response = await request.get(`personalHana/allData/${1}`)
        let tags=[]
        response.result.p.forEach(element => {
          tags.push({
            label: `${element.name} ${element.lastName}`,
            value: element.id
          })
        });
        setTagsBoss(tags)
    }
    useEffect(() => {
        getTags()
    },[])
   

  
    useEffect(() => {
    
        let ing =new Date(data.ingreso).getTime();
        let todayAnt =new Date().getTime();
        var diffAnt =( todayAnt - ing)/(1000*60*60*24).toFixed(2);
        let anniosAnt =(diffAnt/365 ).toFixed(2)
        let arrAnt= anniosAnt.split('.')
        let mesesAnt= ((arrAnt[1]/100)*365)/30
 setAntiguedad(`${arrAnt[0]} años ${Math.round(mesesAnt)} meses`)

},[data.ingreso])
  return (
    <>
    <ModalPuesto />
    <ModalJefeInmediato reload={reload} getTags={getTags} />
      <div className=" row  p-1">
                <div className="col-md-4 input-group-sm">
                    <label>Empresa</label>
                   <select name="empresa" value={data.empresa ||'Humantalen'} onChange={handleData} className="form-control">
                    <option value="0"> Selecciona una opcion</option>
                   
                    <option value="Avaluosygestoriapdcsc"> Avaluos y Gestoria PDC S.C</option>
                    <option value="RHR83SC"> RHR 83 S.C</option>
                    <option value="RHRVIVIENDASC"> RHR Vivienda S.C</option>
                   </select>
                </div>
              
                <div className="col-md-4 input-group-sm">
                    <label>Fecha de Ingreso</label>
                    <input type="date" className={` form-control ${data.ingreso==''?'border-danger':''}`} onChange={handleData} name='ingreso' value={data.ingreso || ''}/>
                </div>
                <div className="col-md-4 input-group-sm">
                    <label>Antiguedad</label>
                    <input type="text" className='form-control' disabled onChange={handleData} name='antiquity' value={antiguedad || ''}/>
                </div>
                <div className="col-md-5 col-lg-6 input-group-sm">
                    <label>Jefe Inmediato</label>
                    <div className="d-flex">
                        <div style={{width:'400px'}}>
                            <Select options={tagsBoss}  value={data.inmBoss} onChange={value=>handleDataSelect(value,'inmBoss')}/>
                        </div>
                    <div>
                        <button data-bs-toggle="modal" data-bs-target={`#modalJefeInmediato`} className='btn bg-gradient-dark p-2  btn-sm ml-2'><i className="font-15 fa fa-plus"></i></button>
                    </div>
                   </div>
                  
                </div>
                <div className="col-md-3 col-lg-3 input-group-sm">
                    <label>Departamento</label>
                    <input type="text" className="form-control" defaultValue={data.wArea} disabled/>
                    {/* <Select options={department} value={data.wArea} onChange={value=>handleDataSelect(value,'wArea')}/> */}
                  
                </div>
                <div className="col-md-2 col-lg-3 input-group-sm">
                    <label>Puesto</label>
                    <input type="text" className="form-control"  defaultValue={data.wArea} disabled/>
                    {/* <Select options={department} value={data.wArea} onChange={value=>handleDataSelect(value,'wArea')}/> */}
                  
                </div>
                <div className="col-md-4 col-lg-3 input-group-sm">
                    <label>Número de Infonavit</label>
                    <input type="text" className='form-control' name='infonavit' value={data.infonavit || ''} onChange={handleData}/>
                </div>
                <div className="col-md-4 col-lg-3 input-group-sm">
                    <label>Número de tarjeta de nómina</label>
                    <input type="text" className='form-control' name='numCart' value={data.numCart || ''} onChange={handleData}/>
                </div>
                <div className="col-md-4 col-lg-3 input-group-sm">
                    <label>Número de tarjeta SPIN</label>
                    <input type="text" className='form-control' name='numCarttwo' value={data.numCarttwo || ''} onChange={handleData}/>
                </div>
                
                <div className="col-md-4 col-lg-3 input-group-sm">
                    <label>Número de licencia </label>
                    <input type="text" className='form-control' name='idLicNum' value={data.idLicNum || ''} onChange={handleData}/>
                </div>
                <div className="col-md-4 col-lg-3 input-group-sm">
                    <label>Vencimiento de licencia</label>
                    {/* el campo es el de id_check en bd, se utilizo ese por que no estaba en uso y no era necesario */}
                    <input type="date" className='form-control' name='id_check' value={data.id_check || ''} onChange={handleData}/>
                </div>
                <div className="col-md-4 col-lg-3 input-group-sm">
                    <label>Numero de Empleado</label>
                    <input type="number" className='form-control' name='id_empleado' value={data.id_empleado || ''} onChange={handleData}/>
                </div>
                <div className="col-md-4 col-lg-3 input-group-sm">
                    <label>Código de Checador</label>
                    <input type="text" className='form-control' name='checkCode' value={data.checkCode || ''} onChange={handleData}/>
                </div>
                <div className="col-md-4 col-lg-3 input-group-sm">
                    <label>Email</label>
                    <input type="email" className='form-control' name='email' value={data.email || ''} onChange={e=>validarEmail(e.target.value,'email')}/>
                  {emailValidator.email&&  <small className="text-danger">Email invalido</small>}
                </div>
                <div className="col-md-4 col-lg-3 input-group-sm">
                    <label>Email notaria</label>
                    <input type="email" className='form-control' name='emailCompany' value={data.emailCompany || ''} onChange={e=>validarEmail(e.target.value,'emailCompany')}/>
                    {emailValidator.emailCompany&&  <small className="text-danger">Email invalido</small>}
                </div>

            </div>  

    </>
  )
}
const ModalPuesto =({reload})=>{
    const [data,setData]=useState({
        puesto:'',
        id:0,
        type:1
    })
    const handleSubmit= async()=>{

        const response = await request.post('tags/createTag',data)
        if (response && response.statusCode==200) {
            // reload()
            return 'success'
        }else{
            return 'error'
        }
    }
    return(
        <SimpleModal identifier={'modalpuesto'} size='400'>
            <div className="card p-3">
                <h6 className='text-xs'>Nombre del puesto</h6>
            <input type="text" className='form-control' placeholder='escriba aqui' value={data.puesto} onChange={e=>setData({...data,puesto:e.target.value})} />
        
           <ModalButtons handleSubmit={handleSubmit}/>
            </div>

        </SimpleModal>
    )
}
const ModalJefeInmediato =({reload,getTags})=>{
    const [data,setData]=useState({
        puesto:'',
        id:0,
        type:2
    })
    const handleSubmit= async()=>{

        const response = await request.post('tags/createTag',data)
        if (response && response.statusCode==200) {
            getTags()
            return 'success'
        }else{
            return 'error'
        }
    }
    return(
        <SimpleModal identifier={'modalJefeInmediato'} size='600' heightProp='200' >
            <div className="card  mt-3 p-3">
                <h6 className='text-xs'>Nombre del Jefe Inmediato</h6>
            <input type="text" className='form-control' placeholder='escriba aqui' value={data.puesto} onChange={e=>setData({...data,puesto:e.target.value})} />
        
           <ModalButtons handleSubmit={handleSubmit}/>
            </div>

        </SimpleModal>
    )
}