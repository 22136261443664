import React, { useState } from 'react'
import Request from '../../utils/http';
import { Toast } from '../Toast';
const request = new Request()
export const UploadDocument = ({ urlPost,url, typeDoc ,user,reload,typeprop,date='',urlDesactivate='uploadDocumentation/desactivate',metod='put'}) => {
 
 
  const [loading,setLoading]=useState(false)
    const [showToastProp,setShowToast]=useState({
      showToast:false,
      labelHeader:'Éxito',
      message:'Operacion exitosa',
      type:'success'
  })
    const [fileInputKey, setFileInputKey] = useState(0); // Añadir una clave única para restablecer el input
  
    const handleUpload = async (file,typeDocp) => {
      setLoading(true)
      const userId = user.id;
      const s=typeDocp
      const formData = new FormData();
      formData.append('file', file);
      formData.append('user_id', userId);
      formData.append('type', s);
      formData.append('date', date);
  
      try {
        const response = await request.post(urlPost, formData);
        if (response && response.statusCode==200) {
          setShowToast({...showToastProp,type:'success',showToast:true,message:'Guardado con exito!!'})
          reload(response.result.file_url)
      }else{
          setShowToast({...showToastProp,type:'danger',showToast:true,labelHeader:'Error, intentelo de nuevo',message:'Favor de notificar a sistemas'})
          
      }
      setTimeout(() => {
          setShowToast({...showToastProp,showToast:false})
          
      }, 3000);
      setLoading(false)
        setFileInputKey((prevKey) => prevKey + 1);
      } catch (error) {
        console.error('Error al subir el archivo:', error);
      }
    };
    const handeDesactivate=async()=>{
     const data={
      id:user.id,
      typeDoc:typeprop.typeDoc
     }
     let response = null
     if (metod=='put') {
      
        response = await request.put(urlDesactivate,data)
     } else {
     
        response = await request.post(urlDesactivate,data)
      
     }



     if (response && response.statusCode==200) {
          console.log("🚀 ~ handeDesactivate ~ response:", response)
          setShowToast({...showToastProp,type:'success',showToast:true,message:'desactivado con exito!!'})
          reload(1)
        }else{
            setShowToast({...showToastProp,type:'danger',showToast:true,labelHeader:'Error, intentelo de nuevo',message:'Favor de notificar a sistemas'})
            
        }
  setTimeout(() => {
      setShowToast({...showToastProp,showToast:false})
  }, 3000);
    }

    
    return url !=''? (
     <div className='input-group-sm d-flex'>
       <a className="btn btn-info btn-sm" href={url} target='_blank'>
        <i className="fa fa-file font-12" ></i> <div className=""></div>
      </a>
      <button className='btn btn-danger ml-2' onClick={e=>handeDesactivate()}> <i class="fa fa-trash"></i></button>
     </div>
    ) : (
      <>
      <div className="custom-file">
        <input
          type="file"
          key={fileInputKey} // Cambia la clave para forzar la re-renderización y restablecer el input
          className="custom-file-input"
          id={`customFile${typeDoc}`}
          accept=''
          onChange={(e) => handleUpload(e.target.files[0],typeDoc)}
        />
        <label className={`bg bg-${loading?'warning':'dark'} font-12 p-1 custom-file-label shadow`} style={{ borderRadius: '15px', color: 'white' }} htmlFor={`customFile${typeDoc}`}>
          {loading?'Cargando archivo':'Seleccionar archivo' } &nbsp;<i className={`${loading ? 'fa fa-spinner fa-spin' : 'fa-solid fa-arrow-up-from-bracket'} font-15`}></i>
        </label>

        {showToastProp.showToast && 
          <Toast
            showToast={showToastProp.showToast}
            labelHeader={showToastProp.labelHeader}
            message={showToastProp.message}
            type={showToastProp.type}
          
          />}
      </div>
      
      
      </>
    );
  };
