import React, { useEffect, useState } from 'react'
import curved6 from '../static/img/curved-images/curved-6.jpg'
import sil from '../static/logo.png'
import Request from '../utils/http'
import {encryptString} from '../utils/encrypt'
import { useNavigate  } from 'react-router-dom';
import { path } from '../configuracion'
import { openDB } from 'idb'
const request = new Request()
export const LoginPage = () => {

    const navigate = useNavigate ();
    const [email,setEmail] =useState('')
    const [password,setPassword] = useState('')
    const [loading,setloading] = useState(false)
    const [error,setError] = useState(false)
    const [errorText,setErrorText] = useState('')
    const handleLogin =async()=>{
      setloading(true)
        let data = {email:email,password:password}

        const response = await request.post('login',data)
      
        if (response && response.statusCode === 200 && response.result.errorboss==false) {
            const respuesta = response.result

            sessionStorage.setItem('authToken', encryptString(respuesta.access_token));
            let user = encryptString(JSON.stringify(respuesta.user))
            sessionStorage.setItem('user', user);
            sessionStorage.setItem('home', respuesta.home.url);
            sessionStorage.setItem('modulos', JSON.stringify(respuesta.permisos));
            sessionStorage.setItem('permisosAcceso', JSON.stringify(respuesta.permisosAcceso));

            if (respuesta.device == null && respuesta.deviceOffice==null) {
              deleteTokenFromIndexedDB()
            }else{

              sessionStorage.setItem('device',respuesta.device)
              sessionStorage.setItem('deviceOffice',respuesta.deviceOffice)
            }
           
            
            
            // navigate(respuesta.home.url);
            window.location.reload()
            // window.location.replace(`${path}${respuesta.home.url}`); 


          }else if(response.statusCode== 503){
            setError(true)
            setErrorText('Usuario o contraseña no validos!')
          }else{
          
            setErrorText('datos impompletos')
            setError(true)
          }
          setloading(false)
      
    }
    useEffect(()=>{
      checkSession()
    },[])
    const checkSession=()=>{
      let exist = sessionStorage.getItem('user')
      if (exist) {
        navigate(sessionStorage.home);
      }
    }

    const deleteTokenFromIndexedDB = async (position) => {
    
      indexedDB.deleteDatabase("my-database");

    };
  return (
    <>
     <div className="container position-sticky z-index-sticky top-0">
    <div className="row">
      <div className="col-12">
       
        <nav className="navbar navbar-expand-lg blur blur-rounded top-0 z-index-3 shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
          <div className="container-fluid pe-0">
            <a className="navbar-brand font-weight-bolder ms-lg-0 ms-3 " href='#'>
             Notaria publica N° 83
            </a>
            <button className="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon mt-2">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navigation">
              <ul className="navbar-nav mx-auto ms-xl-auto me-xl-7">
            
              </ul>
            </div>
          </div>
        </nav>
       
      </div>
    </div>
  </div>
  <main className="main-content  mt-0">
    
    <section>
      <div className="page-header min-vh-75">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-6 d-flex flex-column mx-auto">
              <div className="card card-plain mt-8">
              <img src={sil} alt="fondo" style={{width:'100%',marginTop:'20%'}} className=''/>
                <div className="card-header pb-0 text-left bg-transparent">
                  <h3 className="font-weight-bolder text-not-dark  ">Sistema Integral Laboral 83</h3>
                  <p className="mb-0">Ingresa tus credenciales de acceso</p>
                </div>
                <div className="card-body">
                  <form >
                    <label>Usuario</label>
                    <div className="mb-3">
                      <input type="text" className="form-control" onChange={e=>setEmail(e.target.value)} value={email}  placeholder="Usuario" aria-label="Email" aria-describedby="email-addon"/>
                    </div>
                    <label>Password</label>
                    <div className="mb-3">
                      <input type="password" onChange={e=>setPassword(e.target.value)} value={password} className="form-control" placeholder="Password" aria-label="Password" aria-describedby="password-addon"/>
                    </div>
                 
                    <div className="text-center">
                      <button type="button" disabled={loading} className="btn bg-gradient-dark w-100 mt-4 mb-0" onClick={handleLogin}>
                       {loading&& <i className='fa fa-spin fa-spinner'></i>}&nbsp;
                      {loading?'Iniciando sesion...':  'Iniciar Sesión'}
                        
                        </button>
                    </div>
                  </form>
                </div>
                <div className="card-footer text-center pt-0 px-lg-2 px-1">
                  <p className="mb-4 text-sm mx-auto">
                    olvidaste tu contraseña? Contacta a sistemas
                   
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
                    {/* <img src={sil} alt="fondo" style={{width:'100%',marginTop:'200px'}} className=''/> */}
              {/* <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{backgroundImage:curved6}}>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    {error&&<div className="m-1 alert alert-danger text-white" role="alert">
      <strong>error!</strong> {errorText}
  </div>}
  </main>
 
    </>
  )
}
