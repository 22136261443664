import { useEffect, useState } from "react";
import { ProgressBar } from 'primereact/progressbar';
import Modal from "react-modal";
import { db } from '../../../../../../../firebaseConfig';
import {  getDoc,doc,updateDoc } from 'firebase/firestore';
import moment from "moment-timezone";

export const Encuesta = ({ opciones, userDataInfo,id,answered }) => {

  
    const [totalVotos, setTotalVotos] = useState(0);
    const [copyPost, setCopyPost] = useState(null)
    const [disabled,setIsDisabled]=useState(false)

    const manejarSeleccion = (index) => {
        let copy = JSON.parse(JSON.stringify(copyPost))

        let number = userDataInfo.id_personal

        if (copy.multi) {
            
            if (copy.opciones[index].ids.includes(number)) {
                // Si el número existe, elimínalo
                copy.opciones[index].ids = copy.opciones[index].ids.filter(item => item !== number);
            } else {
                // Si el número no existe, añádelo
                copy.opciones[index].ids.push(number);
            }
        }else{
            copy.opciones.forEach((opcion, i) => {
                opcion.ids = opcion.ids.filter(item => item !== number);
              });
            
              // Agrega el número solo en la opción especificada, si no existía previamente
              if (!copy.opciones[index].ids.includes(number)) {
                copy.opciones[index].ids.push(number);
              }
        }

        let total = 0
        copy.opciones.forEach(element => {
            total+=element.ids.length
           
        });
        setTotalVotos(total)

        setCopyPost(copy)

    };
    function isDateToResponseValid(opciones) {
        const currentDate = moment().tz('America/Cancun').startOf('day'); // Usando la zona horaria de Cancún
    
        const responseDate = moment.tz(opciones.dateToResponse, 'America/Cancun').startOf('day'); // Usando la zona horaria de Cancún
      
        return currentDate.isAfter(responseDate);
    }
    
    useEffect(() => {
        if (opciones) {
            let isdisabled = isDateToResponseValid(opciones) 
            let total = 0
            opciones.opciones.forEach(element => {
            total+=element.ids.length
        });
        setTotalVotos(total)
        let d = answered.includes(userDataInfo.id_personal)
        setIsDisabled(isdisabled)
        if (d) {
           setIsDisabled(true)   
        }  
    }
     setCopyPost(opciones)
        
    }, [opciones])

    return (
        <>
            <div className="encuesta shadow">
                <h6>{copyPost?.pregunta}</h6>
                <ul>
                    {copyPost?.opciones.map((opcion, index) => {

                        let checked = copyPost.opciones[index].ids.includes(userDataInfo.id_personal)
                        return (
                            <li key={index}>
                                <div className="opcion-container">
                                    <label>
                                        <input type="checkbox" disabled={disabled} checked={checked} onChange={() => manejarSeleccion(index)} />
                                        {opcion.text}
                                    </label>
                                    <ProgressBar value={(copyPost.opciones[index].ids.length / totalVotos) * 100} displayValueTemplate={() => `${Math.round((copyPost.opciones[index].ids.length / totalVotos) * 100) || 0}%`} />
                                </div>
                            </li>
                        )
                    })}
                </ul>

            </div>
            <ModalSendResponse {...{disabled,id,copyPost,userDataInfo,answered}} />

        </>
    );
};

const ModalSendResponse=({disabled,id,copyPost,userDataInfo,answered})=>{
    const [isOpen,setIsOpen]=useState(false)
    const [answeredPrevius,setAnsweredPrevius]=useState(false)

const handleSubmit=async()=>{
        const postRef = doc(db, 'posts', id);
        const postDoc = await getDoc(postRef);
    
    
    
        if (postDoc.exists()) {
            // Obtener los datos actuales del post
            let postData = postDoc.data();
           
            let d = postData.answered
            d.push(userDataInfo.id_personal)
          
            const respuesta =  await updateDoc(postRef, {
                'data.opciones': copyPost.opciones,
                'answered':d
              });
              setIsOpen(false)
            
        }
    }

    useEffect(() => {
        
        let d = answered.includes(userDataInfo.id_personal)
        setAnsweredPrevius(d)
    }, [answered]);
    
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "-35%",
      width: "83%",
      height: "38%",
      transform: "translate(-50%, -50%)",
    },
  };
    return(
        <>
        
        {!answeredPrevius && 
            !disabled && 
            <div className="text-end mt-2">
                <button className="btn btn-primary font-10 p-2" onClick={e=>setIsOpen(true)} >enviar respuesta</button>
            </div>
        }
        
        <Modal isOpen={isOpen} shouldCloseOnEsc style={customStyles} shouldCloseOnOverlayClick onRequestClose={e=>setIsOpen(false)}>
           <div className="text-center">
           <h3>¿Contestar esta encuesta?</h3>
           <p>Esta accion no se puede deshacer</p>
           </div>
           <div className="d-flex text-center">
            <button className="btn bg-gradient-dark " onClick={handleSubmit}> <i className="fa fa-upload"></i> Si, contestar</button>
            <button className="btn"  onClick={e=>setIsOpen(false)}> cancelar</button>
           </div>
        </Modal>

        </>
    )
}