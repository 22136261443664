import { useState } from 'react'
import Modal from 'react-modal'
import { db } from '../../../../../../../firebaseConfig';

import { collection, query, getDoc,doc,deleteDoc,updateDoc,orderBy, limit, startAfter, getDocs ,onSnapshot, serverTimestamp } from 'firebase/firestore';
import { ToastAlert } from '../../../../../../../components/ToastAlert';

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "-35%",
      width: "98%",
      height: "18%",
      transform: "translate(-50%, -50%)",
    },
  };
export const RenderAnyOption=({post})=>{
  const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
    const [isOpen,setIsOpen]=useState(false)

    const handleDelete=async(docId)=>{
      try {
        await deleteDoc(doc(db, "posts", docId));
        console.log("Documento eliminado con éxito!");
        setToastAlert({text:'eliminado con exito',type:'success'},)
        setIsOpen(false)
      } catch (error) {
        console.error("Error eliminando el documento: ", error);
        setToastAlert({text:'error al eliminar ',type:'error'},)
      }
    }
    return(
      <>
      <ToastAlert {...{toastAlertProps}} /> 
         <button type="button" className="btn btn-sm bg-gradient-primary mb-0 font-8" onClick={e=>setIsOpen(true)}  >
            <i className="fa fa-trash font-15"></i> 
         </button>
        <Modal isOpen={isOpen} shouldCloseOnEsc={true}shouldCloseOnOverlayClick={true}  onRequestClose={e=>setIsOpen(false)} style={customStyles}>
          <div className="text-center">
             <h6>¿Eliminar esta publicacion?</h6>
            <div className="d-flex justify-content-center">
                <button className="btn btn-danger" onClick={e=>handleDelete(post.id)} >Si,eliminar</button>
                <button className="btn" onClick={e=>setIsOpen(false)}>Cancelar</button>

            </div>
          </div>
        </Modal>
      
      </>
    )
  }