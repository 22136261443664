
export function determinarTipo(variable,property) {
    if (typeof variable === 'string') {
       return variable
    } else if (typeof variable === 'object' && !Array.isArray(variable) && variable !== null) {
       return variable[property]
    } else {

        ///no cumple 
       return null
    }
}