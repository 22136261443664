import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart';
import { TableInfinityFixed } from '../../../components/TableInfinityFixed';
import randomCssRgba from '../../../Hooks/RandomColor';
const header=[
    {label:'Tipo'},
    {label:'Departamento'},
    {label:'Enero'},
    {label:'Febrero'},
    {label:'Marzo'},
    {label:'Abril'},
    {label:'Mayo'},
    {label:'Junio'},
    {label:'Julio'},
    {label:'Agosto'},
    {label:'Septiembre'},
    {label:'Octubre'},
    {label:'Noviembre'},
    {label:'Diciembre'},

]
export const TableInactivos = ({keysData,data,inactivosCharts}) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
   if (keysData.length > 0) {
    processData(inactivosCharts)
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
   
    const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };

    // setChartData(data);
    setChartOptions(options);
   }
}, [keysData]);

const processData=(inactivosCharts) => {
 
   let dataItems = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','septiembre','Octubre','Noviembre','Diciembre'],
    datasets: [ ]
    };
    inactivosCharts.forEach((element,key) => {
          dataItems.datasets.push(  {
            label:element.depto,
            data:element.data,
            fill: false,
            borderColor: randomCssRgba(),
            tension: 0.4
        })
    });
    setChartData(dataItems)

}
    const returnMonts=(items) => {
      let mes=[0,0,0,0,0,0,0,0,0,0,0,0]
      items.forEach(element => {
          mes[element.month-1] = element.records.length 
      });
      return mes
    }
  return (
    <>
    
    <Chart type="line" height='230' data={chartData} options={chartOptions} />
    
    <TableInfinityFixed columnsParam={header} height={'auto'} aditionalClass="table-striped">
    {keysData.map(((line,idx)=>{
        let s = returnMonts(data[line]);
     
         return(
            <tr key={idx}>
                <td>baja</td>
                <td>{line=='no_asign'?'sin asignar':line}</td>
                <td>{s[0]}</td>
                <td>{s[1]}</td>
                <td>{s[2]}</td>
                <td>{s[3]}</td>
                <td>{s[4]}</td>
                <td>{s[5]}</td>
                <td>{s[6]}</td>
                <td>{s[7]}</td>
                <td>{s[8]}</td>
                <td>{s[9]}</td>
                <td>{s[10]}</td>
                <td>{s[11]}</td>

            </tr>
         )
    }))}

</TableInfinityFixed>
    </>
  )
}
