import React, { useEffect, useState } from 'react'
import vacation from '../../../../../static/vacation.png'
import time from '../../../../../static/time.png'
import permisos from '../../../../../static/permisos.png'
import visita from '../../../../../static/visita.jpg'
import { Link } from 'react-router-dom'
export const CardsAlert = ({loggedIn,handleOpen,lastModified,urlToBack}) => {
  
  const [loggedInStatus,setLoggedIn] = useState(false)
  const [leyend,setLeyend] = useState('CheckIn')
 
  useEffect(() => {

    setLoggedIn(loggedIn)
    if (lastModified) {
      switch (lastModified.type) {
        case 1:
          
        setLeyend('CheckOut')
        break;
        case 2:
          setLeyend('CheckIn')
          
          break;
          case 3:
            setLeyend('CheckOut')
          
          break;
          
          default:
          setLeyend('CheckIn')
          break;
        }
      }
    }, [lastModified]);
    
    // useEffect(() => {
      // const initDB = async () => {
        // const db = await openDB('MiBaseDeDatos', 1, {
        //   upgrade(db) {
        //     // Crea un almacén de objetos si no existe
        //     if (!db.objectStoreNames.contains('personas')) {
        //       db.createObjectStore('personas', { keyPath: 'id', autoIncrement: true });
        //     }
        //   }
        // });
    
        // // Agregar un ejemplo de datos
        // await addData(db, { nombre: 'Juan', edad: 30 });
        // await addData(db, { nombre: 'María', edad: 25 });
    
        // // Recuperar los datos almacenados
        // const personas = await getAllData(db);
        // console.log('Personas almacenadas:', personas);
    //   };
    
    //   initDB();
    // }, []);
  //    // Función para agregar datos
  // const addData = async (db, data) => {
  //   const tx = db.transaction('personas', 'readwrite');
  //   const store = tx.objectStore('personas');
  //   await store.add(data);
  //   await tx.done;
  //   console.log('Datos agregados:', data);
  // };

  // // Función para obtener todos los datos
  // const getAllData = async (db) => {
  //   const tx = db.transaction('personas', 'readonly');
  //   const store = tx.objectStore('personas');
  //   const allData = await store.getAll();
  //   await tx.done;
  //   return allData;
  // };
    
 

    return (

     
      <div className="row mt-3">
         
  <div className="col-md-12 p-2 mt-2"  onClick={handleOpen}>
       <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12 text-center   shadow" 
           style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',height:'60px'}}>
           <div  className="col-10 d-flex justify-content-between pr-3">
               <img src={time} alt="" className='ml-3 mt-2' style={{width:'40px', height:'40px'}} />
                 <h6 className="mb-1 text-gradient-not ml-2 mt-2 text-not "> {leyend}  </h6>
                 <i className="fa-solid fa-check font-30 mt-3 text-not ml-1"></i>
           </div>
         </div>
  </div>
  <div className="col-md-12 p-2 mt-2"  >
  <Link to={`/CheckOfTheDays/${urlToBack}`}>
       <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12 text-center   shadow" 
           style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',height:'60px'}}>
           <div  className="col-10 d-flex justify-content-between pr-3">
               <img src={time} alt="" className='ml-3 mt-2' style={{width:'40px', height:'40px'}} />
                 <h6 className="mb-1 text-gradient-not ml-2 mt-2 text-not "> Mis checks del dia  </h6>
                 <i className="fa-solid fa-list-check font-30 mt-3 text-not ml-1"></i>
           </div>
         </div>
         </Link>
  </div>
  <div className="col-md-12 p-2 mt-2">
       <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12  text-center   shadow" 
           style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',height:'60px'}}>
           <Link to={`/checking/${urlToBack}`} className="col-10 d-flex justify-content-between pr-3">
               <img src={visita} alt="" className='ml-3 mt-2' style={{width:'40px', height:'40px'}} />
                 <h6 className="mb-1 text-gradient-not ml-2 mt-2 text-not ">  Registrar visita </h6>
                 <i className="fa-solid fa-solid fa-location-crosshairs font-30 mt-3 text-not ml-1"></i>
           </Link>
         </div>
  </div>
  <div className="col-md-12 p-2 mt-2">
      <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-1 text-center shadow" 
          style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',height:'57px'}}>
          <Link className="col-10 d-flex justify-content-between pr-3" to={"/vacationsMobile/"+urlToBack}>
            <img src={vacation} alt="" className='ml-1 mt-2' style={{width:'40px',height:'40px'}} />
            <h6 className="mb-1 text-gradient-not ml-1 mt-3 text-not "> Mis vacaciones&nbsp; </h6>
              <i className="fa-solid fa-plane-departure font-20 mt-3 text-not m-1 mr-1"></i>
          </Link>
        </div>
  </div>
  <div className="col-md-12 p-2 mt-2">
      <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-1 text-center shadow" 
          style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',height:'57px'}}>
          <Link className="col-10 d-flex justify-content-between pr-3" to={"/solicitudes/"+urlToBack}>
            <img src={permisos} alt="" className='ml-1 mt-2' style={{width:'40px',height:'40px'}} />
              <h6 className="mb-1 text-gradient-not ml-1 mt-3 text-not ">Mis Solicitudes &nbsp; </h6>
              <i className="fa-solid fa-pen-to-square font-20 mt-3 text-not m-1 mr-1"></i>
          </Link>
      </div>
  </div>
 
 
</div>

  )
}


