// Weather.js
import React, { useState, useEffect } from 'react';
import Request from '../../../../utils/http'
import axios from 'axios';
const request = new Request();
export const Weather = () => {
  const [location, setLocation] = useState(null);
  const [weather, setWeather] = useState(null);
  const [error, setError] = useState(null);
  const [iconUrl,seticonUrl]=useState('')
  const [color,setColor] = useState('');

  useEffect(() => {
    // Obtener la ubicación del usuario
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
      },
      (err) => {
        setError(err.message);
      }
    );
  }, []);

  useEffect(() => {
    if (location) {
      const fetchWeather = async () => {
        const baseLatitud = 20.6208046
        const baseLongitud = -87.080208;
          const apiKey = '67f3f9e77092543b435b56060da1dddb';
          const { latitude, longitude } = location;
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=${baseLatitud}&lon=${baseLongitud}&appid=${apiKey}&units=metric&lang=es`;

       
         
         
        try {
            const response = await axios.get(url);
            
           
            setWeather(response.data);
           seticonUrl(`http://openweathermap.org/img/wn/${response.data.weather[0].icon}@2x.png`);
           if (response.data.weather[0].id>199 &&response.data.weather[0].id<300) {
            setColor('warning')
        }else if(response.data.weather[0].id>232 &&response.data.weather[0].id<322){
            
            setColor('info')
        }else if (response.data.weather[0].id>499 &&response.data.weather[0].id<532){
               setColor('dark')

           }






          } catch (err) {
            setError(err.message);
          }
        };

      fetchWeather();
    }
  }, [location]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!location) {
    return <div>Obteniendo ubicación...</div>;
  }

  if (!weather) {
    return <div>Obteniendo clima...</div>;
  }
 
  
 
  return (
    <>
    <div class="col-12">
<div class={`card bg-gradient-${color} crecer`}>
  <div class="card-body p-3 ">
    <div class="row ">
      <div class="col-8 my-auto ">
        <div class="numbers">
          <p class="text-white text-sm mb-0 text-capitalize font-weight-bold opacity-7">
           Clima hoy
          </p>
          <h5 class="text-white font-weight-bolder mb-0">
          {weather.name} - {weather.main.temp} °C
          </h5>
          <h6 class="text-white font-weight-bolder mb-0">  Sensacion termica: { weather.main.feels_like}</h6>
          <h6 class="text-white font-weight-bolder mb-0">  Min: { weather.main. temp_min} Max: {weather.main. temp_max}</h6>
         
        
         
        </div>
      </div>
      <div class="col-4 text-end">
      <img src={iconUrl} alt="Icono del clima" />
        <h5 class="mb-0 text-white text-end me-3">{weather.weather[0].description}</h5>
      </div>
    </div>
  </div>
</div>
</div>
     
      
    </>

  );
};
