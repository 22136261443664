import { useState } from "react";

export const ModalCloseSesion=({useNavigate,request,FiLogIn,Modal})=>{
    const navigate = useNavigate ();
    const [open,setIsOpen]=useState(false)
    const logout=async() => {
      const response = await request.post('logout')
      if (response && response.statusCode === 200) {
        sessionStorage.clear();
     
        navigate('/');
      }
    }
    const customStyles = {
      content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "-35%",
          width: "83%",
          height: "21%",
          transform: "translate(-50%, -50%)",
        },
  }
    return(
      <>
      
      <span  className=" p-1" onClick={e=>setIsOpen(true)}>
         {/* <i class="fa-solid fa-power-off text-not font-30"></i> */}
         <FiLogIn size={'35px'} color='#000000' />
      </span>
      <Modal isOpen={open} style={customStyles} shouldCloseOnEsc={true} onRequestClose={e=>setIsOpen(false)} shouldCloseOnOverlayClick={true} >
      <div className="text-center">
      <h2>¿Cerrar Sesión?</h2>
      </div>
      <div className="d-flex input-group-sm justify-content-center">
        <button className="btn btn-sm btn-not" onClick={logout}>Si,Cerrar Sesion</button>
        <button className="btn ml-2 btn-sm btn-outline-secondary" onClick={e=>setIsOpen(false)}>Cancelar</button>
      </div>
  
      </Modal>
      </>
    )
  }