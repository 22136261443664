import React, { useEffect, useRef, useState } from 'react'
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import { OrganizationChart } from 'primereact/organizationchart';
import Request from '../../../utils/http'
const request = new Request();

export const OrganigramaGeneral = ({openModal,closeModal,dataToShow,setDataToShow}) => {
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const [scale, setScale] = useState(0.5);
  
  
    const scaleContent = () => {
      if (containerRef.current && contentRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = containerRef.current.clientHeight;
        const contentWidth = contentRef.current.scrollWidth;
        const contentHeight = contentRef.current.scrollHeight;
  
        const scaleX = containerWidth / contentWidth;
        const scaleY = containerHeight / contentHeight;
        const newScale = Math.min(scaleX, scaleY);
  
        // setScale(newScale);
      }
    };
  
    const handleWheel = (event) => {
        event.preventDefault();
        const delta = event.deltaY > 0 ? -0.1 : 0.1;
       
        setScale(prevScale => Math.min(Math.max(prevScale + delta, 0.2), 2));
      };
    const [selection, setSelection] = useState([]);
    const [data,setData] = useState(null);
   

    const getData = async() => {
        const response = await request.get("information/departments", []);

     
       
        if (response && response.statusCode==200) {
            let org=[
                {
                    expanded: false,
                    type: 'person',
                    data: {
                        image: 'https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png',
                        name: 'RAMÓN ROLANDO HEREDIA RUIZ',
                        title: 'Notario Titular'
                    },  
                    children:[] 
                }
            ]
            response.result.datos.Department.forEach(element => {
                let boss=JSON.parse(element.areaboss)
                let item={
                    expanded: false,
                    type: 'person',
                    data: {
                        image: 'https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png',
                        name: boss.label,
                        title: element.description,
                        
                    },
                    children: [ ]
                }
                // element.groups.forEach(group => {
                //   let puesto={
                //     label:`${group.description}\n Sueldo base: ${group.sueldo_base}`,
                    
                //     children:[ ]
                //   }
                //   puesto.children.push({
                     
                //     expanded: true,
                //     type: 'person',
                //     data: {
                //         image: 'https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png',
                //         name: group.personal.length>0?`${group.personal[0].name} ${group.personal[0].lastName}`:'',
                //         title:  group.personal.length>0?`sueldo: ${group.personal[0].sueldo}`:'',
                //         children:[
                //           {data:group.personal}
                //         ]
                        
                //     },
                        
                   
                //   })



                //     item.children.push(puesto)
                   
                // })

                org[0].children.push(item)
            });
            setData(org);
        }
    }
    const handleNode=(node) => {
      setDataToShow(node.data.children)
      closeModal(true)
      
    }
    const nodeTemplate = (node) => {


        if (node.type === 'person') {
            return (
                <div style={{borderRadius:20,width:200}} onClick={e=>handleNode(node)}>
                    <div className="rounded">
                        <img alt={node.data.name} src={node.data.image} className="mb-3 w-3rem h-3rem img-fluid " style={{width:40}} />
                    </div>
                    <div>
                        <h6 className="font-bold mb-2 font-15">{node.data.name}</h6>
                    </div>
                    <div className="">
                        <span>{node.data.title}</span>

                    </div>
                </div>
            );
        }

        return node.label;
    };
    useEffect(() => {
    if(   data!=null ){

        scaleContent();
        window.addEventListener('resize', scaleContent);
    }
        return () => {
          window.removeEventListener('resize', scaleContent);
        };
      }, [data]);
      useEffect(()=>{
        getData()
    },[])
    useEffect(() => {
        scaleContent();
        window.addEventListener('resize', scaleContent);
    
        const container = containerRef.current;
        if (container) {
          container.addEventListener('wheel', handleWheel);
        }
    
        return () => {
          window.removeEventListener('resize', scaleContent);
          if (container) {
            container.removeEventListener('wheel', handleWheel);
          }
        };
      }, []);
      useEffect(() => {
        const container = containerRef.current;
        if (container) {
          container.scrollLeft = (container.scrollWidth - container.clientWidth) / 2;
        }
      }, []);
  return (
    <div className="card " ref={containerRef} style={{overflowX:'scroll',height:'80vh',backgroundColor:'#a5c1dd73' }} >
            <div className="content " ref={contentRef} style={{ transform: `scale(${scale})`,}}>
                {data!=null&&  <OrganizationChart value={data} selectionMode="multiple" selection={selection} onSelectionChange={(e) => setSelection(e.data)} nodeTemplate={nodeTemplate} />}
            </div>
        </div>
  )
}
