import React, { useEffect, useState } from 'react'
import { fetchChats, fetchMessages, sendMessage, createChat } from './firebaseService'; 
import Modal from 'react-modal'
import Request from '../../../utils/http'

import { useAppContext } from '../../../Context/AppContext';
const request = new Request()
export const ContactList = ({setTypeOfChatIndividual,setTypeOfChat}) => {
  const {user,getInfo} = useAppContext(null)
  const [isOpen,setIsOpen] = useState(false)
  const [chats, setChats] = useState([]);
  const [filteredChats, setFilteredChats] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [userId ,setUserId] = useState({
    id:0,
    name:''
});
 

  const loadChats = async () => {
    const userChats = await fetchChats(userId.id);
    setChats(userChats);
    setFilteredChats(userChats);
  };
  useEffect(() => {
    loadChats();
  }, [userId]);

 

  const handleCreateChat = async (otherUserId) => {
    const chatId = await createChat([userId.id, otherUserId.value],userId.name,otherUserId.label);
     setTypeOfChatIndividual(3,chatId)
    loadChats(); // Reload chats after creating a new one
    
  };

  useEffect(() => {
    if(user!='tengo estado') {
        setUserId({
            id:user.personalData.id,
            name:user.personalData.name + ' ' + user.personalData.lastName
        })
    }else{
     getInfo()
    }
}, [user]);




  const handleNewChat = () => {
    setIsOpen(true)
  };

  useEffect(() => {
    // Filter chats based on the search term
    const filtered = chats.filter(chat =>
      chat.nameParticipants.participantOne.toLowerCase().includes(searchTerm.toLowerCase()) ||
      chat.nameParticipants.participantTwo.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredChats(filtered);
  }, [searchTerm, chats]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  return (
    <>
    <ModalContact {...{isOpen,setIsOpen,handleCreateChat}} />
    
    
    <div class="col-12 ">
    <div class="card blur shadow-blur max-height-vh-90 overflow-auto overflow-x-hidden mb-5 mb-lg-0">
      <div class="card-header p-3">
        <h6>Mis contactos</h6>
        <input type="text"
         value={searchTerm}
         onChange={handleSearch}
        id='buscador' class="form-control" placeholder="escribe para buscar...." aria-label="Email"/>
      </div>
      <div class="card-body p-2">
        <div onClick={e=>setTypeOfChat(1)} class="d-block p-2 border-radius-lg bg-gradient-not shadow">
          <div class="d-flex justify-content-between p-2">
            <img alt="Image" src="https://th.bing.com/th/id/OIP.Eb-D-vuvCdFZTvn2ee9n5QHaHa?rs=1&pid=ImgDetMain" class="avatar shadow"/>
            <div class="ms-2">
              <div class="justify-content-between align-items-center">
                <h6 class="text-white mb-0">Chat general
                  <span class="badge badge-success"></span>
                </h6>
                {/* <p class="text-white mb-0 text-sm">Typing...</p> */}
              </div>
            </div>
            <div class="align-end">
            <h6 className='font-25'><i class="fa-solid fa-chevron-right text-white"></i></h6>
            </div>
          </div>
        </div>
        <div onClick={e=>setTypeOfChat(2)} class="d-block p-2 mt-2 border-radius-lg bg-gradient-dark shadow">
          <div class="d-flex justify-content-between p-2">
            <img alt="Image" src="https://cdn-icons-png.flaticon.com/512/748/748637.png" class="avatar shadow"/>
            <div class="ms-2">
              <div class="justify-content-between align-items-center">
                <h6 class="text-white mb-0">Departamento
                  <span class="badge badge-success"></span>
                </h6>
                {/* <p class="text-white mb-0 text-sm">Typing...</p> */}
              </div>
            </div>
            <div class="align-end">
            <h6 className='font-25'><i class="fa-solid fa-chevron-right text-white"></i></h6>
            </div>
          </div>
        </div>
        <hr />
        {filteredChats.map((cht)=>{
         
          let fecha = cht.timestamp.toDate()
          
        let receiver=cht.nameParticipants.participantOne==userId.name?cht.nameParticipants.participantTwo:cht.nameParticipants.participantOne
          return(
            <div class="d-block p-2 shadow border-radius-lg mt-2" onClick={e=>setTypeOfChatIndividual(3,cht.id,receiver,fecha.toLocaleString())} >
            <div class="d-flex p-2">
              <img alt="Image" src="https://th.bing.com/th/id/R.76654c1ccf9a3d2be9f9894bac07a79b?rik=QBkBlxEFtZrMSw&riu=http%3a%2f%2ficon-icons.com%2ficons2%2f827%2fPNG%2f512%2fuser_icon-icons.com_66546.png&ehk=3sdp0gGBFfN4qR2HRKjXLoNmu6wMTj3hP7ZN1p1IxXo%3d&risl=&pid=ImgRaw&r=0" class="avatar shadow"/>
              <div class="ms-3">
                <h6 class="mb-0 font-15">{cht.nameParticipants.participantOne==userId.name?cht.nameParticipants.participantTwo:cht.nameParticipants.participantOne}</h6>
                <span class="text-muted text-sm col-11 p-0 text-truncate d-block">{cht.lastMessage.slice(0, 40)}</span>
                <p class="text-muted text-xs mb-2">{fecha.toLocaleString()}</p>
              </div>
            </div>
          </div>
          )
        })}

      
      </div>
    </div>
    <button className="btn btn-primary floating-btn" onClick={handleNewChat}>
        <i className="fa-solid fa-plus font-15"></i>
      </button>
  </div>
    </>
  )
}

const ModalContact=({isOpen,setIsOpen,handleCreateChat})=>{
  const [userdata,setUser]=useState([])
  const closeModal=()=>{
    setIsOpen(false);
  }

  const getDateMeta=async()=>{
    const response = await request.get("information/departments/personal", [] );

    if (response && response.result.success === true) {
      let personas = [];
      response.result.datos.personal.forEach((item) => {
        
        personas.push({
          label: `${item.name} ${item.lastName}`,
          value: item.id,
          email: item.email,
        });
      });
      
      setUser(personas);
  }
}
  useEffect(()=>{
    getDateMeta()
  },[])

  const createChat=(line)=>{
    handleCreateChat(line)
    closeModal()
  }

  return(
    <Modal isOpen={isOpen} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} onRequestClose={closeModal}>
      {userdata.map((line,key)=>{
       
        return(
          <div class="d-block p-2 shadow border-radius-lg mt-2" key={key} onClick={e=>createChat(line)}>
            <div class="d-flex p-2">
              <img alt="Image" src="https://th.bing.com/th/id/R.76654c1ccf9a3d2be9f9894bac07a79b?rik=QBkBlxEFtZrMSw&riu=http%3a%2f%2ficon-icons.com%2ficons2%2f827%2fPNG%2f512%2fuser_icon-icons.com_66546.png&ehk=3sdp0gGBFfN4qR2HRKjXLoNmu6wMTj3hP7ZN1p1IxXo%3d&risl=&pid=ImgRaw&r=0" class="avatar shadow"/>
              <div class="ms-3">
                <h6 class="mb-0">{line.label} </h6>
              </div>
                <i class="fa-solid fa-chevron-right font-25"></i>
            </div>
           </div>
        )
      })}

    </Modal>
  )
}
  {/* <div onClick={handleDetails} class="d-block p-2 border-radius-lg shadow ">
          <div class="d-flex p-2">
            <img alt="Image" src="../../assets/img/team-2.jpg" class="avatar shadow"/>
            <div class="ms-3">
              <div class="justify-content-between align-items-center">
                <h6 class=" mb-0">Charlie Watson
                  <span class="badge badge-success"></span>
                </h6>
                <p class=" mb-0 text-sm">Typing...</p>
              </div>
            </div>
          </div>
        </div>
        <div onClick={handleDetails} class="d-block p-2 shadow border-radius-lg mt-2">
          <div class="d-flex p-2">
            <img alt="Image" src="../../assets/img/team-1.jpg" class="avatar shadow"/>
            <div class="ms-3">
              <h6 class="mb-0">Jane Doe</h6>
              <p class="text-muted text-xs mb-2">1 hour ago</p>
              <span class="text-muted text-sm col-11 p-0 text-truncate d-block">Computer users and
                programmers</span>
            </div>
          </div>
        </div>
        <div onClick={handleDetails} class="d-block p-2 shadow border-radius-lg mt-2">
          <div class="d-flex p-2">
            <img alt="Image" src="../../assets/img/team-3.jpg" class="avatar shadow"/>
            <div class="ms-3">
              <h6 class="mb-0">Mila Skylar</h6>
              <p class="text-muted text-xs mb-2">24 min ago</p>
              <span class="text-muted text-sm col-11 p-0 text-truncate d-block">You can subscribe to receive
                weekly...</span>
            </div>
          </div>
        </div>
        <div onClick={handleDetails} class="d-block p-2 shadow border-radius-lg mt-2">
          <div class="d-flex p-2">
            <img alt="Image" src="../../assets/img/team-5.jpg" class="avatar shadow"/>
            <div class="ms-3">
              <h6 class="mb-0">Sofia Scarlett</h6>
              <p class="text-muted text-xs mb-2">7 hours ago</p>
              <span class="text-muted text-sm col-11 p-0 text-truncate d-block">It’s an effective resource
                regardless..</span>
            </div>
          </div>
        </div>
        <div onClick={handleDetails} class="d-block p-2 shadow border-radius-lg mt-2">
          <div class="d-flex p-2">
            <img alt="Image" src="../../assets/img/team-4.jpg" class="avatar shadow"/>
            <div class="ms-3">
              <div class="justify-content-between align-items-center">
                <h6 class="mb-0">Tom Klein</h6>
                <p class="text-muted text-xs mb-2">1 day ago</p>
              </div>
              <span class="text-muted text-sm col-11 p-0 text-truncate d-block">Be sure to check it out if your
                dev pro...</span>
            </div>
          </div>
        </div> */}