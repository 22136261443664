import React, { useEffect, useState,useRef } from 'react'
import Modal from 'react-modal'
import moment from 'moment-timezone';
import Request from '../../../utils/http'
const request = new Request();
export const ModalInformation=({openModal,closeModal,dataToShow,setDataToShow}) => {

    const [data,setData]=useState([])
    const toast = useRef(null);
     
  useEffect(() => {
    if (dataToShow) {
      setData(dataToShow[0].data[0])
    }
   
  }, [dataToShow]);
  const [edad,setEdad] = useState('')
    
  useEffect(() => {
      let nac =new Date(data.birthday).getTime();
      let today =new Date().getTime();
      var diff =( today - nac)/(1000*60*60*24).toFixed(2);
      let annios =(diff/365 ).toFixed(2)
      let arr= annios.split('.')
      let meses= ((arr[1]/100)*365)/30
  
      setEdad( `${arr[0]} años ${Math.round(meses)} meses`)
     
  },[data])
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "-35%",
      width: "80%",
      height: "79%",
      transform: "translate(-50%, -50%)",
    },
  };
  const [dataSalaries,setDataSalaries]=useState([])
  const [loading,setLoading] = useState(false)
  useEffect(() => {
      getSalaries(data.id)
  }, [data]);
  const getSalaries=async(id)=>{
      setLoading(true)
      const response = await request.get(`sueldos/obtenerSueldos/${id}`)
    
      
      if (response && response.statusCode==200) {
        setDataSalaries(response.result.data)
        }
        setLoading(false)
     
  }
  const handleData=() => {}
    return(
    <Modal isOpen={openModal} onRequestClose={e=>closeModal(false)} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} style={customStyles} >
      <div className="text-center">
        <h2>Información del colaborador</h2>
      </div>
     <div className="row">
        <div className="col-md-9 row p-3" >
              <div className="col-md-3 col-lg-3 ">
                  <label  >Nombre(s)</label>
                  <input type="text" className={`text-uppercase form-control ${!data.name?'border-danger':''}`}  name='name' value={data.name  || ''} onChange={handleData} />
                  
              </div>
              <div className=" col-md-3 col-lg-3">
                  <label  >Apellido(s)</label>
                  <input type="text" className={`text-uppercase form-control ${!data.lastName?'border-danger':''}`}  name='lastName' value={data.lastName || ''} onChange={handleData}/>
                  
              </div>
              
              <div className=" col-md-3 col-lg-2">
                  <label  >Telefono</label>
                  <input type="number" className='form-control'  name='phone' value={data.phone || ''} onChange={handleData}/>
              </div>
              <div className=" col-md-12 col-lg-2">
                  <label  >Num. Ext</label>
                  <input type="text" className='text-uppercase form-control ' name='numExt' value={data.numExt || ''} onChange={handleData}/>
              </div>
              <div className=" col-md-2 col-sm-3">
                  <label  >Código Postal</label>
                  <input type="number" className={`form-control ${data.cp==''?'border-danger':''}`} name='cp' value={data.cp || ''} onChange={handleData}/>
                  
              </div>
              <div className=" col-md-12 col-lg-7">
                  <label  >Direccion</label>
                  <input type="text" className='text-uppercase form-control' name='direction' value={data.direction || ''} onChange={handleData}/>
              </div>
            
              <div className=" col-md-6 col-lg-5 d-flex justify-content-between">
                  <div>
                      <label  >Fecha de Nacimiento</label>
                          <input type="date"  className={` form-control ${data.birthday==''?'border-danger':''}`} name='birthday'  value={data.birthday} onChange={handleData}/>
                      </div>
  
                      <div className=''>
                          <label >Edad</label>
                          <input type="text" value={edad} disabled className="form-control" />
                      </div>
              </div>
              <div className=" col-md-6 col-lg-4   ">
                  <label >R.F.C</label>
                  <input type="text" className={`text-uppercase form-control ${!data.rfc?'border-danger':''}`} name='rfc' value={data.rfc || ''} onChange={handleData}/>
                  
              </div>
              <div className=" col-md-6 col-lg-4">
                  <label >C.U.R.P</label>
                  <input type="text" className={`text-uppercase form-control ${!data.curp?'border-danger':''}`} name='curp' value={data.curp || ''} onChange={handleData}/>
                  
              </div>
              <div className=" col-md-6 col-lg-4">
                  <label >N.S.S</label>
                  <input type="text" className='text-uppercase form-control' name='nss' value={data.nss || ''} onChange={handleData}/>
              </div>
              <div className=" col-md-6 col-lg-2">
                  <label >Talla de uniforme</label>
                  <input type="text" className='text-uppercase form-control' name='utalla' value={data?.utalla || ''} onChange={handleData}/>
              </div>
              <div className=" col-md-6 col-lg-3">
                  <label >Nivel de Estudios</label>
                  <select className="form-control" name="school" value={data.school || ''} onChange={handleData}>
                      <option value="No Especificado">No Especificado</option>
                      <option value="Primaria">Primaria</option>
                      <option value="Secundaria">Secundaria</option>
                      <option value="Preparatoria">Preparatoria</option>
                      <option value="Licenciatura">Licenciatura</option>
                      <option value="Maestria">Maestria</option>
                      <option value="Doctorado">Doctorado</option>
                  </select>
              </div>
              <div className=" col-md-6 col-lg-3">
                  <label >Número de INE</label>
                  <input type="text" className='text-uppercase form-control' name='ine' value={data.ine || ''} onChange={handleData}/>
              </div>
              <div className=" col-md-6 col-lg-4">
                  <label >Alergias</label>
                  <input type="text" className='text-uppercase form-control' name='alergist' value={data.alergist || ''} onChange={handleData}/>
              </div>
              <div className=" col-md-6 col-lg-4">
                  <label >Persona de Contacto</label>
                  <input type="text" className='text-uppercase form-control' name='personalContact' value={data.personalContact || ''} onChange={handleData}/>
              </div>
              <div className=" col-md-6 col-lg-4">
                  <label className='text-danger'>Telefono de emergencia</label>
                  <input type="number" className='form-control' name='phoneContact' value={data.phoneContact || ''} onChange={handleData}/>
              </div>
              
          </div>
                  <div className="shadow p-3 col-md-3" style={{height: '400px',overflowY:'scroll'}}>
              <h6>Salarios registrados del colaborador</h6>
              <div class="card-body p-3">
                {dataSalaries.map((line,i) =>{
                  const {sueldo,description,created_at,comments,description_dep}=line
                  return(
  
                <div class="timeline timeline-one-side" key={i}>
                  <div class="timeline-block mb-3">
                    <span class="timeline-step">
                    <i class="fa-solid fa-hand-holding-dollar text-dark"></i>
                    </span>
                    <div class="timeline-content">
                      <h6 class="text-dark text-sm font-weight-bold mb-0">${sueldo}, {description}</h6>
                      <h6 class="text-secondary text-xs font-weight-bold mb-0">Departamento: {description_dep}</h6>
                      <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">Fecha de captura: {moment(created_at).format('DD/MM/YYYY')}</p>
                      <p class=" font-weight-bold text-xs mt-1 mb-0 text-danger">Observaciones: {comments==null?'Sin comentarios':comments}</p>
                    </div>
                  </div>
                 
                </div>
                  )
                })}
              </div>
             
          </div>
                {/* <div className="colmd-12 pr-3">
                <SpeedDial buttonStyle={{borderRadius:'40px'}} buttonClassName={'ml-3'} model={items} direction="left" style={{ top: 'calc(90% - 2rem)',borderRadius:'50px', right: 0 }} />
                </div> */}
     </div>
    </Modal>
    )
  }
  
