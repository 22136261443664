import React from 'react'
import { LayoutMovile } from '../Layouts/LayoutMovile'
import setings from '../../static/setings.png'
import inicio from '../../static/inicio.png'
import permisoschrome from '../../static/permisoschrome.png'
import { Accordion, AccordionTab } from 'primereact/accordion';
export const NotificationGeo = () => {
  return (
    <LayoutMovile showTabs={false}>
          <div className="card">
            <Accordion activeIndex={0}>
            <AccordionTab header="Habilitar checking en computadora">
                    <h4 className='text-xs'>Si experimentas problemas para registrar </h4>
                    <h4 className='text-xs'>tus entradas desde google chrome/edge, sigue las siguientes instrucciones:  </h4>
                    <ul>
                        <li>
                            desde la barra de navegacion da click en :
                            <img src={inicio} alt="img" style={{height:'250px',width:'250px'}} />

                        </li>
                        <li>
                            <h4>Posteriormente, asegurate que la casilla de ubicacion este habilitada en permitir:</h4>
                              <img src={permisoschrome} alt="img" style={{height:'250px',width:'250px'}} />

                        </li>
                        <li>
                            <h4>una vez habilitada, solo cierra esa pestaña e inicia sesion de nuevo con tus credenciales:</h4>
                              

                        </li>
                    </ul>
                   
                </AccordionTab>
                <AccordionTab header="Registrar checkIn para usuarios Iphone" style={{overflowY:'scroll'}}>
                <div>
           
          
            <p> Ve a Configuración{` > `}Privacidad y seguridad{` > `} Localización.  </p>
            <ul className='mb-5'>
                <li>Asegúrate de que la función Localización esté activada.</li>
                <li>Desplázate hacia abajo hasta encontrar la app Safari y/o chrome.</li>
                <li>Toca la app y selecciona la opción que corresponda:</li>
                <li> Encontraras un listado similar a este:
                   <div className="d-flex">
                    <div className="" >
                    <ul>
                        <li>Nunca: Deniega el acceso a la información de Localización.</li>
                        <li> Preguntar la próxima vez o al compartirla: permite elegir las opciones Permitir al usar la app, Permitir una vez o No permitir.</li>
                        <li>Al usar la app: permite el acceso a la información de Localización solo cuando la app o una de sus funciones están visibles en la pantalla. 
                        </li>
                        <li>Siempre: Permite el acceso a la información de Localización incluso si la app está funcionando en segundo plano.</li>
                    </ul>
                    </div>
                   </div>
                </li>
                   <img src={setings} alt="img" style={{height:'300px'}} />
                <li>
                    selecciona la opcion: Siempre
                </li>
                <li className="text-danger">
                    una vez permitida la geolocalizacion en safari sigue estos pasos tanto en safari como en chrome ( esto para asegurarnos que tienen acceso correctamente a los datos gps):
                    <ul className="text-dark">
                        <li>
                            abre safari/chrome e ingresa a sil83.com con tus credenciales
                        </li>
                        <li>
                            intenta registrar tu checking.
                        </li>
                        <li>
                            te deberia dejar registrar correctamente tu checking o en en su defecto mostrate una alerta piediendo tu autorización.
                        </li>
                        <li>
                            si una vez seguidos los pasos sigues experimentando el mismo error. por favor comunicate via whatsapp al numero: 9983916095.
                        </li>
                    </ul>
                </li>
            </ul>












          

        </div>
                </AccordionTab>
               
            </Accordion>
          </div>
       
    </LayoutMovile>
  )
}
