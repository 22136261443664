import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import wallpaper from '../../static/no83.jpeg';
import Request from "../../utils/http";
const request = new Request();
Modal.setAppElement("#root"); // Esto es para accesibilidad (debe apuntar al elemento raíz de tu app)

export const CoverPick = ({ backgroundImage, coverPick }) => {
  
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fondo,setFondo]=useState('')

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);  // Guardamos la imagen seleccionada
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    // Creación del form data para enviar el archivo
    const formData = new FormData();
    
    
    formData.append("file", selectedFile);

    
    try {
      
      const response = await request.post(`updateCoverPick`, formData)

      if (response.statusCode==200) {
        alert("Imagen subida correctamente");
        window.location.reload();
        // Recargar la página o actualizar el estado de la imagen si es necesario
      } else {
        alert("Hubo un error al subir la imagen");
      }
    } catch (error) {
      console.error("Error subiendo la imagen:", error);
      alert("Hubo un error al subir la imagen");
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "10%",
      bottom: "-35%",
      width: "47%",
      height: '55%',
      transform: "translate(-50%, -50%)",
    },
  };

  const getData=async()=>{
    
    const response = await request.get('getImg')
    if (response && response.statusCode ==200) {
      setFondo(response.result.data?.link)
    }
  
  }

   useEffect(()=>{
    setFondo(wallpaper)
    getData()
   },[])
  return (
    <div>
      <div
        onClick={openModal}
        className={`page-header min-height-${coverPick ? '200' : '150'} border-radius-xl mt-4`}
        style={{
          backgroundImage: 'url(' + fondo + ')',
          backgroundPositionY: "35%",
          backgroundPositionX: '95%'
        }}
      >
        <span className="mask bg-gradient-primary opacity-1" />
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Wallpaper Modal"
        style={customStyles}
      >
        <div>
          <img src={fondo} alt="Wallpaper" style={{ width: '95%', height: '350px' }} />
      
          <div style={{ marginTop: '20px' }}>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            <button onClick={handleFileUpload} disabled={!selectedFile}>Cargar Imagen</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

