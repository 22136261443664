   const VACATIONSDAYSPERYEARS=[
        {label:1,dias:12},
        {label:2,dias:14},
        {label:3,dias:16},
        {label:4,dias:18},
        {label:5,dias:20},
        {label:6,dias:22},
        {label:7,dias:22},
        {label:8,dias:22},
        {label:9,dias:22},
        {label:10,dias:22},
        {label:11,dias:24},
        {label:12,dias:24},
        {label:13,dias:24},
        {label:14,dias:24},
        {label:15,dias:24},
        {label:16,dias:26},
        {label:17,dias:26},
        {label:18,dias:26},
        {label:19,dias:26},
        {label:20,dias:26},
        {label:21,dias:28},
        {label:22,dias:28},
        {label:23,dias:28},
        {label:24,dias:28},
        {label:25,dias:28},
        {label:26,dias:30},
        {label:27,dias:30},
        {label:28,dias:30},
        {label:29,dias:30},
        {label:30,dias:30},
        {label:31,dias:32},
        {label:32,dias:32},
        {label:33,dias:32},
        {label:34,dias:32},
        {label:35,dias:32},

    ]

export default VACATIONSDAYSPERYEARS