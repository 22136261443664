import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { CustomCheck } from "../../../components/CustomCheck";
// import {Alert} from '../../../components/Alerts/Alert'
// import { RefillCols } from "../../../components/tableRefill/RefillCols";

import Request from "../../../utils/http";
import { TableInfinityFixed } from '../../../components/TableInfinityFixed';
import { ToastAlert } from '../../../components/ToastAlert';
const request = new Request();
const headerRows= [
    {classRow:'text-center', label:'Años laborados'},
    {classRow:'text-center', label:'Dias de vacaciones correspondientes'}
]

export const DaysToVacations = ({id}) => {
    const [openModal, setOpenModal] = useState(false)
    const [year,setYear] = useState({
        id:0,
        year:0,
        yearTwo:0,
        periodo:0,
        days:0
    })
    const [data,setData]=useState([])
    const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
    useEffect(() => {
        getData()
    },[])
    const getData = async () => {
        const response = await request.get('gestionrrhh/getVacationsGestion')
        if (response && response.statusCode === 200) {
            setData(response.result.data)
        }
    }
    const handleOpenModal = async (vacacion) => {
        if (vacacion==0) {
            setYear({id:0,year:0,days:0 ,yearTwo:0,periodo:0})
            setOpenModal(true)
            
        }else{

            setYear(vacacion)
            setOpenModal(true)
        }
    }
    const closeModal=() => {
        setYear({id:0,year:0,days:0,yearTwo:0,periodo:0 })
        setOpenModal(false)
        getData()
    }
  return (
    <div className="tab-pane " id={id} role="tabpanel">
        <ModalAddDay {...{openModal,year,setYear,handleOpenModal,closeModal,setToastAlert}} />
        <div className="p-3">
            <TableInfinityFixed columnsParam={headerRows} height={500} aditionalClass='clickeable table-striped' refill={true} cols={5}>
                    {data.map((linea=>{
                        const {year,days,yearTwo,periodo}=linea
                        return(
                            <tr onClick={e=>handleOpenModal(linea)} key={linea.id}> 
                                <td className="text-center">{year} { periodo>0&& ` a ${yearTwo} años`}</td>
                                <td className="text-center">{days }</td>
                            </tr>
                        )
                    }))}
            </TableInfinityFixed>
        </div>
        <ToastAlert {...{toastAlertProps}} /> 
     </div>
  )
}
const ModalAddDay=({openModal,year,setYear,handleOpenModal,closeModal,setToastAlert})=>{

    const [isDisabled,setIsDisabled]=useState(false)


   const customStyles = {
       content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "-35%",
         width: "28%",
         height: "48%",
         transform: "translate(-50%, -50%)",
       },
     };

 

   const createDays=async()=>{
       setIsDisabled(true);
       const response = await request.post('gestionrrhh/createVacations',year)
       if (response && response.statusCode === 200) {
        setToastAlert({text:'Guardado con exito',type:'success'},)
           closeModal()
       }
       setIsDisabled(false);

   }
   const handleCheck=(position,check)=>{
       let y = year.yearTwo==0?1:0
       setYear({...year,yearTwo:y,periodo:check})
   }
   return(
       <>
       
           <div className="d-flex justify-content-end m-2">
                <button onClick={e=>handleOpenModal(0)} className="btn btn-dark btn-sm"> Agregar <i className="fa fa-plus font-12"></i></button>
           </div> 
            <Modal style={customStyles} isOpen={openModal} shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true} onRequestClose={closeModal}>
                <div className="d-flex justify-content-between">
                <label >Gestion de vacaciones</label>
                <CustomCheck label="Periodo compuesto" checked={year.periodo} customStyles={{CheckText:{fontSize:'10px'}}} handleChecked={handleCheck}/>
                </div>
                <div className={year.yearTwo>0?"d-flex justify-content-between":''}>
                    <div>
                        <label >Inicio periodo</label>
                        <input type="number"   className="form-control" value={year.year} onChange={e=>setYear({...year,year:e.target.value})} />
                    </div>
                {year.periodo>0 && 
                    <div className='mr-2 pr-3'>
                        <label >Fin periodo</label>
                        <input type="number" placeholder="fin"  className="form-control ml-2 mr-1" value={year.yearTwo} onChange={e=>setYear({...year,yearTwo:e.target.value})} />
                    </div>
                }
                </div>
                <label >Dias corresponsientes</label>
                <input type="number" className="form-control" value={year.days} onChange={e=>setYear({...year,days:e.target.value})} />
                <div className="justify-end m-2">
                    <button onClick={createDays} disabled={isDisabled} className="btn btn-dark btn-sm">Guardar <i className="fa fa-save font-15"></i></button>
                </div>
            </Modal>
       </>
   )
}