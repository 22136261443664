import React from 'react'
import './styleCustomCheck.css'
export const CustomCheck = ({type='info',label='',checked=false,handleChecked,position=0,customStyles={}}) => {

// color options: info,success,danger,primary





  return (
    <label className={`containerCheck-${type} mr-1`} style={customStyles.CheckText}>
        <input type="checkbox" checked={checked} onChange={e=>handleChecked(position,e.target.checked)} />{label}
        <span className="checkmark"></span>
    </label>
  )
}
