

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { collection, addDoc, query, orderBy, where, doc, runTransaction, Timestamp, onSnapshot } from 'firebase/firestore';
import { db,storage } from '../../../../../firebaseConfig';
import Modal from 'react-modal'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAppContext } from '../../../../../Context/AppContext';


export const IndividualChats = ({  idChat, chatWhith, lastDate }) => {
    const { user, getInfo } = useAppContext();
    const chatContainerRef = useRef(null);
    const [newMessage, setNewMessage] = useState('');
    const [sender, setSender] = useState({
        id: 0,
        name: ''
    });
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const [img,setImg] = useState('')
  const [openModal,setOpenModal] = useState(false)// Tamaño de la página

  const handleImg=(url)=>{
    setOpenModal(true)
    setImg(url)
}
const handleClose=()=>{
    setOpenModal(false)
    setImg('')
}
    useEffect(() => {
        if (user !== 'tengo estado' && idChat!=null) {
            console.log("🚀 ~ useEffect ~ idChat:", idChat)
            setSender({
                id: user.personalData.id,
                name: `${user.personalData.name} ${user.personalData.lastName}`
            });
            listenForMessages(idChat);
        } else {
            getInfo();
        }
    }, [user, idChat]);
    
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const listenForMessages = (chatId) => {
        const messagesCollection = collection(db, 'chats', chatId, 'messages');
        const q = query(messagesCollection, orderBy('timestamp', 'asc'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const chatMessages = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(chatMessages);
        });

        return () => unsubscribe();
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (newMessage.trim() !== '') {
            await sendMessage(idChat, sender.id, newMessage);
            setNewMessage('');
        }
    };

    const sendMessage = async (chatId, senderId, message) => {
        const chatDocRef = doc(db, 'chats', chatId);
        const messagesCollection = collection(chatDocRef, 'messages');
    
        try {
            await runTransaction(db, async (transaction) => {
                // Add the new message
                const newMessageRef = await addDoc(messagesCollection, {
                    sender: senderId,
                    message: message,
                    timestamp: Timestamp.now()
                });
    
                // Update the chat's lastMessage and timestamp fields
                transaction.update(chatDocRef, {
                    lastMessage: message,
                    timestamp: Timestamp.now()
                });
    
                return newMessageRef;
            });
        } catch (error) {
            console.error('Error sending message and updating chat: ', error);
        }
    };
    const handleUploadFile = async (e) => {
        const file = e.target.files[0];
        let timestamp =new Date()
        if (file) {
            const chatIdIndividual =`chats/${idChat}`;  // Reemplaza esto con el chatId real
            const storageRef = ref(storage, `chatFiles/${chatIdIndividual}/${file.name}`);
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);

            const chatDocRef = doc(db, 'chats', idChat);  
            const messagesRef = collection(chatDocRef,'messages');

            await addDoc(messagesRef, {
                sender:sender.id,
                timestamp: timestamp,
                type: file.type.split('/')[0], // "image", "video", "audio", etc.
                content: downloadURL,
                fileName: file.name,
                message:'',
                id_user: sender.id
            });
        }
    };

    
    return (
        <div className="col-12">
           
            <div className="card blur shadow-blur max-height-vh-70">
                <div className="card-header shadow-lg">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="d-flex align-items-center">
                                <img alt="Image" src="https://th.bing.com/th/id/R.c33d127fc827b9c519bd6cfe6d937e30?rik=TyLeJpJeJc9rdA&pid=ImgRaw&r=0" className="avatar" />
                                <div className="ms-3">
                                    <h6 className="mb-0 d-block"> {chatWhith}</h6>
                                    <span className="text-sm text-dark opacity-8">Ultimo mensaje {lastDate}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalImages open={openModal} handleClose={handleClose} imageUrls={[img]}  />
                <div className="card-body overflow-auto overflow-x-hidden" ref={chatContainerRef}>
                    {messages.length > 0 && (
                        messages.map((chat) => {
                            let fecha = chat.timestamp.toDate();
                            return (
                                <Fragment key={chat.id}>
                                    {chat.sender === sender.id ? (
                                        <SelfMessage handleImg={handleImg} message={chat.message} timestamp={fecha.toLocaleString()} chat={chat} />
                                    ) : (
                                        <GenarlMessage handleImg={handleImg} message={chat.message} timestamp={fecha.toLocaleString()} chat={chat} name={chat.sender.name} />
                                    )}
                                </Fragment>
                            );
                        })
                    )}
                    <div ref={messagesEndRef} />
                </div>
                {/* <div className="card-footer d-block">
                    <form className="align-items-center" onSubmit={handleSendMessage}>
                        <div className="d-flex">
                            <div className="input-group">
                                <input type="text" value={newMessage} onChange={(e) => setNewMessage(e.target.value)} className="form-control" placeholder="Escribe aqui ..." aria-label="Message example input" />
                            </div>
                            <button className="btn bg-gradient-info mb-0 ms-2 btn-rounded" type="submit">
                                <i className="ni ni-send"></i>
                            </button>
                        </div>
                    </form>
                </div> */}
                 <div className="card-footer footer-general d-block">
                    <form className="">
                        <div className="d-flex mb-0">
                            <div className="input-group" style={{width: '85%'}}>
                                <textarea type="text" rows={2} cols={1}  value={newMessage} onChange={e => setNewMessage(e.target.value)} className="form-control " placeholder="Escribe aqui ..." aria-label="Message example input" />
                            </div>
                        <div>
                           <button className="btn bg-gradient-info p-2 btn-rounded" disabled={newMessage.length<1?true:false} onClick={e => handleSendMessage(e)}>
                                <i className="font-15 ni ni-send"></i>
                            </button>
                           </div>
                            <div class="file-input-container">
                                <label for="file-upload" class="file-label"><i className="fa-solid fa-paperclip font-15"></i></label>
                                <input type="file" id="file-upload"   class="file-input " onChange={handleUploadFile}/>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const SelfMessage = ({ message, timestamp,chat,handleImg }) => {
    const renderContent = () => {
        if (chat.message != '') {
            return <p className="mb-0">{message}</p>;
        } else if (chat.type === 'image') {
            return (
                <div className="col-12 p-0">
                    <img 
                     onClick={e=>handleImg(chat.content)}
                        src={chat.content} 
                        style={{ width: '200px' }} 
                        alt="Rounded uploadimage" 
                        className="img-fluid mb-2 border-radius-lg" 
                    />
                </div>
            );
        } else {
            return (
                <div className="col-12 p-0">
                        <p>{chat.fileName} <i className="fa fa-file"></i></p>
                    <a href={chat.content} download className="btn btn-link">
                        <i className="fa fa-download"></i> Descargar archivo
                    </a>
                </div>
            );
        }
    };
    return (
        <div className="row justify-content-end text-right mb-4">
            <div className="col-auto">
                <div className="card bg-gray-200">
                    <div className="card-body py-2 px-3">
                      {renderContent()}
                        <div className="d-flex align-items-center justify-content-end text-sm opacity-6">
                            <i className="ni ni-check-bold text-sm me-1"></i>
                            <small>{timestamp}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const GenarlMessage = ({ message, timestamp, name,chat,handleImg }) => {
    const renderContent = () => {
        if (chat.message != '') {
            return <p className="mb-0">{message}</p>;
        } else if (chat.type === 'image') {
            return (
                <div className="col-12 p-0">
                    <img 
                     onClick={e=>handleImg(chat.content)}
                        src={chat.content} 
                        style={{ width: '200px' }} 
                        alt="Rounded uploadimage" 
                        className="img-fluid mb-2 border-radius-lg" 
                    />
                </div>
            );
        } else {
            return (
                <div className="col-12 p-0">
                        <p>{chat.fileName} <i className="fa fa-file"></i></p>
                    <a href={chat.content} download className="btn btn-link">
                        <i className="fa fa-download"></i> Descargar archivo
                    </a>
                </div>
            );
        }
    };
    return (
        <div className="row justify-content-start mb-4">
            <div className="col-auto">
                <div className="card ">
                    <div className="card-body py-2 px-3">
                    {renderContent()}
                        <div className="d-flex align-items-center text-sm opacity-6">
                            <i className="ni ni-check-bold text-sm me-1"></i>
                            <small>{name} {timestamp}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ModalImages=({imageUrls,open,handleClose})=>{
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "-35%",
            width: "90%",
            height: "55%",
            transform: "translate(-50%, -50%)",
          },
    }
    return(
       <Modal style={customStyles} isOpen={open} onRequestClose={handleClose} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
         <div className="col-md-8 mx-auto">
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
          {imageUrls.map((imageUrl,key)=>{
            return(
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={key} className="active" aria-current="true" aria-label="Slide 1"></button>
            )
          })}
          </div>
        
          <div className="carousel-inner">
          {imageUrls.map((imageUrl) =>{
        
            return(
            <div className="carousel-item active">
              <img src={imageUrl} className="d-block w-100" alt="..."/>
            </div>
              
            )
          })}
          </div>
        
      
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
        </div>
      </div>
       </Modal>
    )
}