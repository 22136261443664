import { decryptString } from "../utils/encrypt";

export async function loadUser() {
   
      let user =  window.sessionStorage.getItem("user")
      if (user) {
        return JSON.parse( decryptString( user));
      }
   
    }
  