import React from "react";
import { CSVLink } from "react-csv";

const CSVDownloadButton = ({ classContainer='',csvData, buttonText, ...props }) => {
  return (
    <div className={classContainer}>
      <CSVLink data={csvData} {...props}>
     <i className="fa fa-download font-15"></i>&nbsp; {buttonText}
    </CSVLink>
    </div>
  );
};

export default CSVDownloadButton;
