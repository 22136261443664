import RoutesModule from "./Routes";
import { AppProvider, useAppContext } from "./Context/AppContext"; 

import { PrimeReactProvider } from 'primereact/api';
import { Client } from '@pusher/push-notifications-web';
import { useEffect } from "react";

function App() {
 

//  useEffect(() => {
//   const beamsClient = new Client({
//     instanceId: 'a792d905-f047-4d9e-a8dc-77748507eae3', // Reemplaza con tu Instance ID
//   });

//   beamsClient.start()
//     .then(() => beamsClient.addDeviceInterest('hello'))
//     .then(() => console.log('Successfully registered for notifications!'))
//     .catch(err => console.error('Error registering for notifications:', err));

//   // Manejar notificaciones cuando la aplicación está en primer plano
//   navigator.serviceWorker.addEventListener('message', event => {
//     const payload = event.data;

//     if (payload.type === 'PUSH_NOTIFICATION_RECEIVED') {
//       const { title, body, icon, image, data } = payload.notification;

//       if (Notification.permission === 'granted') {
//         new Notification(title, {
//           body,
//           icon,
//           image,
//           data: data.customData,
//           actions: [
//             { action: 'view', title: 'View' },
//             { action: 'dismiss', title: 'Dismiss' },
//           ],
//           tag: 'notification-tag',
//           renotify: true,
//         });
//       }
//     }
//   });
// }, []);
  const value = {
    appendTo: 'self',
  };

  return (
    <div className="App">
      
       <PrimeReactProvider >


      <AppProvider>
       <RoutesModule /> 
      </AppProvider>
       </PrimeReactProvider>
    </div>
  );
}

export default App;
