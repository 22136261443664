import React,{useState,useEffect} from 'react'
import Request from '../../../../utils/http'
import moment from 'moment-timezone'
import Select from 'react-select';
import { calcularDistanciaEnMetros } from '../../../../Hooks/distanciaGeo';
const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999 // Ajusta el valor de zIndex según tus necesidades
    }),
  };
const request = new Request()
export const SearchBar = ({setLatitudesUsers,setLoading,setLatitudesUsersExcel}) => {
    const [params,setParams]=useState({
        inicio:moment().format('YYYY-MM-DD'),
        fin:moment().format('YYYY-MM-DD'),
    })
    const [colaborators,setColaborators]=useState([])
    const [userSelected,setUserSelected] =useState({label:'todos los colaboradores',value:'0'})
    const getData = async() =>{
      setLoading(true)
        const response = await request.get("information/departments/personal", []);
        if (response && response.statusCode === 200) {
           
            let personas=[]
            response.result.datos.personal.forEach((item)=>{
              personas.push({
                label:`${item.name} ${item.lastName}`,
                value:item.id,
              
              })
            })
            setColaborators(personas)
        }
        handleSearch()
        setLoading(false)
    }
    const handleUser=(user)=> setUserSelected(user)
    useEffect(()=>{
        getData()
    },[])
    function groupBy(array, key) {
        return array.reduce((result, item) => {
            const keyValue = item[key];
            result[keyValue] = result[keyValue] || [];
            result[keyValue].push(item);
            return result;
        }, {});
    }
   
    const handleSearch=async()=>{
        const data={
            
            inicio:`${moment(params.inicio).format('YYYY-MM-DD')} 00:00:00`,
            fin:`${moment(params.fin).format('YYYY-MM-DD')} 23:59:59`
        }
       
        const response = await request.post(`locations/getlocations/${userSelected.value}`,data)
        
        if (response && response.statusCode === 200) {
            let data=[]
            let excelUbication=[]
            const groupedObject = groupBy(response.result.data, 'colaborator');
            const keys=Object.keys(groupedObject)
            keys.forEach(element => {
              
              data.push({
                  colaborator:element,
                  open:false,
                  ubications: groupedObject[element]
                })
                groupedObject[element].forEach(elementItem => {
                  let loc=JSON.parse(elementItem.location)
                 let distancia= calcularDistanciaEnMetros(elementItem.latitude,elementItem.longitude,elementItem.latitudeOut, elementItem.longitudeOut);
                  excelUbication.push({
                  colaborator:element,
                  ubicacion:loc.value,
                  entrada:elementItem.time,
                  latitud:elementItem.latitude,
                  longitud:elementItem.longitude,
                  salida:elementItem.timeout,
                  latitudSalida:elementItem.latitudeOut,
                  longitudSalida:elementItem.longitudeOut,
                  distancia:(distancia).toFixed(2)

                })
              });
            });
            
            setLatitudesUsersExcel(excelUbication)
            
            setLatitudesUsers(data)
        }
    }
  return (
    <>
     <div className="row p-0">
          <div className="col-md-3 p-2 col-sm-12">
            <input onChange={e=>setParams({...params,inicio:e.target.value})} value={params.inicio} type="date" className="form-control"  />
          </div>
          <div className="col-md-3 p-2 col-sm-12">
            <input onChange={e=>setParams({...params,fin:e.target.value})} value={params.fin} type="date" className="form-control"  />
          </div>
          <div className="col-md-3 p-2 col-sm-12">
          <Select options={colaborators} value={userSelected} onChange={value=>handleUser(value)}  styles={customStyles} />
          </div>

          <div className="col-md-3 p-2 col-sm-12">
            <button className="btn btn-dark" onClick={handleSearch}> <i className="fa fa-search" /> Buscar </button>
          </div>
        </div>
    </>
  )
}
